import { defineStyleConfig } from "@chakra-ui/react";

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 4,
    fontWeight: "semibold",
    transition: "all 0.2s",
  },
  variants: {
    solid: {
      color: "white",
      transition: "all 0.2s",
      bgGradient: "linear(to-b, #EBA91F, #ED9024)",
      _hover: {
        bgGradient: "linear(to-b, #E78A1D, #E78A1D)",
      },
    },
    radioSelected: {
      color: "white",
      bg: "black",
    },
    outlineDark: {
      color: "black",
      _hover: {
        bg: "gray.100",
      },
    },
  },
});

export default Button;
