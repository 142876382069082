import { Select } from "@chakra-ui/react";

function SelectImageControl({
  options,
  value,
  supportiveText,
  sendFullObject,
  onChange,
  button,
}) {
  return (
    <Select
      color="black"
      borderColor="gray.300"
      placeholder="Select option"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options &&
        options.map((item, i) => {
          return (
            <option key={i} value={item.code}>
              {item.name}
            </option>
          );
        })}
    </Select>
  );
}

export default SelectImageControl;
