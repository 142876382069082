import {
  Box,
  Container,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
  ButtonGroup,
} from "@chakra-ui/react";
import UserContext from "../userContext";
import AnalyticsContext from "../analyticsContext";
import TestimonialCard from "../Components/TestimonialCard";
import LastConfigurationBanner from "../Components/LastConfigurationBanner";
import AppFrame from "../Components/AppFrame";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as StepGraphicConnect } from "../assets/step-graphic-connect.svg";
import { ReactComponent as StepGraphicCustomize } from "../assets/step-graphic-customize.svg";
import { ReactComponent as StepGraphicCelebrate } from "../assets/step-graphic-celebrate.svg";

import { ReactComponent as HeroGraphic } from "../assets/hero.svg";
import { ReactComponent as StarIcon } from "../assets/star.svg";

import PresetActivitiesList from "../Components/PresetActivitiesList";
import { Transition } from "@headlessui/react";

const HeroGraphic2 = require("../assets/hero-2.png");
const HeroGraphicMobile = require("../assets/hero.png");
const GoldEggshellProductShot = require("../assets/productShots/eggshel-gold-a4.jpeg");

function Home() {
  const { triggerEvent } = useContext(AnalyticsContext);
  const [showHero, setShowHero] = useState(false);
  const userState = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setShowHero(true);
  }, []);

  return (
    <AppFrame>
      {userState.user &&
        userState.user.last_configuration &&
        userState.user.last_configuration.activity && (
          <Box bg="black.50">
            <Container maxW="container.xl" py={{ base: 6, md: 14 }} px="8">
              <LastConfigurationBanner
                lastConfiguration={userState.user.last_configuration}
              />
            </Container>
          </Box>
        )}

      <Container
        h={{ base: "auto", md: "100vh" }}
        maxH={{ md: "700px" }}
        maxW="container.xl"
        py={{ base: 2, md: 10 }}
        px={8}
      >
        <Transition
          show={showHero}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem
              colSpan={{ base: 12, sm: 10, md: 6 }}
              colStart={{ base: 0, sm: 2, md: 0 }}
              display="flex"
              alignItems="center"
            >
              <Box textAlign={{ base: "center", md: "left" }}>
                <Box
                  display={{ base: "block", md: "none" }}
                  my={4}
                  position="relative"
                  width="80%"
                  maxW="350px"
                  mx="auto"
                >
                  <Image src={HeroGraphicMobile} width="100%" />
                </Box>
                <Heading
                  as="h1"
                  size={{ base: "2xl", md: "3xl" }}
                  fontStyle="italic"
                  fontWeight="medium"
                  mb="6"
                  textAlign={{ base: "center", md: "left" }}
                >
                  Frame your athletic achievements
                </Heading>
                <Heading
                  as="h3"
                  size={{ base: "md", md: "lg" }}
                  fontFamily="body"
                  fontWeight="regular"
                  color="gray.400"
                  mb="10"
                  textAlign={{ base: "center", md: "left" }}
                >
                  Premium, personalised artwork celebrating your athletic
                  activity
                </Heading>
                <ButtonGroup spacing={2}>
                  <Button
                    variant="solid"
                    onClick={() => {
                      triggerEvent({
                        category: "homepage",
                        action: "click",
                        label: "create_artwork_clicked--hero",
                      });
                      navigate("/activities");
                    }}
                    size="lg"
                  >
                    Create artwork
                  </Button>
                  {/* <Button as={Link} to="/vouchers" variant="outline" size="lg">
                  Buy gift voucher
                </Button> */}
                </ButtonGroup>
              </Box>
            </GridItem>
            <GridItem
              display={{ base: "none", md: "block" }}
              colSpan={{ base: 10, sm: 40, md: 6 }}
              colStart={{ base: 2, sm: 2, md: 7 }}
              position="relative"
            >
              <HeroGraphic />
              <Box
                display="inline-block"
                position="absolute"
                top={0}
                left="80px"
              >
                <StarIcon />
              </Box>
              <Box
                display="inline-block"
                position="absolute"
                top={-10}
                left="150px"
              >
                <StarIcon width="15px" height="15px" />
              </Box>
              <Box
                display="inline-block"
                position="absolute"
                bottom={-10}
                right="80px"
              >
                <StarIcon width="15px" height="15px" />
              </Box>
              <Box
                display="inline-block"
                position="absolute"
                bottom={0}
                right="-30px"
              >
                <StarIcon />
              </Box>
            </GridItem>
          </Grid>
        </Transition>
      </Container>

      <Container
        id="how-it-works"
        maxW="container.lg"
        py={{ base: 20, md: 24 }}
      >
        <Heading
          as="h3"
          fontFamily="body"
          fontWeight="regular"
          size="lg"
          mb="12"
          textAlign="center"
        >
          How it works
        </Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap={{ md: 4, lg: 10 }}>
          <GridItem
            colSpan={{ base: 3, md: 1 }}
            w="100%"
            textAlign="center"
            px={6}
            mb={{ base: 14, md: 0 }}
          >
            <Box display="inline-block" mb={4}>
              <StepGraphicConnect />
            </Box>
            <Heading as="h3" fontFamily="body" size="md" mb="4">
              Connect
            </Heading>
            <Text fontSize="xl" color="gray.400">
              Connect your Strava account to select the activity you would like
              to create artwork from
            </Text>
          </GridItem>

          <GridItem
            colSpan={{ base: 3, md: 1 }}
            w="100%"
            textAlign="center"
            px={6}
            mb={{ base: 14, md: 0 }}
          >
            <Box display="inline-block" mb={4}>
              <StepGraphicCustomize />
            </Box>
            <Heading as="h3" fontFamily="body" size="md" mb="4">
              Customize
            </Heading>
            <Text fontSize="xl" color="gray.400">
              Select the size, style and content of your artwork you would like
              to display
            </Text>
          </GridItem>

          <GridItem
            colSpan={{ base: 3, md: 1 }}
            w="100%"
            textAlign="center"
            px={6}
            mb={{ base: 14, md: 0 }}
          >
            <Box display="inline-block" mb={4}>
              <StepGraphicCelebrate />
            </Box>
            <Heading as="h3" fontFamily="body" size="md" mb="4">
              Celebrate
            </Heading>
            <Text fontSize="xl" color="gray.400">
              Your artwork will be printed and delivered to your door ready to
              frame and celebrate!
            </Text>
          </GridItem>
        </Grid>
        <Box textAlign="center" mt={{ base: 0, md: "12" }}>
          <Button
            size="md"
            variant="outline"
            as={Link}
            to="/faq"
            onClick={() => {
              triggerEvent({
                category: "homepage",
                action: "click",
                label: "faq_how-it-works_clicked",
              });
            }}
          >
            Frequently Asked Questions
          </Button>
        </Box>
      </Container>

      {/* <Container maxW="container.lg" py={{ base: 20, md: 24 }}>
        <Heading
          as="h3"
          fontFamily="body"
          fontWeight="regular"
          size="lg"
          mb="2"
          textAlign="center"
        >
          Recent UK races
        </Heading>
        <Heading
          as="h3"
          fontFamily="body"
          fontWeight="regular"
          size="md"
          mb="12"
          textAlign="center"
          color="gray.500"
        >
          Select a race and add your statistics to create your personalised
          artwork
        </Heading>
        <PresetActivitiesList />
      </Container> */}
      <Container maxW="container.lg" py={{ base: 20, md: 24 }}>
        <Heading
          as="h3"
          fontFamily="body"
          fontWeight="regular"
          size="lg"
          mb="2"
          textAlign="center"
        >
          Artwork created from activities tracked in Strava
        </Heading>
        <Heading
          as="h3"
          fontFamily="body"
          fontWeight="regular"
          size="md"
          mb="12"
          textAlign="center"
          color="gray.500"
        >
          Select between gold or silver foil. Printed on premium quality paper.
        </Heading>
        <Grid
          templateRows={{ base: "repeat(4, 1fr)", md: "repeat(2, 1fr)" }}
          templateColumns="repeat(5, 1fr)"
          gap={4}
        >
          <GridItem colSpan={{ base: 5, md: 2 }} bg="gray.900">
            <Image src={GoldEggshellProductShot} width="100%" />
          </GridItem>
          <GridItem colSpan={{ base: 5, md: 3 }} bg="gray.900">
            <Image src={GoldEggshellProductShot} width="100%" />
          </GridItem>
          <GridItem colSpan={{ base: 5, md: 3 }} bg="gray.900" />
          <GridItem colSpan={{ base: 5, md: 2 }} bg="gray.900" />
        </Grid>
      </Container>
      <Container maxW="container.lg" mt="12">
        <Heading
          as="h3"
          fontFamily="body"
          fontWeight="regular"
          size="lg"
          mb="8"
          textAlign="center"
        >
          Happy Customers
        </Heading>
        <SimpleGrid
          spacing={4}
          templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        >
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
        </SimpleGrid>
      </Container>

      <Container maxW="container.xl" py={{ base: 20, md: 24 }} my={16} px={8}>
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem
            display={{ base: "none", md: "block" }}
            colSpan={{ base: 10, sm: 40, md: 6 }}
            colStart={{ base: 2, sm: 2, md: 0 }}
            position="relative"
          >
            <Image src={HeroGraphic2} width="100%" />
            <Box display="inline-block" position="absolute" top={0} left="80px">
              <StarIcon />
            </Box>
            <Box
              display="inline-block"
              position="absolute"
              top={-10}
              left="150px"
            >
              <StarIcon width="15px" height="15px" />
            </Box>
            <Box
              display="inline-block"
              position="absolute"
              bottom={-10}
              right="70px"
            >
              <StarIcon width="15px" height="15px" />
            </Box>
            <Box
              display="inline-block"
              position="absolute"
              bottom={0}
              right="-30px"
            >
              <StarIcon />
            </Box>
          </GridItem>

          <GridItem
            colSpan={{ base: 10, sm: 10, md: 5 }}
            colStart={{ base: 2, sm: 2, md: 8 }}
            display="flex"
            alignItems="center"
          >
            <Box
              mx={{ base: "auto", sm: "auto", md: "none" }}
              textAlign={{ base: "center", md: "left" }}
            >
              <Heading
                as="h1"
                size={{ base: "2xl", md: "3xl" }}
                fontStyle="italic"
                mb="6"
                textAlign={{ base: "center", md: "left" }}
              >
                Frame your achievements
              </Heading>
              <Heading
                as="h3"
                size={{ base: "md", md: "lg" }}
                fontFamily="body"
                fontWeight="regular"
                color="gray.400"
                mb="10"
                textAlign={{ base: "center", md: "left" }}
              >
                Create your custom artwork in minutes
              </Heading>
              <ButtonGroup spacing={2}>
                <Button
                  variant="solid"
                  onClick={() => {
                    triggerEvent({
                      category: "homepage",
                      action: "click",
                      label: "create_artwork_clicked--footer-cta",
                    });
                    navigate("/activities");
                  }}
                  size="lg"
                >
                  Create artwork
                </Button>
                {/* <Button as={Link} to="/vouchers" variant="outline" size="lg">
                  Buy gift voucher
                </Button> */}
              </ButtonGroup>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </AppFrame>
  );
}

export default Home;
