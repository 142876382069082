import * as polyline from "@mapbox/polyline";
import { useContext, useEffect, useState } from "react";
import * as d3 from "d3";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import AnalyticsContext from "../analyticsContext";
import dateFormat from "dateformat";
import {
  Box,
  CardBody,
  IconButton,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Card,
} from "@chakra-ui/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

function Activity({
  name,
  id,
  distance,
  start_date_local,
  elapsed_time,
  map,
  onClick,
}) {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const { triggerEvent } = useContext(AnalyticsContext);
  const [ctx, setCtx] = useState(null);
  const [canvasSize] = useState(80);
  const [geoJSON, setGeoJSON] = useState(null);
  function handleClick() {
    triggerEvent({
      category: "select-activity",
      action: "click",
      label: `selected-activity-${id}`,
    });
    if (onClick) {
      onClick();
    } else {
      navigate(`/activity?id=${id}`);
    }
  }

  useEffect(() => {
    processActivity();
    setupCanvas();
  }, []);

  useEffect(() => {
    if (ctx && geoJSON) {
      setMap();
    }
  }, [ctx, geoJSON]);

  function processActivity(res) {
    let coordinates = polyline.decode(map.summary_polyline);
    setGeoJSON({
      type: "LineString",
      coordinates: coordinates,
    });
  }

  function setupCanvas() {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    setCtx(context);
  }

  function setMap() {
    var projection = d3
      .geoMercator()
      .rotate([0, -90])
      .reflectX(true)
      .fitSize([canvasSize, canvasSize], geoJSON);
    var path = d3.geoPath().projection(projection);
    const svgPath = path(geoJSON);
    var canvasPath = new Path2D(svgPath);
    ctx.strokeStyle = "#fff";
    ctx.lineWidth = 1;
    var p = new Path2D(canvasPath);
    ctx.stroke(p);
  }

  return (
    <Card
      boxShadow="none"
      border="1px solid"
      borderColor="gray.900"
      borderRadius="lg"
      bg="black"
      direction="row"
      overflow="hidden"
      alignItems="center"
      onClick={handleClick}
      mb={2}
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      _hover={{
        bg: "gray.900",
      }}
    >
      <CardHeader position="relative" p={{ base: 2, md: 4 }}>
        <Box
          w={["40px", "80px"]}
          h={["40px", "80px"]}
          borderRadius="md"
          bg="gray.900"
          padding={{ base: 0, sm: 2 }}
        >
          <canvas
            ref={canvasRef}
            style={{
              minWidth: "40px",
              width: "100%",
              minHeight: "40px",
              height: "100%",
            }}
            width={canvasSize}
            height={canvasSize}
          />
        </Box>
      </CardHeader>
      <CardBody
        py={{ base: 2, md: 4 }}
        px={0}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box flex="2">
          <Heading size="md" mb={0} noOfLines={1}>
            {name}
          </Heading>
          <Box>
            <Text display="inline" mb={0}>
              {dateFormat(start_date_local, "mmm dS, yy")}
            </Text>
            <Text display={{ base: "inline", sm: "none" }} m={0}>
              {" · "}
              {Math.floor(distance / 100) / 10 + "km"}
            </Text>
            <Text display={{ base: "inline", sm: "none" }} m={0}>
              {" · "}
              {elapsed_time.toString().toHHMMSS()}
            </Text>
          </Box>
        </Box>
        <Box flex="1" display={["none", "block"]}>
          <Text m={0} fontSize="sm" color="gray.500">
            Distance
          </Text>
          <Text m={0}>{Math.floor(distance / 100) / 10 + "km"}</Text>
        </Box>
        <Box flex="1" display={["none", "block"]}>
          <Text m={0} fontSize="sm" color="gray.500">
            Duration
          </Text>
          <Text m={0}>{elapsed_time.toString().toHHMMSS()}</Text>
        </Box>
      </CardBody>
      <CardFooter p={{ base: 2, md: 4 }}>
        <IconButton
          variant="ghost"
          colorScheme="white"
          size="md"
          icon={<ArrowRightIcon width={16} color="white" />}
        />
      </CardFooter>
    </Card>
  );
}

export default Activity;
