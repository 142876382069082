import { useContext, useEffect, useState } from "react";
import Context from "../context";
import UserContext from "../userContext";

import { useSearchParams } from "react-router-dom";
import { Flex, Box, Container } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CREATE_ARTWORK_STEPPER_HEIGHT } from "../Components/CreateArtworkStepper";
import ArtworkConfigurationCarousel from "../Components/ArtworkConfigurationCarousel";
import generateControls from "../Helpers/generateControls";
import loadActivity from "../Helpers/loadActivity";

import { Canvas, CreateArtworkStepper } from "../Components";

import { postData } from "../Helpers/http";

import { TailSpin } from "react-loader-spinner";
const WallBackground = require("../assets/wall-bg.jpg");
function ActivityDetail() {
  let [params] = useSearchParams();
  const id = params.get("id");
  const isPrebuilt = params.get("prebuilt");
  let state = useContext(Context);
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  const [testImage, setTestImage] = useState(null);
  const [exportFinalImages, setExportFinalImages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [controls, setControls] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSwiperCards, setShowSwiperCards] = useState(true);

  useEffect(() => {
    loadActivity(id, state.path, state.builderConfig, isPrebuilt).then(
      (data) => {
        state.setActivity(data.activity);
        state.updateBuilder(null, null, data.builderConfig);
        state.setgeoJSON(data.geoJSON);
        state.setStatOptions(data.statOptions);
        state.setProductsAndSizes(data.products);
        setInitialLoad(false);
      }
    );
  }, []);

  //generate new controls if things change
  useEffect(() => {
    if (state.products && state.sizes) {
      let newControls = generateControls(
        state.builderControlsId,
        state,
        userState
      );
      setControls(newControls);
    }
  }, [state.builderConfig, state.countryCode, userState.email]);

  useEffect(() => {
    //generate new controls when the user selects to change a set of options
    if (state.products && state.sizes) {
      let newControls = generateControls(
        state.builderControlsId,
        state,
        userState
      );
      setShowSwiperCards(false);
      setControls(newControls);
      state.setBuilderIndex(0);
      setTimeout(() => {
        setShowSwiperCards(true);
      }, 500);
    }
  }, [state.builderControlsId]);

  useEffect(() => {
    if (state.finalImages) {
      Promise.all([
        uploadFile(state.finalImages.standard, "standard"),
        uploadFile(state.finalImages.thumbnail, "thumbnail"),
      ])
        .then((key) => {
          state.updateBuilder(null, null, { uploadedImageKey: orderId });
          setLoading(false);
          navigate(
            `/checkout?toStripe=true&order=${orderId}&country=${state.countryCode}`
          );
        })
        .catch((err) => {
          console.log("failed to upload", err);
        });
    }
  }, [state.finalImages]);

  function onBuildComplete() {
    setLoading(true);
    postData(`${state.path}/order`, {
      email: userState.email,
      configuration: state.builderConfig,
    }).then((data) => {
      setOrderId(data.orderId);
      setExportFinalImages(true);
    });
  }

  function uploadFile(url, imageType) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          var fd = new FormData();
          fd.append("image", blob, "filename");
          fd.append("orderId", orderId);
          fd.append("imageKey", imageType);
          // Upload
          fetch(`${state.path}/upload`, {
            method: "POST",
            credentials: "include",
            body: fd,
          }).then((data) => {
            resolve(data);
          });
        });
    });
  }

  function getControlsTitle() {
    if (controls) {
      let filtered = controls.filter((c) => c.display);
      return filtered[state.builderIndex]
        ? filtered[state.builderIndex].label
        : "There is no title";
    } else return "";
  }

  if (initialLoad) {
    return (
      <Box h="100vh" display="flex" alignItems="center" justifyContent="center">
        <Container maxW="container.sm" py={8}>
          <Box textAlign="center">
            <Box display="inline-block">
              <TailSpin
                height="45"
                width="45"
                color="#212121"
                ariaLabel="tail-spin-loading"
                radius="2"
                visible={true}
              />
            </Box>
            <br />
            <br />
            Loading activity...
          </Box>
        </Container>
      </Box>
    );
  }
  const currentType = controls?.filter((c) => c.display)[state.builderIndex]
    .type;

  return (
    <>
      <CreateArtworkStepper mx="auto" stepNo={1} />
      {testImage && <img src={testImage} />}
      <Flex pt={CREATE_ARTWORK_STEPPER_HEIGHT} minH="100vh">
        <Box
          zIndex={-1}
          flexDirection="row"
          position={"fixed"}
          top={CREATE_ARTWORK_STEPPER_HEIGHT}
          left={2}
          bottom={2}
          right={2}
          borderRadius="lg"
          backgroundImage={WallBackground}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          minH={`calc(100vh - ${CREATE_ARTWORK_STEPPER_HEIGHT} - 4px)`}
        ></Box>

        <Box
          w={{ base: "100vw", md: "60vw" }}
          h={"auto"}
          position={{ base: "fixed", md: "relative" }}
          top={0}
          left={0}
          bottom={0}
          right={0}
        >
          {state.canvasImage && <img src={state.canvasImage} alt="canvas" />}
          {state.activity && state.geoJSON && state.builderConfig && (
            <>
              <Flex
                w="full"
                minH={`calc(100vh - ${CREATE_ARTWORK_STEPPER_HEIGHT})`}
                boxSizing="border-box"
                p={6}
                pt={{ base: "100px", md: 0 }}
                flexDirection="column"
                align={["center"]}
                justify={{ base: "flex-start", md: "center" }}
              >
                {state.mapImage && (
                  <Canvas
                    imageSize={state.sizes.find(
                      (s) => s.key === state.builderConfig.imageSize
                    )}
                    showFullColorPreview={
                      currentType === "colour" || currentType === "orderSummary"
                    }
                    exportFinalImages={exportFinalImages}
                    updateBuilder={state.updateBuilder}
                    typeScale={state.builderConfig.typeScale}
                    {...state.builderConfig.activity}
                    {...state.builderConfig}
                    elementSpacing={
                      state.builderConfig.elementSpacing *
                      state.builderConfig.masterScale
                    }
                    paceChartHeight={
                      state.builderConfig.paceChartHeight *
                      state.builderConfig.masterScale
                    }
                    borderSize={
                      state.builderConfig.borderSize *
                      state.builderConfig.masterScale
                    }
                    strokeWidth={
                      state.builderConfig.strokeWidth *
                      state.builderConfig.masterScale
                    }
                    geoJSON={state.geoJSON}
                    // {...state.activity}
                    statOptions={state.statOptions}
                    sendImage={(image) => {
                      setTestImage(image);
                      // uploadFile(image);
                    }}
                  />
                )}
              </Flex>
            </>
          )}
        </Box>
        <Flex
          zIndex={2}
          w={{ base: "100vw", md: "40vw" }}
          minW={{ base: "100vw", md: "300px" }}
          mt={{ base: "70vh", md: 0 }}
          mb={{ base: CREATE_ARTWORK_STEPPER_HEIGHT, md: 0 }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ArtworkConfigurationCarousel
            showSwiperCards={showSwiperCards}
            isLoading={loading}
            onBuildComplete={onBuildComplete}
            controls={controls}
            builderIndex={state.builderIndex}
            setBuilderIndex={state.setBuilderIndex}
            getControlsTitle={getControlsTitle}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default ActivityDetail;
