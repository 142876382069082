import { Box, Image, useRadio, useRadioGroup } from "@chakra-ui/react";
import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="md"
        _hover={{
          bg: "gray.100",
        }}
        _checked={{
          bg: "black",
          color: "white",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        p={2}
        border="0"
        color="black"
        display="flex"
        alignItems="center"
        mb={1}
      >
        {props.icon && <Image src={props.icon} h="38px" w="38px" mr={2} />}
        <Box flex="1" textAlign="left">
          {props.children}
        </Box>
        <Box flex="0">
          <CheckCircleIcon
            transition="all .2s"
            opacity={props.isChecked ? 1 : 0}
            display="block"
            w={5}
            h={5}
          />
        </Box>
      </Box>
    </Box>
  );
}

const ColourSelection = ({ options = [], onChange, value }) => {
  const [selectedColour, setSelectedColour] = React.useState(options[0]?.key);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "colour",
    defaultValue: options[0]?.key,
  });
  const group = getRootProps();

  return (
    <Box {...group}>
      {options.map((option, index) => {
        const radio = getRadioProps({ key: option.key });
        return (
          <RadioCard
            key={index}
            {...radio}
            onChange={() => {
              setSelectedColour(option.key);
              onChange(option);
            }}
            isChecked={selectedColour === option.key}
            icon={option.icon}
          >
            <p style={{ fontWeight: "bold" }}>{option.label}</p>
            {option.subLabel && (
              <p
                style={{
                  marign: 0,
                  fontWeight: "300",
                  fontSize: "12px",
                }}
              >
                {option.subLabel}
              </p>
            )}
          </RadioCard>
        );
      })}
    </Box>
  );
};

const ColourSelectControls = ({ options, value, onChange }) => {
  return (
    <div>
      <Box mb={4}>
        <ColourSelection options={options} onChange={onChange} value={value} />
      </Box>
    </div>
  );
};

export default ColourSelectControls;
