import React, { useState, useEffect } from "react";
import { Provider } from "./context";
import {
  faRoute,
  faMapPin,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import presetBuilderConfigs from "./Data/presetBuilderConfigs";
import loadFontFaces from "./Helpers/typography";

const STRAVA_AUTHENTICATION_URL =
  process.env.NODE_ENV === "production"
    ? "http://www.strava.com/oauth/authorize?client_id=106331&response_type=code&redirect_uri=https://www.kudosmaps.com/exchange_token&approval_prompt=force&scope=activity:read_all"
    : "http://www.strava.com/oauth/authorize?client_id=106331&response_type=code&redirect_uri=http://localhost:3000/exchange_token&approval_prompt=force&scope=activity:read_all";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://www.kudosmaps.com/api"
    : "http://localhost:5001/api";

const presets = [
  { key: "map", label: "Map", icon: faMapPin },
  { key: "route", label: "Route line", icon: faRoute },
  {
    key: "graph",
    label: "Pace chart",
    icon: faChartLine,
    requiresStrava: true,
  },
];

const defaultSizes = [
  {
    key: "square",
    productKey: "square",
    aspect_ratio: "square",
    orientation: "square",
    label: "Square",
    a: 1,
    b: 1,
  },
  // {
  //   key: "longPortrait",
  //   productKey: "long",
  //   aspect_ratio: "long",
  //   orientation: "portrait",
  //   label: "Long portrait",
  //   a: 1,
  //   b: 2,
  //   // subLabel: getMinPrice("long"),
  // },
  // {
  //   key: "longLandscape",
  //   productKey: "long",
  //   aspect_ratio: "long",
  //   orientation: "landscape",
  //   label: "Long landscape",
  //   a: 1,
  //   b: 2,
  //   // subLabel: getMinPrice("long"),
  // },
  {
    key: "paperPortrait",
    productKey: "paper",
    aspect_ratio: "paper",
    orientation: "portrait",
    label: "Paper portrait",
    a: 1,
    b: 1.414,
    // subLabel: getMinPrice("paper"),
  },
  {
    key: "paperLandscape",
    productKey: "paper",
    aspect_ratio: "paper",
    orientation: "landscape",
    label: "Paper landscape",
    a: 1.414,
    b: 1,
    // subLabel: getMinPrice("paper"),
  },
];

const ProviderComponent = (props) => {
  const [path, setPath] = useState(API_URL);
  const [shippingRates, setShippingRates] = useState(null);
  const [preBuiltRoutes, setPreBuiltRoutes] = useState(null);
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState(null);
  const [sizes, setSizes] = useState(defaultSizes);
  const [products, setProducts] = useState(null);
  const [frames, setFrames] = useState(null);
  const [geoJSON, setgeoJSON] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null);
  const [statOptions, setStatOptions] = useState(null);
  const [mapImage, setMapImage] = useState(null);
  const [finalImages, setFinalImages] = useState(null);
  const [builderControlsId, setBuilderControlsId] = useState("presets");
  const [builderIndex, setBuilderIndex] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [builderConfig, setBuilderConfig] = useState({
    ...presetBuilderConfigs[0],
    updater: 0,
  });

  useEffect(() => {
    loadFontFaces();
  }, []);

  function setProductsAndSizes(data) {
    setProducts(data);
    let newSizes = sizes.map((item, i) => {
      return {
        ...item,
        subLabel: "From £" + getMinPrice(data, item.productKey),
      };
    });
    setSizes(newSizes);
  }

  function getMinPrice(passedProducts, productKey) {
    if (passedProducts) {
      const prods = passedProducts.filter((p) => {
        return p.metadata.aspect_ratio === productKey;
      });
      if (prods && prods.length > 0) {
        const lowestPrice = prods.reduce((previous, current) => {
          return current.default_price.unit_amount <
            previous.default_price.unit_amount
            ? current
            : previous;
        });
        return lowestPrice.default_price.unit_amount / 100;
      }
    }
  }

  function updateBuilder(key, value, obj) {
    if (obj) {
      let newObj = {
        ...builderConfig,
        ...obj,
        updater: builderConfig.updater + 1,
      };

      setBuilderConfig(newObj);
    } else {
      setBuilderConfig({
        ...builderConfig,
        [key]: value,
        updater: builderConfig.updater + 1,
      });
    }
  }

  return (
    <Provider
      value={{
        shippingRates,
        setShippingRates,
        builderConfig,
        setBuilderConfig,
        updateBuilder,
        showMap,
        setShowMap,
        setProductsAndSizes,
        finalImages,
        setFinalImages,
        activity,
        preBuiltRoutes,
        setPreBuiltRoutes,
        setActivity,
        builderControlsId,
        setBuilderControlsId,
        geoJSON,
        setgeoJSON,
        canvasImage,
        setCanvasImage,
        countryCode,
        setCountryCode,
        statOptions,
        setStatOptions,
        sizes,
        products,
        setProducts,
        frames,
        setFrames,
        frames,
        setFrames,
        path,
        presets,
        initialWidth: 6000,
        activities,
        setActivities,
        builderIndex,
        setBuilderIndex,
        mapImage,
        setMapImage,
        STRAVA_AUTHENTICATION_URL,
      }}
    >
      {props.children}
    </Provider>
  );
};

export default ProviderComponent;
