
import { useContext, useEffect, useRef, useState} from 'react'
import Context from '../context'
import { useSearchParams, useParams } from 'react-router-dom';
import { getData } from '../Helpers/http'
import {  Canvas, SwiperCard, Swiper,CreateArtworkStepper, CustomiseText, SwiperControls, RadioImageControl} from '../Components'
import { createStats, getMapBounds } from '../Helpers/canvasHelper'
import poly from '@mapbox/polyline'
import { Flex , Box, Container, Text, Card, CardBody, Button} from '@chakra-ui/react'
import presetConfigs from '../Data/presetBuilderConfigs'
import { useNavigate } from 'react-router-dom';



function ActivityDetail() {
    let [params] = useSearchParams();
    const id = params.get('id')
    let state = useContext(Context)
    const [ count, setCount ] = useState(0)
    const navigate = useNavigate()
    
    useEffect(() => {
        getActivity()
    },[])

    // useEffect(() => {
        
    //     if (state.builderConfig.updater > 0 ){
    //         setTimeout(() => {
    //             navigate(`/activity/builder?id=${id}`)
    //         },100)
            
    //     }
    // },[state.builderConfig.updater])

    function processActivity(res){
        state.setActivity(res)
        state.setStatOptions(createStats(res))
        const gj = poly.toGeoJSON(res.map.summary_polyline)  
        const mapBounds = getMapBounds(gj)
        state.updateBuilder('mapBounds', mapBounds)
        state.setgeoJSON(gj) 
    }
    function getActivity(){
        getData(`${state.path}/activity/${id}`)
        .then((res) => {
            processActivity(res)
        })
        .catch((err) => console.log('error', err))
    }

    function setBuilder(preset){
        state.updateBuilder(null, null, {...state.builderConfig, ...preset, updater: state.builderConfig.updater + 1})
        
    }

  return (
        <Container maxW="container.lg" py={8}>
            <Flex>
                <Box w="100%">
                    {presetConfigs.map((preset, i) => {
                        return(
                            <Card key={i} onClick={() => setBuilder(preset)}>
                                <CardBody >
                                    <img src="https://placekitten.com/300/200" />
                                    <Text>{preset.presetName}</Text>
                                </CardBody>
                            </Card>
                        )
                    })}
                </Box>
              
            </Flex>
            <Button 
            colorScheme="orange"
            onClick={() => navigate(`/activity/builder?id=${id}`)}>
                Next
            </Button>
        </Container>
  );
        
}

export default ActivityDetail;
