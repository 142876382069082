import { useContext, useEffect, useState, useRef } from "react";

import Context from "../../context";
import adminContext from "../../adminContext";
import { useSearchParams } from "react-router-dom";
import { getData, postData } from "../../Helpers/http";
import Order from "../../Components/Order";

import { Button, ButtonGroup } from "@chakra-ui/react";

import Generator from "./Generator";

function AdminOrderRoute() {
  let [params] = useSearchParams();
  const id = params.get("id");
  const adminState = useContext(adminContext);
  let state = useContext(Context);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    state.setFinalImages(false);
    state.setgeoJSON(false);
    state.setActivity(false);
    state.setStatOptions(false);
    setLoading(true);
    getOrder();
  }, []);

  function getOrder() {
    console.log("getting order");
    console.log(adminState.path, id);
    getData(`${adminState.path}/order?id=${id}`)
      .then((res) => {
        console.log("got order", res);
        adminState.setOrder(res);
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }

  function cancelOrder() {
    postData(`${adminState.path}/order/cancel`, {
      printer_id: adminState.order.printer_id,
      id: adminState.order.id,
    })
      .then((order) => {
        adminState.setOrder(order);
      })
      .catch((err) => {
        console.log("there was an error");
      });
  }

  function refundOrder() {
    postData(`${adminState.path}/order/refund`, {
      stripe_charge_id: adminState.order.stripe_charge_id,
      id: adminState.order.id,
    })
      .then((order) => {
        console.log(order);
        // adminState.setOrder(order)
      })
      .catch((err) => {
        console.log("there was an error");
      });
  }

  return (
    <>
      {adminState?.order?.status === "paid" ? (
        <Generator />
      ) : (
        adminState.order && (
          <>
            <Order {...adminState.order} />
            {adminState.order.status !== "cancelled" && (
              <ButtonGroup>
                <Button
                  display={["none", "block"]}
                  variant="solid"
                  colorScheme="orange"
                  size="sm"
                  onClick={() => {
                    cancelOrder();
                  }}
                >
                  CancelOrder
                </Button>
                <Button
                  display={["none", "block"]}
                  variant="solid"
                  colorScheme="orange"
                  size="sm"
                  onClick={() => {
                    refundOrder();
                  }}
                >
                  Refund order
                </Button>
              </ButtonGroup>
            )}
          </>
        )
      )}
    </>
  );
}

export default AdminOrderRoute;
