import { Box, Heading, Flex, Text, HStack, Stack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Context from "../context";
import Swiper from "./Swiper";
import SwiperControls from "./SwiperControls";
import SwiperCard from "./SwiperCard";
import RadioImageControl from "./RadioImageControl";
import ButtonsImageControl from "./ButtonsImageControl";
import CustomiseStats from "./CustomiseStats";
import TextInputs from "./TextInputs";
import OrderSummary from "./OrderSummary";
import SelectImageControl from "./SelectImageControl";
import ScrollAnimation from "./ScrollAnimation";
import AnalyticsContext from "../analyticsContext";
import ColourSelectControls from "./ColourSelectControls";
import BuilderPagination from "../Components/BuilderPagination";
import BuilderToggles from "../Components/BuilderToggles";

export const ARTWORK_CAROUSEL_WIDTH = 300;

const ArtworkConfigurationCarousel = ({
  getControlsTitle,
  builderIndex,
  onBuildComplete,
  setBuilderIndex,
  showSwiperCards,
  controls,
  isLoading,
}) => {
  const [validation, setValidation] = useState(false);
  const { triggerEvent } = useContext(AnalyticsContext);
  const state = useContext(Context);
  useEffect(() => {
    triggerEvent({
      category: "creating-artwork-navigate",
      action: "click",
      label: `navigated to ${controls && controls[builderIndex]?.label}`,
    });
  }, [builderIndex]);

  return (
    <Box
      w={ARTWORK_CAROUSEL_WIDTH}
      bg="white"
      borderRadius="lg"
      shadow="lg"
      overflow="hidden"
    >
      <Box color="white" w={ARTWORK_CAROUSEL_WIDTH} p={4}>
        <Flex position="relative" mh="60px" direction="column">
          <Heading size="md" m={0} fontFamily="body" color="black">
            {!isLoading && getControlsTitle()}
            {isLoading && "Exporting your artwork"}
          </Heading>
          <ScrollAnimation />
        </Flex>
      </Box>
      <Swiper
        builderIndex={builderIndex}
        showSwiperCards={showSwiperCards}
        setBuilderIndex={setBuilderIndex}
      >
        {controls &&
          showSwiperCards &&
          controls.map((control, i) => {
            // if (i < builderIndex - 1 || i > builderIndex + 1) {
            //   return <SwiperCard key={i} />;
            // }
            if (controls[i].display) {
              switch (controls[i].type) {
                case "toggles":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <BuilderToggles {...control} key={i} />
                      {validation && <Text>{validation}</Text>}
                    </SwiperCard>
                  );
                case "textInput":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <Stack spacing={2}>
                        <TextInputs
                          {...control}
                          builderHeader={state.builderConfig.header}
                          key={i}
                        />
                      </Stack>
                      {validation && <Text>{validation}</Text>}
                    </SwiperCard>
                  );
                case "stats":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <CustomiseStats {...control} key={i} />
                    </SwiperCard>
                  );
                case "radio":
                default:
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <RadioImageControl
                        sendFullObject={control.sendFullObject}
                        {...controls[i]}
                        isLoading={isLoading}
                      />
                    </SwiperCard>
                  );
                case "colour":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <ColourSelectControls
                        sendFullObject={control.sendFullObject}
                        {...controls[i]}
                        isLoading={isLoading}
                      />
                    </SwiperCard>
                  );
                case "buttons":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      {!isLoading && (
                        <ButtonsImageControl
                          setBuilderIndex={setBuilderIndex}
                          onBuildComplete={onBuildComplete}
                          sendFullObject={control.sendFullObject}
                          {...controls[i]}
                          isLoading={isLoading}
                        />
                      )}
                      {isLoading && (
                        <p>
                          We ensure we export the highest quality images so this
                          may take a little while.
                        </p>
                      )}
                    </SwiperCard>
                  );
                case "orderSummary":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <OrderSummary {...controls[i]} />
                    </SwiperCard>
                  );
                case "select":
                  return (
                    <SwiperCard
                      mb={2}
                      key={i}
                      supportiveText={control.supportiveText}
                    >
                      <SelectImageControl {...controls[i]} />
                    </SwiperCard>
                  );
              }
            } else return <div key={i} />;
          })}
      </Swiper>
      <BuilderPagination
        steps={controls ? controls.filter((c) => c.display) : []}
        selected={builderIndex}
      />
      <SwiperControls
        controls={controls}
        setValidation={setValidation}
        CTA={controls ? controls[builderIndex].CTA : null}
        control={controls ? controls[builderIndex] : null}
        isLoading={isLoading}
        onBuildComplete={onBuildComplete}
        limit={controls && controls.filter((c) => c.display).length}
        builderIndex={builderIndex}
        setBuilderIndex={setBuilderIndex}
      />
    </Box>
  );
};

export default ArtworkConfigurationCarousel;
