import { useContext, useEffect } from "react";
import Context from "../context";
import UserContext from "../userContext";
import { useSearchParams } from "react-router-dom";
import { getData } from "../Helpers/http";
import { useNavigate } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";
import AppFrame from "../Components/AppFrame";

function CheckLoginStatus() {
  const navigate = useNavigate();
  let state = useContext(Context);
  let userState = useContext(UserContext);

  useEffect(() => {
    checkStatus();
  }, []);

  function checkStatus() {
    
    getData(`${state.path}/athlete`)
      .then((res) => {
        userState.setUser(res)
      })
      .catch((err) => {console.log('no user logged in')})
  }

  return (
    <></>
  );
}

export default CheckLoginStatus;
