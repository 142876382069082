import { useContext, useEffect, useState } from "react";
import Context from "../../adminContext";
import { useSearchParams } from "react-router-dom";
import { getData } from "../../Helpers/http";
import AdminUser from '../../Components/AdminUser'
import styled from "styled-components";
import { useNavigate  } from "react-router-dom";
import { Box, Button,Text, Container, Heading, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";


function Users({query}) {
  let state = useContext(Context);
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [tab, setTab ] = useState('all')
  let [loading, setLoading] = useState(true);
 
  useEffect(() => {
      getUsers();
  }, []);

  function getUsers() {
    setPage(page + 1);
    getData(`${state.path}/users`)
    .then((res) => {
      if (Array.isArray(res)){
        state.setUsers(res);
        setLoading(false);
      }
      
    })
    .catch((err) => console.log("error", err));
  }

  return (
    <Box>
      <Container maxW="container.lg">
        <Box>
          {state.users && state.users.map((item, i) => {
              return (
                <AdminUser 
                    path={state.path}
                  index={i}
                  setUsers={state.setUsers}
                  key={i} 
                  {...item} 
                  onClick={()=> {
                    navigate(`/admin/order?id=${item.id}`)
                  }}
                />
              )
          })}
        </Box>
        <Box textAlign="center" pb={12}>
          <Button 
            isLoading={loading} 
            w={['full', 'auto']} 
            onClick={() => getUsers()}>
            Load more
          </Button>
        </Box>
      </Container>
    </Box>
  );
}





export default Users;
