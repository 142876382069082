

import * as d3 from 'd3';
import svgBBox from 'svg-path-bbox'
import { mapToRange , getTextDimensions, getCenterFocusDimensions,getPace, calculateLockupHeights,} from './canvasHelper'
import svgpath from 'svgpath'
import mapboxgl from 'mapbox-gl';
import Context from '../context'
const style_id = 'clhz2s2gr02c901qyduen7tex'
const username = 'bobbyhewitt'
const access_token = 'pk.eyJ1IjoiYm9iYnloZXdpdHQiLCJhIjoiY2xoeXd1cTN0MTdzYzNkbnRpazE5d2xhNyJ9.Ezxvt9Mq-Gf8hvCUa3vbxA'

function canvasFooter(params){
    const {ctx, width, height, heights, typography, props} = params
    const {paceChartCenteredVertically,swapTitleAndStats, borderSize, paceChartScale, preset, stats, footer, useIconForStats, statOptions, elementSpacing, isMetric, splits_metric,splits_standard, paceChartHeight} = props
    const showPaceChart = footer === 'statsAndPaceChart' || footer === 'paceChart' 
    const showStats = footer === 'statsAndPaceChart' || footer === 'stats' ? true : false; 
    
    const calculateLockupWidth = ( text) => {
        let longest = 0;
        for (var i = 0; i < text.length; i++){
            ctx.font = text[i].font
            let width = ctx.measureText(text[i].value).width
            if (width > longest){
                longest = width
            }
        }
        return longest
    }

    function addPace(){ 
        const splits = getPace(isMetric ? splits_metric : splits_standard) 
        const barSpacing =((width - borderSize * 2) + ((width - borderSize * 2) / splits.length) * 0.1  ) / splits.length  
        let barPosition =  
            swapTitleAndStats ? 
                borderSize + (showStats ? heights.statLockup + elementSpacing : 0) :
                height - borderSize - (showStats ? heights.statLockup + elementSpacing : 0)
                
        for(var i = 0; i < splits.length; i++){
            const barHeight = 
                (paceChartHeight * paceChartScale) * splits[i]
            const barX = borderSize + (barSpacing * i)
            const barY = swapTitleAndStats ?  
                barPosition + ((paceChartHeight * paceChartScale) - barHeight) : 
                barPosition - barHeight
            const barWidth = barSpacing * 0.9 
            ctx.fillStyle = 'black'
            ctx.fillRect(barX, barY, barWidth, barHeight);    
        }
    }

   function addStats(){

        if (!stats || statOptions.length === 0) return
        ctx.textBaseline = 'bottom'; 
        let totalStatsWidth = 0
        for (var i = 0; i < stats.length; i++) {
            let stat = statOptions.find((option) => option.key === stats[i])
            
            let statLabel = stat.customLabel ? stat.customLabel : stat.imperialLabel && !isMetric ? stat.imperialLabel : stat.label
            let statWidth = calculateLockupWidth([
                {
                    font: typography.stat,
                    value: stat.customValue ? stat.customValue : stat.imperial && !isMetric ? stat.imperial : stat.value
                },
                {
                    font: useIconForStats ? typography.icon : typography.label,
                    value: statLabel
                }
            ])
            stat.width = statWidth	
            totalStatsWidth += statWidth 
        }
        let spacing  = (width -  (borderSize * 2) - totalStatsWidth) / (stats.length -1)
        let currentX = borderSize 		
        for (var i = 0; i < stats.length; i++) {
            let stat = statOptions.find((option) => option.key === stats[i])
            let value = stat.customValue ? stat.customValue : stat.imperial && !isMetric ? stat.imperial : stat.value
            let statLabel = stat.customLabel ? stat.customLabel : stat.imperialLabel && !isMetric ? stat.imperialLabel : stat.label
            addStat(currentX, statLabel, value, stat.icon)
            currentX += stat.width + spacing
        }
    } 
    
    function addStat(xPos, statLabel, value, icon, ){
        const subtitleYPos = swapTitleAndStats ? 
            borderSize + heights.label.actualHeight:
            height - borderSize - heights.stat.actualHeight - heights.typographySpacing
        
        const titleYPos = swapTitleAndStats ? 
            borderSize + heights.label.actualHeight + heights.stat.actualHeight + heights.typographySpacing:
            height - borderSize

        ctx.font = typography.stat
        ctx.fillStyle = "black";
        ctx.fillText(value, xPos,titleYPos);
        if (useIconForStats){
            var transformed = svgpath(icon)          
            .scale(0.25)
            .translate(xPos, height - borderSize - heights.stat- 160)
            .rel()
            .toString();
            var canvasPath = new Path2D(transformed);
            ctx.lineWidth = 14;
            ctx.fill(canvasPath);    
        } else {
            ctx.font = useIconForStats ? typography.icon : typography.label
            ctx.fillText(useIconForStats ? icon : statLabel, xPos, subtitleYPos);
        }	
    }
             
    if (showStats){
        addStats()
    } 
    if (showPaceChart){
        addPace()
    }    
}

export default canvasFooter
