import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Stack,
    Input
  } from '@chakra-ui/react'



  function CustomiseText({statOptions, customText, title, subtitle,isOpen, onClose, onChange}){

    const onChangeTitleOrSubtitle = (key, e) => {  
        onChange(key, e.target.value.toUpperCase())
        
    }
    return(
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Customise text</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Stack spacing={4}>
        <Input variant="flushed" label="title"placeholder='Title' value={title} onChange={(e) => onChangeTitleOrSubtitle('title', e)} />
        <Input variant="flushed" label="subtitle" placeholder='Subtitle' value={subtitle} onChange={(e) => onChangeTitleOrSubtitle('subtitle', e)} />
        </Stack>
        </ModalBody>

        <ModalFooter>
            <Button colorScheme='orange' mr={3} onClick={onClose}>
            Save
            </Button>
            
        </ModalFooter>
        </ModalContent>
    </Modal>
    )
  }

  export default CustomiseText;