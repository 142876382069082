import { cloneDeep } from "lodash";

export function getShippingRateFromProdigi(quotes, method) {
  console.log("prodigi quotes", quotes);

  if (!quotes) return null;
  const quote = quotes.find((quote) => quote.shipmentMethod === method);

  if (quote) {
    return (
      (parseFloat(quote.shipments[0].cost.amount) +
        parseFloat(quote.shipments[0].tax.amount)) *
      100
    ).toFixed(0);
  } else {
    return null;
  }
}

export const ourShippingRates = {
  standard: {
    display_name: "Standard",
    prodigi_shipment_method: "Standard",
    delivery_estimate: {
      minimum: {
        unit: "business_day",
        value: 3,
      },
      maximum: {
        unit: "business_day",
        value: 5,
      },
    },
    type: "fixed_amount",
    fixed_amount: {
      amount: 0,
      currency: "gbp",
    },
  },
  express: {
    display_name: "Next Day",
    prodigi_shipment_method: "Overnight",
    delivery_estimate: {
      minimum: {
        unit: "business_day",
        value: 1,
      },
      maximum: {
        unit: "business_day",
        value: 1,
      },
    },
    type: "fixed_amount",
    fixed_amount: {
      amount: (prodigyQuotes) => {
        return getShippingRateFromProdigi(prodigyQuotes, "Overnight")
          ? getShippingRateFromProdigi(prodigyQuotes, "Overnight")
          : "Not available";
      },
      currency: "gbp",
    },
  },
};

export function getCalculatedShippingOptions(data) {
  const prodigiQuotes = data.quotes;

  const calculatedShippingRates = Object.keys(ourShippingRates).map((key) => {
    let rate = cloneDeep(ourShippingRates[key]);
    console.log("amount", rate.fixed_amount.amount);

    rate.fixed_amount.amount =
      typeof rate.fixed_amount.amount === "function"
        ? parseFloat(rate.fixed_amount.amount(prodigiQuotes))
        : rate.fixed_amount.amount;

    console.log("amount", rate.fixed_amount.amount);
    return rate;
  });
  console.log();
  return calculatedShippingRates;
}

export function formatShippingOptionsForStripe(shippingOptions) {
  return shippingOptions.map((option) => {
    return {
      shipping_rate_data: {
        type: "fixed_amount",
        fixed_amount: {
          amount: option.fixed_amount.amount,
          currency: "gbp",
        },
        display_name: option.display_name,
        delivery_estimate: option.delivery_estimate,
      },
    };
  });
}

// export function getShippingOptions(data, country) {
//   let quotes = data.quotes;
//   let shippingOptions = [];

//   console.log(quotes);

//   if (country === "GB") {
//     shippingOptions.push({
//       shipping_rate_data: {
//         type: "fixed_amount",
//         fixed_amount: {
//           amount: 0,
//           currency: "gbp",
//         },
//         display_name: "Free standard delivery",
//       },
//     });
//   }

//   for (var i = 0; i < quotes.length; i++) {
//     const quote = quotes[i];

//     let option = {
//       shipping_rate_data: {
//         type: "fixed_amount",
//         fixed_amount: {
//           amount: (
//             (parseFloat(quote.shipments[0].cost.amount) +
//               parseFloat(quote.shipments[0].tax.amount)) *
//             100
//           ).toFixed(0),
//           currency: "gbp",
//         },
//         display_name: quote.shipmentMethod,
//       },
//     };
//     shippingOptions.push(option);
//   }
//   return shippingOptions;
// }
