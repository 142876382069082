import { useContext, useEffect, useState } from "react";
import Context from "../../adminContext";
import { useSearchParams } from "react-router-dom";
import { getData, postData, deleteData } from "../../Helpers/http";
import AdminOrder from "../../Components/AdminOrder";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  CardBody,
  Container,
  Card,
  CardFooter,
  Text,
  Heading,
} from "@chakra-ui/react";
import FileUpload from "../../Components/FileUpload";
import { TrashIcon } from "@heroicons/react/24/solid";
import { gpx } from "@tmcw/togeojson";

function PreBuiltRoutes({ query }) {
  let state = useContext(Context);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDistance, setEventDistance] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [geoJson, setGeoJson] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    getPreBuiltRoutes();
  }, []);

  function readFile(event) {
    const xml = event.target.result;
    const parsed = gpx(new DOMParser().parseFromString(xml, "text/xml"));
    console.log("parsed", parsed);
    let geometry = parsed?.features[0]?.geometry;
    let name = parsed?.features[0]?.properties?.name;
    if (name) setEventName(name);
    setGeoJson(geometry);
  }

  function onSubmit() {
    console.log(eventDate);
    setUploading(true);
    const data = {
      geoJson,
      title: eventName,
      location: eventLocation,
      date: eventDate,
      distance: eventDistance,
      image: eventImage,
    };
    postData(`${state.path}/prebuiltroutes`, data).then((res) => {
      setUploading(false);
      setFile(false);
      setGeoJson(false);
      setEventDate("");
      setEventDistance("");
      setEventLocation("");
      setEventName("");
      setEventImage("");
      state.setPreBuiltRoutes(res);
    });
  }

  function getPreBuiltRoutes() {
    getData(`${state.path}/prebuiltroutes`)
      .then((res) => {
        setLoading(false);
        state.setPreBuiltRoutes(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });
  }

  function onDelete(id) {
    setLoading(true);
    deleteData(`${state.path}/prebuiltroutes`, { id }).then((res) => {
      state.setPreBuiltRoutes(res);
      setLoading(false);

      console.log(res);
    });
  }

  return (
    <Container maxW="container.lg">
      <Box>
        {state.preBuiltRoutes &&
          state.preBuiltRoutes.map((item, i) => {
            console.log(item);
            return (
              <Card
                boxShadow="none"
                border="1px solid"
                borderColor="gray.700"
                borderRadius="lg"
                bg="black"
                direction="row"
                overflow="hidden"
                alignItems="center"
                mb={2}
              >
                <CardBody
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Heading size="md" mb={0} color="white" noOfLines={1}>
                    {item.title}
                  </Heading>
                </CardBody>
                <CardFooter>
                  <IconButton
                    onClick={() => {
                      onDelete(item._id);
                    }}
                    variant="solid"
                    colorScheme="orange"
                    size="sm"
                    icon={<TrashIcon width={16} color="black" />}
                  />
                </CardFooter>
              </Card>
            );
          })}
      </Box>
      <Box textAlign="center" pb={12} mt="12">
        <FileUpload
          file={file}
          onFileAccepted={(file) => {
            setFile(file);
            var reader = new FileReader();
            reader.addEventListener("load", readFile);
            reader.readAsText(file);
          }}
        />

        <Input
          mt="4"
          label="Route name"
          placeholder="Route name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          variant="outline"
          borderColor="gray.300"
          _placeholder={{ color: "gray.500" }}
          _hover={{ borderColor: "gray.500" }}
          color="white"
        />
        <Input
          mt="4"
          label="Date"
          placeholder="Date"
          type="date"
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
          variant="outline"
          borderColor="gray.300"
          _placeholder={{ color: "gray.500" }}
          _hover={{ borderColor: "gray.500" }}
          color="white"
        />
        <Input
          mt="4"
          label="Location"
          placeholder="Location"
          value={eventLocation}
          onChange={(e) => setEventLocation(e.target.value)}
          variant="outline"
          borderColor="gray.300"
          _placeholder={{ color: "gray.500" }}
          _hover={{ borderColor: "gray.500" }}
          color="white"
        />
        <Input
          mt="4"
          label="Distance"
          placeholder="Distance"
          value={eventDistance}
          onChange={(e) => setEventDistance(e.target.value)}
          variant="outline"
          type="number"
          borderColor="gray.300"
          _placeholder={{ color: "gray.500" }}
          _hover={{ borderColor: "gray.500" }}
          color="white"
        />
        <Input
          mt="4"
          label="Image"
          placeholder="Paste image url"
          value={eventImage}
          onChange={(e) => setEventImage(e.target.value)}
          variant="outline"
          type="text"
          borderColor="gray.300"
          _placeholder={{ color: "gray.500" }}
          _hover={{ borderColor: "gray.500" }}
          color="white"
        />
        <Button
          isLoading={uploading}
          display={["none", "block"]}
          variant="solid"
          colorScheme="orange"
          size="sm"
          onClick={() => {
            onSubmit();
          }}
        >
          Create event
        </Button>
      </Box>
    </Container>
  );
}

export default PreBuiltRoutes;
