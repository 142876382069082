import { useContext, useEffect, useState } from "react";

import Context from "../../context";
import adminContext from "../../adminContext";
import { useSearchParams } from "react-router-dom";
import { getData, postData } from "../../Helpers/http";
import { createStats } from "../../Helpers/canvasHelper";
import poly from "@mapbox/polyline";
import {
  Flex,
  Box,
  Button,
  Stack,
  Image,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";

import { Canvas } from "../../Components";

function ActivityDetail() {
  let [params] = useSearchParams();
  const id = params.get("id");
  const adminState = useContext(adminContext);
  let state = useContext(Context);
  const [testImage, setTestImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [exportFinalImages, setExportFinalImages] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    state.setFinalImages(false);
    state.setgeoJSON(false);
    state.setActivity(false);
    state.setStatOptions(false);
    setLoading(true);
    getOrder();
  }, []);

  function getComparisonImage(user_id, order_id) {
    const query = `?user_id=${user_id}&order_id=${id}&image=thumbnail`;
    getData(`${state.path}/image${query}`)
      .then((data) => {
        console.log("got image data", data);
        setOriginalImage(data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getOrder() {
    getData(`${adminState.path}/order?id=${id}`)
      .then((res) => {
        console.log(res);
        adminState.setOrder(res);
        checkPrebuilt(res);
        getComparisonImage(res.user_id, res.order_id);
      })
      .catch((err) => console.log("error", err));
  }

  function checkPrebuilt(order) {
    console.log(order.configuration.isPrebuilt);
    if (order.configuration.isPrebuilt) {
      getData(
        `${state.path}/prebuiltroutes?id=${order.configuration.isPrebuilt}`
      )
        .then((res) => {
          processActivity(order, res.geoJson);
        })
        .catch((err) => console.log("error", err));
    } else {
      processActivity(order);
    }
  }

  function processActivity(order, geoJson) {
    const activity = order.configuration.activity;
    // Set activity in state
    state.setActivity(activity);
    const statOptions = createStats(activity, order.configuration.isPrebuilt);
    state.setStatOptions(statOptions);
    let gj = geoJson ? geoJson : poly.toGeoJSON(activity.map.summary_polyline);
    state.setgeoJSON(gj);
    state.updateBuilder(null, null, order.configuration);
    setLoading(false);
  }

  function onExportImage() {
    setExportFinalImages(true);
  }

  function onUpdateImage() {
    state.updateBuilder("updater", state.builderConfig.updater + 1);
    setExportFinalImages(false);
  }

  function commitToPrinter() {
    setLoading(true);
    uploadFile(
      state.finalImages.forprinter,
      adminState.order.user_id,
      adminState.order.id,
      "forprinter"
    )
      .then((key) => {
        setLoading(false);
        postData(`${adminState.path}/order`, { id: adminState.order.id })
          .then((res) => {
            setLoading(false);
            alert("Image submitted to printer");
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        alert("Failed to upload image");
        console.log("failed to upload", err);
      });
  }

  function uploadFile(url, userId, orderId, imageType) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          var fd = new FormData();
          fd.append("image", blob, "filename");
          fd.append("orderId", orderId);
          fd.append("imageKey", imageType);
          fd.append("userId", userId);
          // Upload
          fetch(`${state.path}/upload`, {
            method: "POST",
            credentials: "include",
            body: fd,
          }).then((data) => {
            resolve(data);
          });
        });
    });
  }

  return (
    <>
      <Flex>
        <Box
          w={["", "60vw"]}
          h={"auto"}
          position={["fixed", "relative"]}
          top={0}
          left={0}
          bottom={0}
          right={0}
        >
          {state.geoJSON && state.builderConfig && (
            <>
              <Flex
                w="full"
                minH={`calc(100vh)`}
                boxSizing="border-box"
                p={6}
                pt={["100px", 0]}
                flexDirection="column"
                align={["center"]}
                justify={["flex-start", "center"]}
              >
                <Text>Rendered Canvas</Text>
                <Canvas
                  adminCanvas
                  exportFinalImages={exportFinalImages}
                  updateBuilder={state.updateBuilder}
                  typeScale={state.builderConfig.typeScale}
                  {...state.builderConfig.activity}
                  {...state.builderConfig}
                  elementSpacing={
                    state.builderConfig.elementSpacing *
                    state.builderConfig.adminMasterScale
                  }
                  paceChartHeight={
                    state.builderConfig.paceChartHeight *
                    state.builderConfig.adminMasterScale
                  }
                  borderSize={
                    state.builderConfig.borderSize *
                    state.builderConfig.adminMasterScale
                  }
                  strokeWidth={
                    state.builderConfig.strokeWidth *
                    state.builderConfig.adminMasterScale
                  }
                  imageSize={state.sizes.find(
                    (s) => s.key === state.builderConfig.imageSize
                  )}
                  halfWidth
                  geoJSON={state.geoJSON}
                  {...state.activity}
                  statOptions={state.statOptions}
                  sendImage={(image) => {
                    setTestImage(image);
                    // uploadFile(image);
                  }}
                />

                <ButtonGroup>
                  <Button
                    display={["none", "block"]}
                    variant="solid"
                    colorScheme="orange"
                    size="sm"
                    onClick={() => {
                      onExportImage();
                    }}
                  >
                    Convert image
                  </Button>
                  <Button
                    display={["none", "block"]}
                    variant="solid"
                    colorScheme="orange"
                    size="sm"
                    onClick={() => {
                      onUpdateImage();
                    }}
                  >
                    Update image
                  </Button>
                  <Button
                    display={["none", "block"]}
                    variant="solid"
                    isDisabled={
                      !state.finalImages?.forprinter ||
                      loading ||
                      adminState.order.printer_id
                    }
                    colorScheme="orange"
                    size="sm"
                    onClick={() => {
                      commitToPrinter();
                    }}
                  >
                    Submit to printer
                  </Button>
                </ButtonGroup>
              </Flex>
            </>
          )}
        </Box>

        <Stack>
          {originalImage && (
            <>
              <Text>Original Image</Text>
              <Box backgroundColor="gray.900">
                <Image boxSize="20vw" objectFit="contain" src={originalImage} />
              </Box>
            </>
          )}
          {state.finalImages?.forprinter && (
            <>
              <Text>For printer</Text>
              <Box
                style={{
                  backgroundImage:
                    "linear-gradient(45deg, lightgrey 25%, transparent 25%), linear-gradient(135deg, lightgrey 25%, transparent 25%), linear-gradient(45deg, transparent 75%, lightgrey 75%), linear-gradient(135deg, transparent 75%, lightgrey 75%)",
                  backgroundSize: "20px 20px",
                  backgroundPosition: "0 0, 10px 0, 10px -10px, 0px 10px",
                }}
              >
                <Image
                  boxSize="20vw"
                  objectFit="contain"
                  src={state.finalImages.forprinter}
                />
              </Box>
            </>
          )}
        </Stack>
      </Flex>
    </>
  );
}

export default ActivityDetail;
