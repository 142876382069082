import React, { useContext } from "react";
import Context from "../context";
import AppFrame from "../Components/AppFrame";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { createCheckoutSession } from "../Helpers/stripe";

const vouchers = [
  {
    priceId: "price_1NUSgUFsmRsZF3JzbCl4C9sc",
    name: "Square Print",
    size: {
      width: 12,
      height: 12,
    },
    description:
      "Our smallest size prints are ideal to be placed on a desk or shelf",
    price: 21.99,
    image: "https://placehold.co/600x400",
    recommended: false,
  },
  {
    priceId: "price_1NWMncFsmRsZF3JzwYHbRzQX",
    name: "A1 Print",
    size: {
      width: 33,
      height: 23,
    },
    description:
      "Portrait or landscape, it displays beautifully hanging on any wall",
    price: 24.99,
    image: "https://placehold.co/600x400",
    recommended: true,
  },
  {
    priceId: "price_1NWMp0FsmRsZF3JzcS7fYgy0",
    name: "Long Print",
    size: {
      width: 20,
      height: 10,
    },
    description: "Our largest prints for bold statements and large spaces",
    price: 64.99,
    image: "https://placehold.co/600x400",
    recommended: false,
  },
];

const VoucherCard = ({ voucher }) => {
  const state = useContext(Context);
  const [processing, setProcessing] = React.useState(false);

  const createStripeCheckoutSession = () => {
    setProcessing(true);
    createCheckoutSession({
      path: state.path,
      line_items: [{ price: voucher.priceId, quantity: 1 }],
    });
  };

  const frameRatio = voucher.size.width / voucher.size.height;

  return (
    <Card
      bg="black"
      border="4px solid"
      borderColor="gray.800"
      borderRadius="200px"
      color="white"
      position="relative"
      py={8}
    >
      <Box
        w={60 * frameRatio + "px"}
        h="60px"
        position="absolute"
        left="50%"
        top="-30px"
        ml={-30 * frameRatio + "px"}
        border="2px solid"
        borderColor="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="black"
        fontSize="xs"
      >
        {`${voucher.size.width}" x ${voucher.size.height}"`}
      </Box>
      <CardHeader p={8}>
        <Heading
          as="h2"
          size="lg"
          fontFamily="body"
          fontWeight="700"
          textAlign="center"
          color="white"
          mb={2}
        >
          {voucher.name}
        </Heading>
        <Heading
          as="h3"
          fontFamily="body"
          size="md"
          fontWeight="bold"
          textAlign="center"
          mb={2}
        >
          £{voucher.price}
        </Heading>
        <Text
          fontSize="lg"
          fontWeight="medium"
          textAlign="center"
          color="gray.400"
        >
          {voucher.description}
        </Text>
      </CardHeader>
      <CardBody py={0} px={8}>
        <Image src={voucher.image} width="100%" borderRadius="lg" />
      </CardBody>
      <CardFooter justifyContent="center" p={8}>
        <Button
          isLoading={processing}
          size="lg"
          onClick={createStripeCheckoutSession}
          variant="solid"
        >
          Buy Voucher
        </Button>
      </CardFooter>
    </Card>
  );
};

const Vouchers = () => {
  return (
    <AppFrame>
      <Container maxW="container.xl" py={24}>
        <Heading size="xl" fontFamily="body" textAlign="center" mb={2}>
          Vouchers - Coming Soon
        </Heading>
        <Heading
          as="h2"
          fontFamily="body"
          fontWeight="medium"
          size="md"
          textAlign="center"
          maxW="container.sm"
          mx="auto"
          mb={24}
          lineHeight="tall"
        >
          Celebrate a friend or relatives achievement by buying them a gift
          voucher to create their own athletic artwork. You will receive an
          email with a voucher code that you can send to them.
        </Heading>
        {/* <Grid templateColumns="repeat(12, 1fr)" gap={10}>
          {vouchers.map((voucher) => (
            <GridItem colSpan={4}>
              <VoucherCard voucher={voucher} />
            </GridItem>
          ))}
        </Grid> */}
      </Container>
    </AppFrame>
  );
};

export default Vouchers;
