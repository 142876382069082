export function skuSelector(builderConfig = {}, selectedProduct) {
  const selectedForegroundColor = builderConfig.color?.foreground;

  if (selectedForegroundColor && selectedProduct) {
    switch (selectedForegroundColor) {
      case "gold":
        return selectedProduct.metadata.sku_gold;
      case "silver":
        return selectedProduct.metadata.sku_silver;     
      default:
        return selectedProduct.metadata.sku_poster; 
    }
  }
  return null;
}
