import { useContext, useEffect, useState } from "react";
import Context from "../context";
import UserContext from "../userContext";
import { getData } from "../Helpers/http";
import { Activity } from "../Components";
import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Image,
  Link,
  LinkBox,
  Text,
} from "@chakra-ui/react";
import CreateArtworkStepper from "../Components/CreateArtworkStepper";
import PresetActivitiesList from "../Components/PresetActivitiesList";

import { ReactComponent as StravaIcon } from "../assets/icon-strava.svg";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

function UserActivities() {
  let state = useContext(Context);
  const [page, setPage] = useState(1);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !state.activities ||
      (state.activities && state.activities.length === 0)
    ) {
      getActivities();
    }
  }, []);

  function getActivities() {
    setPage(page + 1);
    setLoading(true);
    getData(`${state.path}/activities?page=${page}`)
      .then((res) => {
        state.setActivities(state.activities.concat(res));
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }

  return (
    <Box>
      <Box mt="12">
        <Heading size="lg" textAlign="center" fontStyle="italic" mb={8}>
          Select a Strava activity
        </Heading>
      </Box>
      <Box>
        {state.activities.map((item, i) => {
          if (
            item.map &&
            item.map.summary_polyline &&
            item.map.summary_polyline.length > 0
          ) {
            return <Activity key={i} {...item} />;
          } else return <div key={i} />;
        })}
      </Box>
      <Box textAlign="center" pb={12}>
        <Button
          variant="outline"
          isLoading={loading}
          w={["full", "auto"]}
          onClick={() => getActivities()}
        >
          Load more activites
        </Button>
      </Box>
    </Box>
  );
}

function StravaLoginPrompt() {
  let state = useContext(Context);
  return (
    <LinkBox
      mt="12"
      onClick={() => window.location.replace(state.STRAVA_AUTHENTICATION_URL)}
      display="flex"
      border="1px solid"
      borderColor="gray.900"
      p={4}
      borderRadius="md"
      _hover={{
        bg: "gray.900",
        cursor: "pointer",
      }}
      gap={4}
      alignItems="center"
    >
      <Box flex="0">
        <StravaIcon width="60px" height="60px" />
      </Box>
      <Box flex="1">
        <Heading size="lg" fontStyle="italic">
          Connect to Strava
        </Heading>
        <Text color="gray.400">
          Select an activity on Strava to create your artwork
        </Text>
      </Box>
      <Box flex="0">
        <ArrowUpRightIcon color="white" width="18px" height="18px" />
      </Box>
    </LinkBox>
  );
}

function Activities() {
  const userState = useContext(UserContext);

  return (
    <Container maxW="container.lg" pb={32}>
      <Box py={8}>
        <CreateArtworkStepper mx="auto" stepNo={0} />
      </Box>
      <Box my="12">
        {userState?.user ? <UserActivities /> : <StravaLoginPrompt />}
      </Box>
      {!userState?.user && (
        <>
          <Text fontSize="xl" textAlign="center" mb={12}>
            <strong>Not on Strava?</strong> Select a popular race event below
          </Text>
          <PresetActivitiesList />
          <Text fontSize="md" textAlign="center" mt={6} color="gray.400">
            Can't find your race? Email us at{" "}
            <Link href="mailto:info@kudosmaps.com">
              <strong>info@kudosmaps.com</strong>
            </Link>{" "}
            and we'll add it for you!
          </Text>
        </>
      )}
    </Container>
  );
}

export default Activities;
