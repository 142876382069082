

import * as d3 from 'd3';
import svgBBox from 'svg-path-bbox'
import { mapToRange , getTextDimensions, getCenterFocusDimensions,getPace, calculateLockupHeights, addStats, addActivityIcon, drawCanvas, addTitle} from './canvasHelper'
import svgpath from 'svgpath'
import mapboxgl from 'mapbox-gl';
import Context from '../context'
const style_id = 'clhz2s2gr02c901qyduen7tex'
const username = 'bobbyhewitt'
const access_token = 'pk.eyJ1IjoiYm9iYnloZXdpdHQiLCJhIjoiY2xoeXd1cTN0MTdzYzNkbnRpazE5d2xhNyJ9.Ezxvt9Mq-Gf8hvCUa3vbxA'


function canvasCenter(params){
    const {ctx, width, height, setShowMap, heights, updateBuilder, mapWidth, mapHeight, mapImage, props} = params
    const {geoJSON, preset, footer, borderSize,showMap, paceChartCenteredVertically, paceChartScale,  elementSpacing, isMetric, splits_metric,splits_standard,strokeWidth} = props
    
    const showStats = footer === 'statsAndPaceChart' || footer === 'stats' ? true : false; 

    switch(preset){
        case 'map':
            return addMap()
        case 'route':
            return addRoute()
        case 'graph':
            return addPace()

    }
    
    function addPace(){ 
        console.log('adding pace', splits_metric, splits_standard)
        const splits = getPace(isMetric ? splits_metric : splits_standard) 
        const barSpacing =((width - borderSize * 2) + ((width - borderSize * 2) / splits.length) * 0.1  ) / splits.length
        const {w, h, topLockupHeight, bottomLockupHeight} = getCenterFocusDimensions(width,height, heights, props)
        
        let barPosition;
        if (paceChartCenteredVertically){
            barPosition = topLockupHeight + (h /2)
        } else {
            barPosition =  height - bottomLockupHeight
        } 
        for(var i = 0; i < splits.length; i++){
            const barHeight = (h * paceChartScale) * splits[i]
            const barX = borderSize + (barSpacing * i)
            const barY =  paceChartCenteredVertically ? barPosition - (barHeight / 2) : barPosition - barHeight
            const barWidth = barSpacing * 0.9 
            ctx.fillStyle = 'black'
            ctx.fillRect(barX, barY, barWidth, barHeight);    
        }
    }

    function addRoute(){
        const { w, h, topLockupHeight }= getCenterFocusDimensions(width, height, heights, props)
        const projection = d3
            .geoMercator()
            .fitSize([w , h ], geoJSON)
        const path = d3
            .geoPath()
            .projection(projection)     
        const svgPath = 
            path(geoJSON)
        const transformed = svgpath(svgPath)          
            .translate(borderSize, topLockupHeight)
            .rel()
            .round(1)
            .toString();
        const canvasPath = new Path2D(transformed);
        ctx.strokeStyle = '#000';
        ctx.lineWidth = strokeWidth;  
        const p = new Path2D(canvasPath);
        ctx.stroke(p);    
    }

    function addMap(){
      const { w, h, topLockupHeight }= getCenterFocusDimensions(width, height, heights, props)      
        if (mapImage){
            const base_image = new Image();
            base_image.src = mapImage
            const yPos = topLockupHeight
            base_image.onload = function(){
                ctx.drawImage(base_image, borderSize ,yPos , w,  h);
            };  
        } 
        if (mapWidth !== w || mapHeight !== h){
            // console.log('updating from within')
            // setShowMap(false)
            updateBuilder(null, null, { mapWidth: w, mapHeight: h})
        }
    }
}

export default canvasCenter
