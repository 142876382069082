import {
  Box,
  Container,
  Grid,
  GridItem,
  HStack,
  Link,
  Text,
} from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box py={6}>
      <Container maxW="container.xl">
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem
            colSpan={{ base: 2, md: 1 }}
            w="100%"
            textAlign={{ base: "center", md: "left" }}
          >
            <Text fontSize="sm">© Kudos Maps {new Date().getFullYear()}</Text>
          </GridItem>
          <GridItem
            colSpan={{ base: 2, md: 1 }}
            w="100%"
            textAlign={{ base: "center", md: "right" }}
          >
            <HStack
              justifyContent={{ base: "center", md: "end" }}
              flexWrap="wrap"
              spacing={{ base: 4, md: 8 }}
            >
              <Link as={RouterLink} to="/faq" fontSize="sm">
                FAQ
              </Link>
              <Link as={RouterLink} to="/cookie_policy" fontSize="sm">
                Cookie Policy
              </Link>
              <Link as={RouterLink} to="/privacy_policy" fontSize="sm">
                Privacy Policy
              </Link>
              <Link href="mailto:info@kudosmaps.com" fontSize="sm">
                Contact Us
              </Link>
            </HStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
