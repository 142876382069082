import React, { useContext, useEffect } from "react";
import Context from "../context";
import {
  Select,
  Flex,
  Box,
  Spinner,
  Text,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { ourShippingRates } from "../Helpers/shipping";
import { displayPrice } from "../Helpers/stripe";

const OrderSummary = ({ onChange, options, value }) => {
  const state = useContext(Context);

  const productPrice = state.builderConfig.product
    ? state.builderConfig.product.default_price.unit_amount / 100
    : 0;

  useEffect(() => {
    onChange(options[0].code); // Get first option in list
  }, []);

  return (
    <>
      <Select
        color="black"
        borderColor="gray.300"
        placeholder="Select shipping destination"
        defaultValue={options[0].code}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options &&
          options.map((item, i) => {
            return (
              <option key={i} value={item.code}>
                {item.name}
              </option>
            );
          })}
      </Select>

      {state.shippingRates === "loading" && (
        <Flex direction="column" align="center" justify="center" p="4">
          <Spinner color="black" />
          <Text color="black">Getting shipping costs and summary</Text>
        </Flex>
      )}

      {state.shippingRates &&
        state.shippingRates?.outcome !== "NotAvailable" &&
        state.shippingRates !== "loading" && (
          <>
            <Heading as="h3" size="sm" color="black" my="4" fontFamily="body">
              Shipping options
            </Heading>
            {state.shippingRates.options.map((rate) => {
              return (
                <Box key={rate.display_name} my={2}>
                  <Box display="flex" flex="row" justifyContent="space-between">
                    <Text
                      flex="1"
                      as="h4"
                      size="sm"
                      color="black"
                      fontWeight="semibold"
                    >
                      {rate.display_name}
                    </Text>
                    <Text flex="0" color="black" fontWeight="semibold">
                      {rate.fixed_amount.amount === 0
                        ? "Free"
                        : displayPrice(
                            rate.fixed_amount.amount,
                            rate.fixed_amount.currency
                          )}
                    </Text>
                  </Box>
                  <Box>
                    <Text color="gray.600" fontSize="xs">
                      Estimated {rate.delivery_estimate.minimum.value}
                      {rate.delivery_estimate.maximum.value > 1
                        ? ` - ${rate.delivery_estimate.maximum.value} `
                        : " "}
                      {rate.delivery_estimate.minimum.unit.replace("_", " ")}
                      {rate.delivery_estimate.maximum.value > 1 ? "s" : ""}
                    </Text>
                  </Box>
                </Box>
              );
            })}
            <Divider my={4} bg="gray.200" />
            <Box my={2}>
              <Box display="flex" flex="row" justifyContent="space-between">
                <Text
                  flex="1"
                  as="h4"
                  size="sm"
                  color="black"
                  fontWeight="semibold"
                >
                  Artwork cost
                </Text>
                <Text flex="0" color="black" fontWeight="semibold">
                  £{productPrice}
                </Text>
              </Box>
              <Box>
                <Text color="gray.600" fontSize="xs">
                  Shipping and total cost will be calculated at checkout
                </Text>
              </Box>
            </Box>
            <Divider my={4} bg="gray.200" />
          </>
        )}
    </>
  );
};

export default OrderSummary;
