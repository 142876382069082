export async function postData(url = "", data = {}) {
  try {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.log("err", err);
  }
}

export async function deleteData(url = "", data = {}) {
  try {
    const requestOptions = {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.log("err", err);
  }
}

export async function postStripeData(url = "", data = {}) {
  // return new Promise((resolve, reject) => {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  const response = await fetch(url, requestOptions);
  return response.json();
}

export const getData = (url = "", data = {}) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log("got bad response");
          reject();
        }
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject("err", err));
  });
};
