import { line } from "d3";
import React from "react";
import CookieBanner from "react-cookie-banner";
import { Link } from "react-router-dom";

const message = "We use cookies to give you the best experience.";

const styles = {
  banner: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    padding: "8px 16px",
  },
  message: {
    flex: 1,
    lineHeight: "20px",
    textAlign: "left",
  },
  button: {
    margin: 0,
    position: "static",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    backgroundColor: "transparent",
    color: "#fff",
  },
};

const Banner = () => {
  return (
    <CookieBanner
      styles={styles}
      message={message}
      link={
        <Link to="/cookie_policy" target="_blank">
          Read our cookie policy
        </Link>
      }
      buttonMessage="Accept"
      dismissOnScroll={true}
      dismissOnClick={true}
    />
  );
};

export default Banner;
