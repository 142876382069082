import { chakra } from "@chakra-ui/react";
import { ARTWORK_CAROUSEL_WIDTH } from "./ArtworkConfigurationCarousel";

function Swiper({ builderIndex, children, showSwiperCards }) {
  return (
    <chakra.div w={ARTWORK_CAROUSEL_WIDTH} overflow="hidden" mx="auto">
      <chakra.div
        h="300px"
        background="white"
        maxH="300px"
        minH="300px"
        transform={`translateX(${-builderIndex * ARTWORK_CAROUSEL_WIDTH}px)`}
        w={ARTWORK_CAROUSEL_WIDTH}
        display="-webkit-inline-box"
        transition={showSwiperCards ? "all 0.3s ease-in-out" : "none"}
      >
        {children}
      </chakra.div>
    </chakra.div>
  );
}

export default Swiper;
