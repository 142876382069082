import { useContext, useEffect } from "react";
import Context from "../context";
import UserContext from "../userContext";
import { useSearchParams } from "react-router-dom";
import { postData } from "../Helpers/http";
import { useNavigate } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";
import AppFrame from "../Components/AppFrame";
import { TailSpin } from "react-loader-spinner";

function TokenExchange() {
  const navigate = useNavigate();
  let [params] = useSearchParams();
  let state = useContext(Context);
  let userState = useContext(UserContext)
  const code = params.get("code");

  useEffect(() => {
    setUser();
  }, []);

  function setUser() {
    const code = params.get("code");
    postData(`${state.path}/user_login`, {
      code: code,
    })
    .then((res) => {
      userState.setUser(res.user)
      navigate(`/activities`);
      
    })
    .catch((err) => console.log("error"));
  }

  return (
    <Box
      h="100vh"
      w="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box display="inline-block" mb="4">
        <TailSpin
          height="45"
          width="45"
          color="#212121"
          ariaLabel="tail-spin-loading"
          radius="2"
          visible={true}
        />
      </Box>
      <Heading size="md" fontFamily="body" textAlign="center">
        Loading...
      </Heading>
    </Box>
  );
}

export default TokenExchange;
