import React from "react";
import AppFrame from "../Components/AppFrame";
import { Container, Heading, Text } from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <AppFrame>
      <Container minH="90vh" maxW="container.md" py={12}>
        <Heading size="xl" fontStyle="italic" textAlign="center" mb={8}>
          Privacy Policy
        </Heading>
        <Heading
          size="md"
          fontFamily="body"
          fontWeight="500"
          textAlign="center"
          mb={8}
        >
          Effective Date: 18th of June 2023
        </Heading>

        <Text>
          This Privacy Policy ("Policy") describes how Kudos Maps ("we," "us,"
          or "our") collects, uses, discloses, and protects the personal
          information you provide when using our website www.kudosmaps.com
          ("Website"). We are committed to safeguarding your privacy and
          ensuring the security of your personal information. By accessing or
          using our Website, you agree to the terms of this Policy.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          1. Information We Collect
        </Heading>
        <Text>
          We may collect various types of information from you when you interact
          with our Website, including:
        </Text>
        <Text>
          1.1 Personal Information: This includes information that can be used
          to identify you personally, such as your name, email address,
          telephone number, shipping and billing address, and payment
          information.
        </Text>
        <Text>
          1.2 Log Data: We automatically collect certain information when you
          visit our Website, such as your IP address, browser type, operating
          system, referring/exit pages, and date/time stamps. This information
          helps us analyze trends, administer the Website, track user movements,
          and gather demographic information.
        </Text>
        <Text>
          1.3 Cookies and Similar Technologies: We use cookies and similar
          technologies to collect information about your browsing behavior,
          preferences, and other statistical data. Please refer to our Cookie
          Policy for more details on how we use cookies and your options to
          manage them.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          2. Use of Information
        </Heading>
        <Text>
          We may use the information we collect from you for the following
          purposes:
        </Text>
        <Text>
          2.1 To provide and improve our services: We use your personal
          information to process and fulfill your orders, communicate with you
          about your purchases, and provide customer support. We may also use
          the information to enhance and personalize your browsing experience on
          our Website.
        </Text>
        <Text>
          2.2 Marketing and Promotional Communications: With your consent, we
          may send you marketing and promotional communications about our
          products, services, special offers, and events. You can opt-out of
          receiving these communications at any time.
        </Text>
        <Text>
          2.3 Legal Compliance: We may use and disclose your information to
          comply with applicable laws, regulations, legal processes, or
          governmental requests.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          3. Data Sharing and Disclosure
        </Heading>
        <Text>
          We may share your personal information with third parties under the
          following circumstances:
        </Text>
        <Text>
          3.1 Service Providers: We may engage trusted third-party service
          providers to assist us in operating our Website, processing payments,
          delivering products, and performing other business-related functions.
          These service providers have access to your personal information only
          as necessary to perform their functions and are obligated to maintain
          the confidentiality and security of your information.
        </Text>
        <Text>
          3.2 Legal Requirements: We may disclose your personal information if
          required to do so by law or in response to valid legal requests, such
          as a court order or government investigation.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          4. Data Security
        </Heading>
        <Text>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, loss, misuse, or alteration.
          However, no method of transmission over the internet or electronic
          storage is 100% secure, and we cannot guarantee absolute security.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          5. Your Rights and Choices
        </Heading>
        <Text>
          You have certain rights and choices regarding your personal
          information:
        </Text>
        <Text>
          5.1 Access and Correction: You can access, update, or correct your
          personal information by logging into your account or contacting us
          directly.
        </Text>
        <Text>
          5.2 Opt-Out: You can opt-out of receiving marketing and promotional
          communications from us by following the unsubscribe instructions
          provided in those communications or by contacting us.
        </Text>
        <Text>
          5.3 Cookies: You can manage cookies through your browser settings or
          by using the options provided in our Cookie Policy.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          6. Children's Privacy
        </Heading>
        <Text>
          Our Website is not directed to individuals under the age of 16, and we
          do not knowingly collect personal information from children. If you
          believe that we have inadvertently collected personal information from
          a child, please contact us, and we will take appropriate measures to
          remove the information.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          7. Changes to this Policy
        </Heading>
        <Text>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes we make will be effective immediately upon posting
          the revised Policy on our Website. We encourage you to review this
          Policy periodically to stay informed about how we collect, use, and
          protect your personal information.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          8. Contact Us
        </Heading>
        <Text>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at info@kudosmaps.com.
        </Text>
        <Text>Last updated: 18th June 2023</Text>
      </Container>
    </AppFrame>
  );
};

export default PrivacyPolicy;
