import { Box, chakra, Icon, Text } from "@chakra-ui/react";
import { ARTWORK_CAROUSEL_WIDTH } from "./ArtworkConfigurationCarousel";
import { useState, useEffect } from "react";
function SwiperCard({ children, supportiveText, showSwiperCards }) {
  const [isAnimatedIn, setIsAnimatedIn] = useState(false);

  useEffect(() => {
    setIsAnimatedIn(true);
  });
  return (
    <chakra.div
      w={ARTWORK_CAROUSEL_WIDTH}
      bg="white"
      px={4}
      boxSizing="border-box"
      h="300px"
      maxH="300px"
      transform={`translateY(${isAnimatedIn ? 0 : 20}px)`}
      opacity={isAnimatedIn ? 1 : 0}
      transition={"all 0.3s ease-out"}
      style={{ overflowY: "scroll" }}
    >
      {children}
      {supportiveText && (
        <Box
          mt={2}
          bg="gray.100"
          p="2"
          borderRadius="4"
          display="flex"
          gap="1"
          alignItems="center"
        >
          <Icon name="info" color="gray.700" fontSize="sm" />
          <Text color="gray.700" fontSize="xs">
            {supportiveText}
          </Text>
        </Box>
      )}
    </chakra.div>
  );
}

export default SwiperCard;
