import { getPresetConfigs } from "../Data/presetBuilderConfigs";
import countryCodes from "../Data/countryCodes";
import { postData } from "../Helpers/http";
import get from "lodash/get";
import { skuSelector } from "../Helpers/orderHelpers";
import { getCalculatedShippingOptions } from "../Helpers/shipping";
import colorOptions from "../Data/colorOptions";
import { getMasterScales } from "../Helpers/canvasHelper";
import LandscapeIcon from "../assets/aspectRatioIcons/icon-landscape-ratio.png";
import PortraitIcon from "../assets/aspectRatioIcons/icon-portrait-ratio.png";
import SquareIcon from "../assets/aspectRatioIcons/icon-square-ratio.png";

function generateControls(selectedControl, state, userState) {
  function addEndCards(arr) {
    const endCards = [
      {
        type: "toggles",
        label: "Would you like to add anyting?",
        supportiveText: null,
        value: true,
        display: true,
        onChange: (values) => {
          let header = !values.header
            ? false
            : values.subtitle && values.icon
            ? "titleSubtitleAndIcon"
            : values.subtitle && values.header
            ? "titleAndSubtitle"
            : values.icon
            ? "titleAndIcon"
            : "title";

          let footer =
            !values.paceChart && !values.stats
              ? false
              : values.paceChart && values.stats
              ? "statsAndPaceChart"
              : values.paceChart
              ? "paceChart"
              : "stats";

          state.updateBuilder(null, null, {
            header,
            footer,
          });
        },
        toggles: [
          {
            label: "Title Block",
            key: "header",
            display: state.builderConfig.selectElements.indexOf("header") > -1,
            value:
              state.builderConfig.header !== false &&
              state.builderConfig.header !== "none" &&
              state.builderConfig.header !== null,
          },
          {
            label: "Subtitle",
            key: "subtitle",
            display:
              state.builderConfig.selectElements.indexOf("subtitle") > -1,
            value:
              state.builderConfig.header === "titleAndSubtitle" ||
              state.builderConfig.header === "titleSubtitleAndIcon",
          },
          {
            label: "Activity icon",
            subLabel: "Shows an icon representing the activity type",
            key: "icon",
            display: state.builderConfig.selectElements.indexOf("icon") > -1,
            value:
              state.builderConfig.header === "titleAndIcon" ||
              state.builderConfig.header === "titleSubtitleAndIcon",
          },
          {
            label: "Statistics",
            subLabel: "Show stats like distance, time, pace and more",
            key: "stats",
            display: state.builderConfig.selectElements.indexOf("stats") > -1,
            value:
              state.builderConfig.footer === "stats" ||
              state.builderConfig.footer === "statsAndPaceChart",
          },
          {
            label: "Pace Chart",
            subLabel: "A bar chart showing your pace over time",
            key: "paceChart",
            display:
              state.builderConfig.selectElements.indexOf("paceChart") > -1,
            value:
              state.builderConfig.footer === "paceChart" ||
              state.builderConfig.footer === "statsAndPaceChart",
          },
        ],
      },
      {
        type: "textInput",
        label: "Edit your artwork header",
        display: state.builderConfig.header,
        supportiveText: null,
        value: true,
        onChange: (values) => {
          state.updateBuilder(null, null, values);
        },
        inputs: [
          {
            label: "Title",
            key: "title",
            display: true,
            value: state.builderConfig.title,
          },
          {
            label: "Subtitle",
            key: "subtitle",
            display:
              (state.builderConfig.header === "titleAndSubtitle") |
              (state.builderConfig.header === "titleAndIcon") |
              (state.builderConfig.header === "titleSubtitleAndIcon"),
            value: state.builderConfig.subtitle,
          },
        ],
      },
      {
        type: "radio",
        label: "Select a unit of measurement",
        options: [
          { label: "Metric (km)", key: true },
          { label: "Imperial (mi)", key: false },
        ],
        value: state.builderConfig.isMetric,
        onChange: (value) => state.updateBuilder("isMetric", value),
        display:
          state.builderConfig.footer === "stats" ||
          state.builderConfig.footer === "statsAndPaceChart" ||
          state.builderConfig.footer === "paceChart" ||
          state.builderConfig.preset === "graph",
      },
      {
        type: "stats",
        label: "Edit your activity statistics",
        display:
          state.builderConfig.footer === "stats" ||
          state.builderConfig.footer === "statsAndPaceChart",
        statOptions: state.statOptions,
        stats: state.builderConfig.stats,
        onChange: (value) => state.updateBuilder("stats", value),
        isMetric: state.builderConfig.isMetric,
      },
      // {
      //   type: "buttons",
      //   label: "Do you want to refine anything?",
      //   value: state.builderControlsId,
      //   display: true,
      //   CTA: "Review order",
      //   onChange: (value) => {
      //     state.setBuilderControlsId(value);
      //     state.setBuilderIndex(0);
      //   },
      //   options: [
      //     {
      //       label: "Title section",
      //       key: "titleLockup",
      //       subLabel: "Edit text, elements and position",
      //     },
      //     {
      //       label: "Focal point",
      //       key: "centerLockup",
      //       subLabel: "Edit map, line or chart",
      //     },
      //     {
      //       label: "Footer & statistics",
      //       key: "supportingElementsLockup",
      //       subLabel: "Edit stats and units of measurement",
      //     },
      //     {
      //       label: "Typography and spacing",
      //       key: "textAndSpacing",
      //       subLabel: "Change font, margins and spacing",
      //     },
      //     {
      //       label: "Start again",
      //       key: "presets",
      //       subLabel: "Reset all choices",
      //       type: "danger",
      //     },
      //   ],
      // },
      {
        type: "colour",
        label: "Select artwork colours",
        display: true,
        value: state.builderConfig.color,
        options: colorOptions,
        sendFullObject: true,
        onChange: (value) => {
          state.updateBuilder("color", {
            foreground: value.foreground,
            background: value.background,
          });
        },
      },
      {
        type: "orderSummary",
        label: "Select shipping destination",
        display: true,
        value: true,
        CTA: "To checkout",
        value: state.countryCode,
        options: countryCodes,
        onChange: (value) => {
          console.log("country", value);
          state.setShippingRates("loading");
          const selectedProduct = get(state, "builderConfig.product");
          const sku = skuSelector(state.builderConfig, selectedProduct);
          postData(`${state.path}/quote`, {
            sku,
            country: value,
          }).then((data) => {
            console.log("data", data);
            let newRates = {
              country: value,
              outcome: data.outcome,
              options: getCalculatedShippingOptions(data),
            };
            state.setShippingRates(newRates);
          });
          state.setCountryCode(value);
        },
      },
    ];
    let newArr = arr.concat(endCards);
    return newArr;
  }

  function getOrientationIcon(orientation) {
    switch (orientation) {
      case "landscape":
        return LandscapeIcon;
      case "portrait":
        return PortraitIcon;
      case "square":
        return SquareIcon;
      default:
        return LandscapeIcon;
    }
  }

  function getProducts() {
    let prods = state.products;
    let size = state.sizes.find((s) => s.key === state.builderConfig.imageSize);
    prods = prods.map((p, i) => {
      p.label = p.name;
      p.icon = getOrientationIcon(p.metadata.orientation);
      return p;
    });
    return prods;
  }

  function getPresets() {
    return [
      {
        type: "radio",
        label: "Select a preset to get started",
        supportiveText: "You can change the layout, content and text later",
        options: getPresetConfigs(state.builderConfig.isPrebuilt),
        value: state.builderConfig.presetKey,
        sendFullObject: true,
        onChange: (value) => {
          state.updateBuilder(null, null, { ...state.builderConfig, ...value });
        },
        display: true,
      },
      {
        type: "radio",
        label: "Select the artwork size",
        supportiveText: "Need a custom size? Contact us",
        options: getProducts(),
        sendFullObject: true,
        value: state.builderConfig.product
          ? state.builderConfig.product.key
          : false,
        onChange: (value) => {
          const masterScales = getMasterScales(value);
          state.updateBuilder(null, null, {
            imageSize: value.metadata.size_key,
            orientation: value.metadata.orientation,
            product: value,
            ...masterScales,
          });
        },
        display: true,
      },
    ];
  }

  function getTextAndSpacing() {
    return [
      {
        type: "radio",
        label: "Select a font",
        sendFullObject: true,
        options: [
          {
            label: "Standard",
            typefaces: ["Norwester", "Kollektif"],
            key: 0,
          },
          {
            label: "CooperHewitt",
            typefaces: ["CooperHewittBold", "CooperHewittLight"],
            key: 1,
          },
          {
            label: "UniSans",
            typefaces: ["UniSansHeavy", "UniSansThin"],
            key: 2,
          },
          {
            label: "ModerneSans",
            typefaces: ["ModerneSans", "Montserrat"],
            key: 3,
          },
        ],
        value: state.builderConfig.typography,
        onChange: (value) => {
          //   console.log(value);

          state.updateBuilder(null, null, {
            typography: value.key,
            titleTypeface: value.typefaces[0],
            subtitleTypeface: value.typefaces[1],
          });
        },
        display: true,
      },
      {
        type: "radio",
        label: "Select a text size",
        options: [
          { label: "Extra small", key: 0.75 },
          { label: "Small", key: 1 },
          { label: "Medium", key: 1.25 },
          { label: "Large", key: 1.5 },
        ],
        value: state.builderConfig.typeScale,
        onChange: (value) => state.updateBuilder("typeScale", value),
        display: true,
      },
      {
        type: "radio",
        label: "Select a border size",
        options: [
          { label: "Small", key: 500 },
          { label: "Medium", key: 750 },
          { label: "Big", key: 1000 },
        ],
        value: state.builderConfig.borderSize,
        onChange: (value) => state.updateBuilder("borderSize", value),
        display: true,
      },
      {
        type: "radio",
        label: "Select the spacing size between elements",
        options: [
          { label: "Compact", key: 100 },
          { label: "Comfortable", key: 300 },
          { label: "Spacious", key: 400 },
        ],
        value: state.builderConfig.elementSpacing,
        onChange: (value) => state.updateBuilder("elementSpacing", value),
        display: true,
      },
    ];
  }

  function getTitleLockup() {
    return [
      {
        type: "radio",
        label: "Select header content",
        supportiveText: "You can edit the title text and font size later",
        options: [
          { label: "No header", key: false },
          { label: "Small title", key: "title" },
          { label: "Full width title", key: "fullWidthTitle" },
          { label: "Title with icon", key: "titleAndIcon" },
          { label: "Title & subtitle", key: "titleAndSubtitle" },
          { label: "Title & subtitle with icon", key: "titleSubtitleAndIcon" },
        ],
        value: state.builderConfig.header,
        onChange: (value) => {
          let largeIcon =
            value !== "titleSubtitleAndIcon"
              ? false
              : state.builderConfig.largeIcon;
          state.updateBuilder(null, null, {
            largeIcon,
            fullWidthTitle: value === "fullWidthTitle",
            header: value,
          });
        },
        display: true,
      },
      // {
      //   type: "textInput",
      //   label: "Add a title and subtitle",
      //   display: true,
      //   supportiveText: null,
      //   value: true,
      //   onChange: (values) => {
      //     console.log(state.builderConfig);

      //     state.updateBuilder(null, null, values);
      //   },
      //   inputs: [
      //     {
      //       label: "Title",
      //       key: "title",
      //       value: state.builderConfig.title,
      //       // onChange: (key, value) => {
      //       //     console.log('should update', key, value)
      //       //     console.log('builder title', state.builderConfig.title)
      //       //   state.updateBuilder(key, value);
      //       // },
      //     },
      //     {
      //       label: "Subtitle",
      //       key: "subtitle",
      //       value: state.builderConfig.subtitle,
      //       // onChange: (key, value) => {
      //       //   state.updateBuilder(key, value);
      //       // },
      //     },
      //   ],
      // },
      {
        type: "radio",
        label: "Select icon size",
        options: [
          { label: "Large", key: true },
          { label: "Small", key: false },
        ],
        value: state.builderConfig.largeIcon,
        onChange: (value) => {
          state.updateBuilder("largeIcon", value);
        },
        display: state.builderConfig.header === "titleSubtitleAndIcon",
      },
      {
        type: "radio",
        label: "Select title placement",
        supportiveText: null,
        options: [
          { label: "At the top", key: false },
          { label: "At the bottom", key: true },
        ],
        value: state.builderConfig.swapTitleAndStats,
        onChange: (value) => {
          state.updateBuilder("swapTitleAndStats", value);
        },
        display: true,
      },
    ];
  }

  function getSupportingElementsLockup() {
    return [
      {
        type: "radio",
        label: "Select footer content",
        supportiveText:
          "You can change the stats and height of the pace chart later",
        options: [
          { label: "Nothing", key: false },
          { label: "Statistics only", key: "stats" },
          {
            label: "Pace chart only",
            key: "paceChart",
            hide:
              state.builderConfig.preset === "graph" ||
              state.builderConfig.isPrebuilt,
          },
          {
            label: "Statistics and pace chart",
            key: "statsAndPaceChart",
            hide:
              state.builderConfig.preset === "graph" ||
              state.builderConfig.isPrebuilt,
          },
          // { label: "Title at the bottom", key: 'bottomTitle' },
        ],
        value: state.builderConfig.footer,
        onChange: (value) => {
          state.updateBuilder(null, null, {
            footer: value,
            stats:
              state.builderConfig.stats.length === 0
                ? state.statOptions.filter((s) => s.selected)
                : state.builderConfig.stats,
          });
        },
        display: true,
      },
      {
        type: "radio",
        label: "Select a unit of measurement",
        options: [
          { label: "Metric (km)", key: true },
          { label: "Imperial (mi)", key: false },
        ],
        value: state.builderConfig.isMetric,
        onChange: (value) => state.updateBuilder("isMetric", value),
        display:
          state.builderConfig.footer === "stats" ||
          state.builderConfig.footer === "statsAndPaceChart" ||
          state.builderConfig.footer === "paceChart" ||
          state.builderConfig.preset === "graph",
      },
      {
        type: "stats",
        label: "Select and order statistics",
        display:
          state.builderConfig.footer === "stats" ||
          state.builderConfig.footer === "statsAndPaceChart",
        statOptions: state.statOptions,
        stats: state.builderConfig.stats,
        onChange: (value) => state.updateBuilder("stats", value),
        isMetric: state.builderConfig.isMetric,
      },
      {
        type: "radio",
        label: "Select title placement",

        options: [
          { label: "At the top", key: false },
          { label: "At the bottom", key: true },
        ],
        value: state.builderConfig.swapTitleAndStats,
        onChange: (value) => {
          state.updateBuilder("swapTitleAndStats", value);
        },
        display: true,
      },
    ];
  }

  function getCenterLockup() {
    return [
      {
        type: "radio",
        label: "Select a focal piece",
        options: [
          { key: "map", label: "Map" },
          { key: "route", label: "Route line" },
          {
            key: "graph",
            label: "Pace chart",
            hide: state.builderConfig.isPrebuilt,
          },
        ],
        value: state.builderConfig.preset,
        onChange: (value) => {
          const paceChartScale = value === "graph" ? 1 : 0.25;

          const showPaceChart =
            value === "graph" ? true : state.builderConfig.showPaceChart;
          state.updateBuilder(null, null, {
            preset: value,
            paceChartScale,
            paceChartCenteredVertically: false,
            showPaceChart,
          });
        },
        display: true,
      },
      {
        type: "radio",
        label: "Select chart alignment",
        options: [
          { label: "Bottom aligned ", key: false },
          { label: "Centered", key: true },
        ],
        value: state.builderConfig.paceChartCenteredVertically,
        onChange: (value) =>
          state.updateBuilder("paceChartCenteredVertically", value),
        display: state.builderConfig.preset === "graph",
      },
      {
        type: "radio",
        label: "Select route line thickness",
        options: [
          { label: "Thin", key: 10 },
          { label: "Elegant", key: 20 },
          { label: "Chunky", key: 40 },
          { label: "Extra chunky", key: 60 },
        ],
        value: state.builderConfig.strokeWidth,
        onChange: (value) => state.updateBuilder("strokeWidth", value),
        display:
          state.builderConfig.preset === "route" ||
          state.builderConfig.preset === "map",
      },
      {
        type: "radio",
        label: "Select chart height",
        options: [
          { label: "Short", key: 0.25 },
          { label: "Medium", key: 0.5 },
          { label: "Tall", key: 0.75 },
          { label: "Giant", key: 1 },
        ],
        value: state.builderConfig.paceChartScale,
        onChange: (value) => state.updateBuilder("paceChartScale", value),
        display:
          state.builderConfig.footer === "statsAndPaceChart" ||
          state.builderConfig.footer === "paceChart" ||
          state.builderConfig.preset === "graph",
      },
    ];
  }

  switch (selectedControl) {
    case "presets":
    case "default":
      const presets = getPresets();
      return addEndCards(presets);
    case "textAndSpacing":
      const textAndSpacing = getTextAndSpacing();
      return addEndCards(textAndSpacing);
    case "titleLockup":
      const titleLockup = getTitleLockup();
      return addEndCards(titleLockup);
    case "supportingElementsLockup":
      const supportingElementsLockup = getSupportingElementsLockup();
      return addEndCards(supportingElementsLockup);
    case "centerLockup":
      const centerLockup = getCenterLockup();
      return addEndCards(centerLockup);
  }
}

export default generateControls;
