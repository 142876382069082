import { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import Context from "../context";
import {
  getTypography,
  calculateLockupHeights,
  drawCanvas,
} from "../Helpers/canvasHelper";
import { Box, Text } from "@chakra-ui/react";

import canvasHeader from "../Helpers/canvasHeader";
import canvasFooter from "../Helpers/canvasFooter";
import canvasCenter from "../Helpers/canvasCenter";
import FullColorPreview from "../Components/FullColorPreview";
import StyledFrame from "../Components/StyledFrame";

const RenderCanvas = styled.canvas`
  max-height: 50vh;
  max-width: ${(props) => (props.adminCanvas ? "50%" : "100%")};
  z-index: 1;
  @media (min-width: 768px) {
    max-height: calc(100vh - 200px);
  }
`;

function Canvas(props) {
  const {
    preset,
    setShowMap,
    showMap,
    imageSize,
    title,
    subtitle,
    typeScale,
    titleScale,
    adminCanvas,
    product,
    titleTypeface,
    subtitleTypeface,
    largeIcon,
    fullWidthTitle,
    exportFinalImages,
    borderSize,
    useIconForStats,
    updateBuilder,
    elementSpacing,
    isMetric,
    updater,
    header,
    swapTitleAndStats,
    footer,
    paceChartHeight,
    strokeWidth,
    mapRotation,
    sendImage,
    stats,
    paceChartCenteredVertically,
    mapScale,
    customText,
    masterScale,
    paceChartScale,
    showFullColorPreview,
  } = props;

  const canvasRef = useRef(null);
  const state = useContext(Context);
  const [ctx, setCtx] = useState(null);
  const [width, setWidth] = useState(state.initialWidth);
  const [height, setHeight] = useState(state.initialWidth * 1.414);
  const [heights, setHeights] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [typography, setTypography] = useState(null);
  const [testImage, setTestImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [adjustedMasterScale, setAdjustedMasterScale] = useState(
    adminCanvas ? props.adminMasterScale : masterScale
  );
  useEffect(() => {
    setupCanvas();
  }, []);

  useEffect(() => {
    if (ctx) {
      let type = getTypography(props, ctx);
      setTypography(type);
    }
  }, [ctx, product]);

  useEffect(() => {
    if (typography) {
      setupHeights(ctx);
    }
  }, [typography]);

  useEffect(() => {
    if (typography) {
      let type = getTypography(props, ctx, typography);
      setTypography(type);
      setupHeights(ctx);
    }
  }, [
    imageSize ? imageSize.key : null,
    borderSize,
    props.name,
    title,
    width,
    adjustedMasterScale,
    titleTypeface,
    subtitleTypeface,
    titleScale,
    typeScale,
    fullWidthTitle,
  ]);

  useEffect(() => {
    if (exportFinalImages) {
      let images = {};
      if (adminCanvas) {
        images.forprinter = exportImageForPrinter(props.color);
      } else {
        images.standard = exportImage();
        images.thumbnail = exportImage(true);
      }
      state.setFinalImages(images);
    }
  }, [exportFinalImages]);

  function exportImage(micro) {
    const canvas = canvasRef.current;
    const dataURL = micro
      ? canvas.toDataURL("image/jpeg", 0.1)
      : canvas.toDataURL("image/png");
    return dataURL;
  }

  // function invertImage() {
  //   // ctx.willReadFrequently = true;
  //   const imageData = ctx.getImageData(0, 0, width, height);
  //   let newImageData = ctx.createImageData(imageData);
  //   for (var i = 0; i < imageData.data.length; i++) {
  //     if (i % 4 < 3) {
  //       newImageData.data[i] = imageData.data[i] < 255 ? 255 : 0;
  //     } else {
  //       newImageData.data[i] = 255;
  //     }
  //   }
  //   ctx.putImageData(newImageData, 0, 0);
  //   return exportImage();
  // }

  // function exportImageForPrinter() {
  //   ctx.willReadFrequently = true;
  //   const imageData = ctx.getImageData(0, 0, width, height);
  //   let newImageData = ctx.createImageData(imageData);
  //   for (var i = 0; i < imageData.data.length; i++) {
  //     if (i % 4 === 3) {
  //       if ( i === 0 || (imageData.data[i-1] && imageData.data[i-1] === 255)){
  //         newImageData.data[i] = 0
  //       } else{
  //         newImageData.data[i] = 255
  //       }

  //     } else {
  //       if (newImageData.data[i] < 255){
  //         newImageData.data[i] = 0
  //       } else {
  //         newImageData.data[i] = 255
  //       }
  //       // newImageData.data[i] = 255;
  //     }
  //   }
  //   ctx.putImageData(newImageData, 0, 0);
  //   return exportImage();
  // }

  function getRGBFromColorName(color) {
    switch (color) {
      case "black":
        return {
          r: 0,
          g: 0,
          b: 0,
        };
      case "white":
        return {
          r: 255,
          g: 255,
          b: 255,
        };
      default:
        return null;
    }
  }

  function getRGBFromColorString(color) {
    if (color) {
    }
    color = color.replace("rgb(", "");
    color = color.replace(")", "");
    color = color.split(",");
    return {
      r: color[0],
      g: color[1],
      b: color[2],
    };
  }

  useEffect(() => {
    if (showFullColorPreview) {
      if (ctx) {
        ctx.willReadFrequently = true;
        setImageData(ctx.getImageData(0, 0, width, height));
      }
    } else {
      setImageData(null);
    }
  }, [showFullColorPreview]);

  function exportImageForPrinter(color, isTestImage) {
    const isFoil = color.foreground === "silver" || color.foreground === "gold";
    const foregroundColorObject = getRGBFromColorName(color.foreground);
    const backgroundColorObject = getRGBFromColorString(color.background);
    ctx.willReadFrequently = true;
    const imageData = ctx.getImageData(0, 0, width, height);
    let newImageData = ctx.createImageData(imageData);
    for (var x = 0; x < imageData.width; x++) {
      for (var y = 0; y < imageData.height; y++) {
        var r = imageData.data[y * (imageData.width * 4) + x * 4];
        var g = imageData.data[y * (imageData.width * 4) + x * 4 + 1];
        var b = imageData.data[y * (imageData.width * 4) + x * 4 + 2];
        var a = imageData.data[y * (imageData.width * 4) + x * 4 + 3];
        if (r < 255 || g < 255 || b < 255) {
          if (isFoil) {
            //set all detail to be transparent
            imageData.data[y * (imageData.width * 4) + x * 4 + 3] = 0;
          } else {
            // set detail colour with foregroundColorObject
            imageData.data[y * (imageData.width * 4) + x * 4] =
              foregroundColorObject.r;
            imageData.data[y * (imageData.width * 4) + x * 4 + 1] =
              foregroundColorObject.g;
            imageData.data[y * (imageData.width * 4) + x * 4 + 2] =
              foregroundColorObject.b;
          }
        } else {
          // set background colour
          imageData.data[y * (imageData.width * 4) + x * 4] =
            backgroundColorObject.r;
          imageData.data[y * (imageData.width * 4) + x * 4 + 1] =
            backgroundColorObject.g;
          imageData.data[y * (imageData.width * 4) + x * 4 + 2] =
            backgroundColorObject.b;
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
    return exportImage();
  }

  useEffect(() => {
    if (width && height && ctx && typography && heights) {
      drawCanvas(ctx);
      if (header) {
        canvasHeader({
          ctx,
          width,
          height,
          heights,
          typography,
          props,
        });
      }
      canvasFooter({
        ctx,
        width,
        height,
        heights,
        typography,
        props,
      });
      canvasCenter({
        ctx,
        width,
        setShowMap,
        showMap,
        height,
        heights,
        typography,
        updateBuilder,
        mapWidth: state.builderConfig.mapWidth,
        mapHeight: state.builderConfig.mapHeight,
        mapImage: state.mapImage,
        props,
      });
    }
  }, [
    width,
    height,
    header,
    footer,
    typeScale,
    stats,
    swapTitleAndStats,
    preset,
    paceChartHeight,
    heights ? heights.title : null,
    typography ? typography.title : null,
    fullWidthTitle,
    isMetric,
    title,
    subtitle,
    state.statOptions,
    useIconForStats,
    mapScale,
    borderSize,
    typography,
    elementSpacing,
    mapRotation,
    strokeWidth,
    titleScale,
    imageSize,
    updater,
    ctx,
    typeScale,
    paceChartCenteredVertically,
    heights,
    largeIcon,
    customText,
    paceChartScale,
    state.mapImage,
    // state.builderConfig
  ]);

  useEffect(() => {
    if (product) {
      let resolutionA = adminCanvas
        ? product.metadata.resolutionA
        : product.safeResolutionA;
      let resolutionB = adminCanvas
        ? product.metadata.resolutionB
        : product.safeResolutionB;
      if (imageSize.orientation === "portrait") {
        setHeight(resolutionA);
        setWidth(resolutionB);
      } else {
        setHeight(resolutionA);
        setWidth(resolutionB);
      }
    }
  }, [
    imageSize,
    product ? product.metadata.resolutionA : null,
    product ? product.metadata.resolutionB : null,
  ]);

  function setupCanvas() {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d", { alpha: false });
    context.letterSpacing = "16px";
    context.imageSmoothingEnabled = true;
    context.textBaseline = "alphabetic";
    setCtx(context);
  }

  function getCanvasImageData() {
    const imageData = ctx.getImageData(0, 0, width, height);
    return imageData;
  }

  function setupHeights(ctx) {
    let allTheHeights = calculateLockupHeights(
      ctx,
      typography,
      adjustedMasterScale,
      typeScale
    );
    allTheHeights.pace = paceChartHeight;
    setHeights(allTheHeights);
  }

  function sendImageUp() {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");
    sendImage(dataURL);
  }

  function createColourPreview(image) {
    URL.revokeObjectURL(testImage);
    setTestImage(image);
  }

  return (
    <>
      <StyledFrame display={!showFullColorPreview || adminCanvas}>
        <RenderCanvas
          style={showFullColorPreview ? { width: 0, height: 0 } : {}}
          fullScreen={fullScreen}
          ref={canvasRef}
          width={width}
          height={height}
        />
      </StyledFrame>
      {imageData && (
        <FullColorPreview
          imageData={imageData}
          width={width}
          height={height}
          color={props.color}
        />
      )}
      {!adminCanvas && (
        <Text color="black" mt="4">
          This is a preview. Final printed product may vary.
        </Text>
      )}
    </>
  );
}

export default Canvas;
