import React, { useState } from "react";
import { Provider } from "./adminContext";

const AdminProvider = (props) => {
  const API_URL =
    process.env.NODE_ENV === "production"
      ? "https://www.kudosmaps.com/api/admin"
      : "http://localhost:5001/api/admin";

  const [orders, setOrders] = useState(null);
  const [order, setOrder] = useState("");
  const [users, setUsers] = useState([]);
  const [path, setPath] = useState(API_URL);
  const [preBuiltRoutes, setPreBuiltRoutes] = useState(null);

  return (
    <Provider
      value={{
        path,
        orders,
        setOrders,
        preBuiltRoutes,
        setPreBuiltRoutes,
        order,
        setOrder,
        users,
        setUsers,
      }}
    >
      {props.children}
    </Provider>
  );
};

export default AdminProvider;
