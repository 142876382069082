import { useContext, useEffect, useState, useRef } from "react";
import Context from "../context";
import UserContext from "../userContext";
import MapContext from "../mapContext";

import { useSearchParams } from "react-router-dom";
import { getData } from "../Helpers/http";
import { createStats, getMapBounds } from "../Helpers/canvasHelper";
import poly from "@mapbox/polyline";
import { Flex, Box, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Spinner } from '@chakra-ui/react'

import {
  Canvas,
  CreateArtworkStepper,
  CustomiseText,
  CustomiseStats,
} from "../Components";

import dateFormat from "dateformat";
import { getProducts } from "../Helpers/stripe";
import presetBuilderConfigs from "../Data/presetBuilderConfigs";
const WallBackground = require("../assets/wall-bg.jpg");
function ActivityDetail() {
  let [params] = useSearchParams();
  const id = params.get("id");
  let state = useContext(Context);
  const userState = useContext(UserContext);
  const mapState = useContext(MapContext);
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(null);
  
  const maxResolutionA = {
    paper: 3380 / 10,
    long: 2843 / 10,
    square: 4020 / 10,
  };

  useEffect(() => {
    //load avaialalbe products and frames.
    console.log('using effect')
    getProducts(state.path).then((res) => {
      console.log('got profucsts', res.products)
      setMaxSize(res.products).then((newProducts) => {
        console.log(newProducts)
        state.setProductsAndSizes(newProducts);
        // state.setFrames(res.frames);
      });
    });
    // return () => {
    //   state.updateBuilder(null, null, presetBuilderConfigs[0]);
    // };
  }, []);

  function setMaxSize(products) {
    console.log('setting max size')
    return new Promise((resolve, reject) => {
      products = products.map((product, i) => {
        console.log('setting max size for product')
        const { metadata } = product;
        if (
          metadata?.aspect_ratio &&
          metadata?.resolutionA > maxResolutionA[metadata?.aspect_ratio]
        ) {
          console.log('metadata exeeding max', product)
          const aspectRatio = metadata.resolutionB / metadata.resolutionA;
          product.safeResolutionA = maxResolutionA[metadata?.aspect_ratio];
          product.safeResolutionB = Math.floor(
            product.safeResolutionA * aspectRatio
          );
          return product;
        } else {
          console.log('metadata not exeeding', product)
          product.safeResolutionA = metadata.resolutionA;
          product.safeResolutionB = metadata.resolutionB;
          return product;
        }
      });

      resolve(products);
    });
  }

  useEffect(() => {
    if (state.products && state.sizes) {
      getActivity();
    }
  }, [state.products, state.sizes]);

  function getActivity() {
    getData(`${state.path}/activity/${id}`)
      .then((res) => {
        processActivity(res);
      })
      .catch((err) => console.log("error", err));
  }

  function processActivity(res) {

    // Set activity in state
    console.log("saving activity");
    state.setActivity(res);
    // Create stats for canvas
    const statOptions = createStats(res);
    state.setStatOptions(statOptions);
    let defaultProduct = state.products.filter(
      (p) => p.metadata.aspect_ratio === state.builderConfig.imageSize
    );

    defaultProduct = defaultProduct.reduce((previous, current) => {
      return current.metadata.resolutionA < previous.metadata.resolutionA
        ? current
        : previous;
    });

    // defaultProduct= defaultProduct[0]
    const starterStats = statOptions.filter((s) => s.selected);
    const starterStatKeys = [];
    for (let i = 0; i < starterStats.length; i++) {
      starterStatKeys.push(starterStats[i].key);
    }

    // Set up map boundaries
    const gj = poly.toGeoJSON(res.map.summary_polyline);
    const mapBounds = getMapBounds(gj);
    state.setgeoJSON(gj);
    console.log("saving activity in builder");
    // update the bilder with all the above
    state.updateBuilder(null, null, {
      product: defaultProduct,
      activity: res,
      masterScale: defaultProduct.metadata.resolutionA / 6000,
      title: state.builderConfig.title ? state.builderConfig.title : res.name,
      stats:
        state.builderConfig.stats && state.builderConfig.stats.length > 0
          ? state.builderConfig.stats
          : starterStatKeys,
      subtitle: state.builderConfig.subtitle
        ? state.builderConfig.subtitle
        : dateFormat(res.start_date_local, "dddd, mmmm dS yyyy").toUpperCase(),
      mapBounds: mapBounds,
    });
    navigate(`/activity?id=${id}`);
    
  }


  console.log('rhius is loading')
  return (

    <>
     <Spinner />
    </>
  );
}

export default ActivityDetail;
