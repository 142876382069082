import { Flex, Text} from "@chakra-ui/react";
import Lottie from "lottie-react";

import scrollAnimation from '../assets/scroll-down.json'




function ScrollForOptions() {
    return(
        
       
  <Flex 
   
    flex="1" 
    // pt={4}
    display={['flex', 'none']}
    align="center" 
    justify="center" 
      // opacity="0.75"
    direction="row" 
    >
    <Lottie  style={{width:'24px'}}animationData={scrollAnimation} loop={true} />  
    {/* <Text >
        Scroll for options    
    </Text>     */}
   
  </Flex>
  
  );
}

export default ScrollForOptions;
