import {
  Button,
  Divider,
  Stack,
  Box,
  IconButton,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { useContext } from "react";
import Context from "../context";
import { Bars2Icon, XMarkIcon, PlusIcon } from "@heroicons/react/24/solid";
import ReactDragListView from "react-drag-listview";
import TextInputs from "./TextInputs";
import { v4 as uuidv4 } from "uuid";

function CustomiseStats({ statOptions, stats, onChange, isMetric }) {
  const state = useContext(Context);

  function onEditText(values, key) {
    let newStatOptions = Object.assign([], state.statOptions);
    for (var i = 0; i < newStatOptions.length; i++) {
      if (newStatOptions[i].key === key) {
        newStatOptions[i].customLabel = values.customLabel;
        newStatOptions[i].customValue = values.customValue;
      }
    }
    state.setStatOptions(newStatOptions);
  }

  const createCustomStat = () => {
    const id = uuidv4();
    console.log(id);
    let newStatOptions = Object.assign([], state.statOptions);
    let newStats = Object.assign([], stats);

    newStatOptions.push({
      customLabel: "Label",
      customValue: "Statistic",
      key: id,
    });
    state.setStatOptions(newStatOptions);
    newStats.push(id);
    onChange(newStats);
  };

  const onAdd = (item) => {
    let newStats = Object.assign([], stats);
    newStats.push(item.key);
    onChange(newStats);
  };
  const onRemove = (i) => {
    let newStats = Object.assign([], stats);
    newStats.splice(i, 1);
    onChange(newStats);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let newStats = Object.assign([], stats);
      const item = newStats.splice(fromIndex, 1)[0];
      newStats.splice(toIndex, 0, item);
      onChange(newStats);
    },
    nodeSelector: ".draggable",
    handleSelector: "a",
  };

  return (
    <>
      {statOptions && statOptions.length > 0 && (
        <>
          <Stack spacing={4}>
            <ReactDragListView {...dragProps}>
              {stats &&
                stats.map((stat, i) => {
                  const item = statOptions.find((s) => s.key === stat);
                  return (
                    <AddedStat
                      index={i}
                      onEditText={onEditText}
                      itemKey={item.key}
                      key={i}
                      label={isMetric ? item.label : item.imperialLabel}
                      onRemove={onRemove}
                      {...item}
                    />
                  );
                })}
            </ReactDragListView>
          </Stack>
          <Divider />
          <Stack spacing="4" w="full">
            {statOptions &&
              statOptions
                .filter((s) => {
                  if (!s.display) return false;
                  for (let i = 0; i < stats.length; i++) {
                    if (stats[i] === s.key) return false;
                  }
                  return true;
                })
                .map((item, j) => {
                  return (
                    <Flex
                      key={`i${j}`}
                      direction="row"
                      align="center"
                      justify="center"
                    >
                      <Button
                        w="full"
                        onClick={() => onAdd(item)}
                        size="lg"
                        variant="outlineDark"
                        rightIcon={
                          <PlusIcon style={{ width: "24px", height: "24px" }} />
                        }
                        px={4}
                        pr={4}
                      >
                        <Box flex="1" textAlign="left">
                          {!isMetric && item.imperialLabel
                            ? item.imperialLabel
                            : item.label}
                        </Box>
                      </Button>
                    </Flex>
                  );
                })}
            {/* <Button variant="outline" color="black" onClick={createCustomStat}>
              Add custom statistic
            </Button> */}
          </Stack>
        </>
      )}
    </>
  );
}

function AddedStat({
  label,
  onEditText,
  onRemove,
  itemKey,
  imperialValue,
  value,
  imperialLabel,
  customValue,
  customLabel,
  isMetric,
  index,
}) {
  return (
    <Box
      border="1px"
      borderColor="gray.100"
      px={2}
      py={1}
      borderRadius="lg"
      mb={1}
      className="draggable"
    >
      <Flex direction="row" alignItems="center" gap={2}>
        <Box>
          <a style={{ width: "44px", height: "44px" }} title="Drag to re-order">
            <Flex
              flex="1"
              direction="row"
              justify="center"
              align="center"
              style={{ height: "100%" }}
            >
              <Bars2Icon
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#212121",
                  cursor: "grab",
                }}
              />
            </Flex>
          </a>
        </Box>
        <Flex flex="1" align="center">
          <HStack spacing={1}>
            <TextInputs
              onChange={(values) => onEditText(values, itemKey)}
              inputs={[
                {
                  key: "customLabel",
                  label: "Label",
                  display: true,
                  value: customLabel
                    ? customLabel
                    : imperialLabel && !isMetric
                    ? imperialLabel
                    : label,
                },
                {
                  key: "customValue",
                  label: "Value",
                  display: true,
                  value: customValue
                    ? customValue
                    : imperialValue && !isMetric
                    ? imperialValue
                    : value,
                },
              ]}
            />
          </HStack>
        </Flex>
        <IconButton
          variant="ghost"
          colorScheme="black"
          size="xs"
          onClick={() => onRemove(index)}
          icon={
            <XMarkIcon
              color="black"
              style={{ width: "24px", height: "24px" }}
            />
          }
        />
      </Flex>
    </Box>
  );
}

export default CustomiseStats;
