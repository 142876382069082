import React, { useEffect, useState, useContext } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { getData } from "../Helpers/http";
import Context from "../context";
import Activity from "./Activity";
import { useNavigate } from "react-router-dom";
import AbandonedCart from "./AbandonedCart";

function LastConfigurationBanner({ lastConfiguration }) {
  const navigate = useNavigate();
  const state = useContext(Context);
  const activity = lastConfiguration?.activity;

  useEffect(() => {
    getData(`${state.path}/activity/${lastConfiguration.activity?.id}`)
      .then((res) => {
        
        state.setActivity(res);
      })
      .catch((err) => console.log("error", err));
  }, []);

  useEffect(() => {
    if (state.builderConfig && state.builderConfig.activityId) {
      
      navigate(`/activity?id=${lastConfiguration.activityId}`);
    }
  }, [state.builderConfig ? state.builderConfig.activityId : null]);

  function onSelect() {
    
    state.updateBuilder(null, null, {
      ...lastConfiguration,
      updater: 1,
    });
  }

  return (
    <>
      {activity && activity.elapsed_time && (
        <AbandonedCart
          isContinue
          activity={activity}
          {...activity}
          onClick={onSelect}
        />
      )}
    </>
  );
}

export default LastConfigurationBanner;
