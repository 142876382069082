import { getData, postData } from "./http";

export const createCheckoutSession = ({
  path,
  line_items,
  orderId,
  country,
  sku,
  activityId,
  shipping_options,
}) => {
  return postData(`${path}/create-checkout-session`, {
    line_items,
    country,
    sku,
    activityId,
    shipping_options,
    orderId,
  })
    .then((res) => {
      if (res.url) {
        window.location.href = res.url;
      } else {
        console.log("no url");
      }
    })
    .catch((err) => console.log("error", err));
};

export const getProducts = (path) => {
  return new Promise((resolve, reject) => {
    getData(`${path}/products`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => console.log("error", err));
  });
};

export function displayPrice(amount, currency) {
  const amountInCents = amount / 100;
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency,
  }).format(amountInCents);
}
