import { useEffect, useState, useRef, useContext } from 'react'
import { getData } from '../Helpers/http'
import Context from '../context'
// import mapboxgl from 'mapbox-gl';
import styled from 'styled-components'
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
/* eslint import/no-webpack-loader-syntax: off */
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.accessToken ='pk.eyJ1IjoiYm9iYnloZXdpdHQiLCJhIjoiY2xoeXd1cTN0MTdzYzNkbnRpazE5d2xhNyJ9.Ezxvt9Mq-Gf8hvCUa3vbxA'
mapboxgl.workerClass = MapboxWorker; 



const Mapbox = ({props}) => {
	let state = useContext(Context)
	const mapContainer = useRef(null);
	const map = useRef(null);
	const timer = useRef(null)

useEffect(() => {
	if (map.current) return;
		map.current = new mapboxgl.Map({
		attributionControl: false,
		container: mapContainer.current,
		style: 'mapbox://styles/bobbyhewitt/clizqj2z400qr01qyc34f1d1q',
		zoom: 1,
		offscreen:true,
		// preserveDrawingBuffer:true
	});
	
},[]);

useEffect(( )=>{
	if (state.builderConfig.mapBounds && state.builderConfig.preset === 'map'){
		generateMap()
	}
},[state.builderConfig.mapWidth, state.showMap, state.builderConfig.mapHeight, state.builderConfig.strokeWidth, state.builderConfig.mapBounds])

function generateMap(){
	if (map.current && map.current.isStyleLoaded()){	
		map.current.resize(state.builderConfig.mapWidth, state.builderConfig.mapHeight)
		if (map.current && state.builderConfig.mapBounds){
			map.current.fitBounds(state.builderConfig.mapBounds, {animate: false});
		}
	}
}

useEffect(()=> {

	if (state.geoJSON && map.current){
		const strokeWidth = Math.floor(state.builderConfig.strokeWidth * state.builderConfig.masterScale) || 20
		addLayers(strokeWidth)
	}
},[
	state.geoJSON, 
	map.current,
	state.builderConfig.strokeWidth, 
	state.builderConfig.masterScale])


function addLayers(strokeWidth){
	if (map.current.getLayer('route')) {
		map.current.removeLayer('route');		
	}
	if (map.current.getSource('route')) {
		map.current.removeSource('route');	
	}
	map.current.addSource('route',
		{
			'type': 'geojson',

			'data': {
				'type': 'Feature',
				'properties': {},
				'geometry': state.geoJSON
			}
		}
	);
	map.current.addLayer({
		id: 'route',
		type: 'line',
		source: 'route',
		layout: {
			"line-join": 'round',
			"line-cap": 'round'
		},
		paint: {
			"line-color": '#000000',
			"line-width":strokeWidth,
		}
	});
}


useEffect(() => {
	if (map.current){
		
		map.current.on('idle', getImageFromMap)
		return () => {
			map.current.off('idle', getImageFromMap)
		}
	}
},[])






function getImageFromMap(){

	if (map.current){
		
		
		// clearTimeout(timer.current)
			// state.updateMapBuilder('mapImage', null)
			// timer.current = setTimeout(() => {
				const canvas = map.current.getCanvas()
				const i = canvas.toDataURL('image/png')
				state.setMapImage(i)
				// if (!state.showMap){
					// state.setShowMap(true)
				// }
			// getImageFromMap()
		// },300)

	}
}

return(
	<div ref={mapContainer}  style={{zIndex:-1, opacity:0,position:"fixed", top: 0, left:0, width: state.builderConfig.mapWidth/2, height: state.builderConfig.mapHeight/2, userSelect:'none', pointerEvents:'none'}} className="map-container" />
)
}

export default Mapbox