import * as d3 from 'd3';
import dateFormat, { masks } from "dateformat";
import svgBBox from 'svg-path-bbox'
import { getTextDimensions,} from './canvasHelper'
import svgpath from 'svgpath'

function canvasHeader(params){
    const {ctx, width, height, heights, typography, props}= params
    const { borderSize, header, title, subtitle, largeIcon, footer, swapTitleAndStats, sport_type} = props
    
    const showSubtitle = header === 'titleSubtitleAndIcon' || header ==='titleAndSubtitle' ? true : false;
    const showActivityIcon = header === 'titleSubtitleAndIcon' || header === 'titleAndIcon' ? true : false;
    const fullWidthTitle = header === 'fullWidthTitle' ? true : false;
    
    function addTitle(){
        
        ctx.font = typography.title
        ctx.fillStyle = "black";
        const titleHeight = heights.title
        let titleYPos = 
            swapTitleAndStats ?
                height - borderSize - heights.title.actualHeight:
                borderSize
        
        ctx.fillText(title, borderSize, titleYPos);
        if (showSubtitle){
            ctx.font = typography.subtitle
            const subtitleHeight = getTextDimensions(ctx, subtitle).height
            let subtitleYPos =
               swapTitleAndStats ?
                    height - borderSize - heights.title.actualHeight - heights.subtitle.actualHeight - heights.typographySpacing:
                    borderSize + heights.title.actualHeight + heights.typographySpacing
            ctx.fillText(subtitle, borderSize, subtitleYPos);
        }
    }


    
    
    // ctx, sport_type, width, height, heights, largeIcon, borderSize
    function addActivityIcon(){
        const base_image = new Image();
        base_image.src = 
            sport_type === 'Run' ? require('../assets/runBlack.png')
            : sport_type === 'Ride' ? require('../assets/bikeBlack.png')
            : sport_type === 'Swim' ? require('../assets/swimBlack.png')
            : sport_type === 'Walk' ? require('../assets/walkBlack.png')
            : require('../assets/walkBlack.png')
        base_image.onload = function(){
            // const iconHeight = largeIcon ? swapTitleAndStats ?  heights.headerLockup : heights.headerLockup : heights.title.actualHeight
            const iconHeight = heights.title.actualHeight
            const divisor = iconHeight / base_image.height
            const iconWidth = base_image.width * divisor
            const iconX = width - borderSize - iconWidth
            const iconY = 
                swapTitleAndStats ? 
                height - borderSize - iconHeight :
                
                borderSize 
            ctx.drawImage(base_image, iconX ,iconY, iconWidth,  iconHeight);
        };  
    }
  
    
    
    addTitle()
    if (showActivityIcon){
        addActivityIcon()
    }
}

export default canvasHeader
