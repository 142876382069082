import * as polyline from "@mapbox/polyline";
import { useEffect, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useRef } from "react";
import dateFormat, { masks } from "dateformat";
import { Row, Col } from "react-bootstrap";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
  Card,
} from "@chakra-ui/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

// const Title = styled.h5`
//   margin-bottom: 4px !important;
//   color: #000;
// `;
// const SubTitle = styled.p`
//   color: #000;
// `;
// const RenderCanvas = styled.canvas`
//   opacity: 0.5;
// `;

// const ActivityRow = styled.div`
//   height: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   flex: 1;
// `;

// const ActivityIcon = styled.img`
//   opacity: 0.5;

//   height: 32px;
//   width: 32px;
// `;

// const ActivityContainer = styled.div`
//   border-bottom: 1px solid #000020;
//   cursor: pointer;
//   padding: 24px 0;
// `;

function AbandonedCart({
  name,
  id,
  distance,
  start_date_local,
  elapsed_time,
  sport_type,
  moving_time,
  map,
  average_speed,
  onClick,
  isContinue,
}) {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [canvasSize] = useState(40);
  const [isAnimatedIn, setIsAnimatedIn] = useState(false);
  const [geoJSON, setGeoJSON] = useState(null);

  function handleClick() {
    if (onClick) {
      onClick();
    } else {
      navigate(`/activity?id=${id}`);
    }
  }

  useEffect(() => {
    // processActivity();
    // setupCanvas();
    setIsAnimatedIn(true);
  }, []);

  //   useEffect(() => {
  //     if (ctx && geoJSON) {
  //       setMap();
  //     }
  //   }, [ctx, geoJSON]);

  //   function processActivity(res) {
  //     let coordinates = polyline.decode(map.summary_polyline);
  //     setGeoJSON({
  //       type: "LineString",
  //       coordinates: coordinates,
  //     });
  //   }

  //   function setupCanvas() {
  //     const canvas = canvasRef.current;
  //     const context = canvas.getContext("2d");
  //     setCtx(context);

  //   }

  //   function setMap() {
  //     var projection = d3
  //       .geoMercator()
  //       .rotate([0, -90])
  //       .reflectX(true)
  //       .fitSize([canvasSize, canvasSize], geoJSON);
  //     var path = d3.geoPath().projection(projection);
  //     const svgPath = path(geoJSON);
  //     // var transformed = svgpath(svgPath)
  //     //             .translate(padding,padding*4)
  //     //             .rel()
  //     //             .scale(mapScale)
  //     //             .round(1)
  //     //             .toString();
  //     var canvasPath = new Path2D(svgPath);
  //     ctx.strokeStyle = "#000";
  //     ctx.lineWidth = 2;
  //     var p = new Path2D(canvasPath);
  //     ctx.stroke(p);
  //     setIsAnimatedIn(true)
  //   }

  return (
    <Card
      style={{ pointer: "cursor" }}
      direction={["column", "row"]}
      transition="all 0.5s ease"
      transform={`translateY(${isAnimatedIn ? "0" : "-100%"})`}
      overflow="hidden"
      alignItems="center"
      mh="72px"
      p={0}
      borderRadius="lg"
      bg="white"
      // onClick={handleClick}
    >
      <CardHeader m={[0]} pb={[0, 4]} pt={3}>
        {/* <canvas ref={canvasRef} width={canvasSize} height={canvasSize} /> */}
        <Text
          fontWeight="bold"
          fontSize={["lg", "xl"]}
          align={["center", "left"]}
          color="black"
        >
          Pick up where you left off
        </Text>
        <Text fontSize="md" align={["center", "left"]} color="gray.500" m={0}>
          Finish ordering {name}
        </Text>
      </CardHeader>
      <CardBody
        display="flex"
        flexDirection="row"
        alignItems="center"
        p={[0, 4]}
      >
        {/* <Box flex="1">
        <Text style={{fontWeight:'bold'}} >
        Pick up where you left off,
          </Text>
          <Text fontSize="sm" color="gray.600" m={0}>continue designing your {name}</Text>
            
        </Box> */}
      </CardBody>
      <CardFooter m={[0, 4]} pr={[4]}>
        <Button
          to={`/activity?id=${id}`}
          as={RouterLink}
          variant="solid"
          colorScheme="orange"
          size="md"
          color="white"
          rightIcon={<ArrowRightIcon width={16} color="white" />}
        >
          {isContinue ? "Finish artwork" : "Select"}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default AbandonedCart;
