import { Button, ButtonGroup, Flex, IconButton, Box } from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { ARTWORK_CAROUSEL_WIDTH } from "./ArtworkConfigurationCarousel";
import { CREATE_ARTWORK_STEPPER_HEIGHT } from "../Components/CreateArtworkStepper";
import Context from '../context'
import { useContext } from 'react'
function SwiperControls({
  builderIndex,
  setBuilderIndex,
  controls,
  isLoading,
  setValidation,
  onBuildComplete,
  CTA,
  control,
  limit,
}) {
  const controlLength = limit - 1;
  const state = useContext(Context)

  // const disableContinue = control?.validate
  //   ? false
  //   : control?.value === null || control?.value === undefined
  //   ? true
  //   : false;

  const disableContinue = false;

  function validate() {
    if (control.validate) {
      let isValid = control.validate(control.value);
      if (isValid) {
        nextPlease();
        setValidation(false);
      } else {
        setValidation("Please enter a valid email address");
      }
    } else {
      // console.log("noit val");
      nextPlease();
    }
  }

  function nextPlease() {
    if (builderIndex < controlLength) return setBuilderIndex(builderIndex + 1);
    if (builderIndex === controlLength) {
      return onBuildComplete();
    }
  }

  return (
    <Box
      position={["fixed", "relative"]}
      left={0}
      right={0}
      bottom={0}
      height={[CREATE_ARTWORK_STEPPER_HEIGHT, "auto"]}
    >
     
      <Flex width={ARTWORK_CAROUSEL_WIDTH} mx="auto" bg="white" p={2}>
        <ButtonGroup spacing={2} w="full">
          {builderIndex > 0 && (
            <IconButton
              isLoading={isLoading}
              onClick={() => {
                if (builderIndex > 0) setBuilderIndex(builderIndex - 1);
              }}
              variant="outlineDark"
              icon={<ArrowBackIcon />}
            >
              Back
            </IconButton>
          )}
          <Button
            isDisabled={builderIndex === controls?.filter((c) => c.display).length-1 && !state.shippingRates?.options?.length > 0}
            isLoading={isLoading}
            onClick={() => {
              validate();
            }}
            variant="solid"
            colorScheme="orange"
            w="full"
            rightIcon={<ArrowForwardIcon />}
          >
            {CTA || "Next"}
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
}

export default SwiperControls;
