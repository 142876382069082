import {
  Box,
  Container,
  Button,
  CardHeader,
  Card,
  Avatar,
  CardBody,
  Heading,
  CardFooter,
  Text,
  Flex,
  Badge,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import userContext from "../userContext";
import Context from "../context";
import { postData } from "../Helpers/http";
import AppFrame from "../Components/AppFrame";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import Orders from "../Components/Orders";

function Profile() {
  const navigate = useNavigate();
  const userState = useContext(userContext);
  const state = useContext(Context);
  const user = userState.user;

  //@todo: turn into 2 columns

  if (user) {
    return (
      <AppFrame>
        <Container maxW="container.lg" py="16">
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, md: 4 }}>
              <Card bg="black" border="1px solid" borderColor="gray.900">
                <CardBody>
                  <Box display="flex" justifyContent="space-between">
                    <Avatar
                      size="lg"
                      mb={4}
                      name={user.firstname}
                      src={user.profile}
                    />
                    <Box flex="0">
                      <Badge fontWeight="regular" colorScheme="green">
                        Strava Connected
                      </Badge>
                    </Box>
                  </Box>
                  <Heading size="md" mb={1}>
                    {user.firstname} {user.lastname}
                  </Heading>
                  <Heading
                    size="sm"
                    fontWeight="regular"
                    color="gray.500"
                    mb={1}
                  >
                    {user.city} · {user.country}
                  </Heading>
                  {user.bio && <Text>{user.bio}</Text>}
                </CardBody>
                <CardFooter borderTop="1px solid" borderColor="gray.900">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      postData(`${state.path}/logout`)
                        .then(() => {
                          userState.setUser(null);
                          navigate("/");
                        })
                        .catch((err) => console.log(err));
                    }}
                  >
                    Log out
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 8 }}>
              <Orders getUrl={state.path} user={userState.user} />
            </GridItem>
          </Grid>
        </Container>
      </AppFrame>
    );
  } else {
    return <div />;
  }
}

export default Profile;
