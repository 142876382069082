import { extendTheme } from "@chakra-ui/react";
import Button from "./buttonTheme";
import colors from "./colors";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const styles = {
  global: {
    html: {
      color: "white",
      fontSize: "15px",
      fontWeight: "500",
    },
    body: {
      bg: "black",
      color: "white",
    },
  },
};

const fonts = {
  body: "Lato, sans-serif",
  heading: "Kanit, sans-serif",
  mono: "Menlo, monospace",
};

const overrides = {
  styles,
  fonts,
  colors,
  components: {
    Button,
  },
  config,
};

const theme = extendTheme(overrides);

export default theme;
