import { Box } from "@chakra-ui/react";
import React from "react";

const StyledFrame = ({ children, display, frameColor = "black", ...props }) => {
  return (
    <Box
      opacity={display ? 1 : 0}
      border="10px solid"
      borderColor={frameColor}
      p={1}
      bg="gray.300"
      borderRadius="1px"
      boxShadow="xl"
      {...props}
    >
      {children}
    </Box>
  );
};

export default StyledFrame;
