import { useState } from "react";
import OffscreenCanvas from "./OffscreenCanvas";
import getColorFilters from "../Helpers/computeColorFilters";
import { Box, Spinner, Text, Flex, Image } from "@chakra-ui/react";
import StyledFrame from "../Components/StyledFrame";

// CSS Foil Colours
const silverFoil = `linear-gradient(45deg, rgb(153, 153, 153) 5%, rgb(255, 255, 255) 10%, rgb(143 143 143) 30%, rgb(255 252 252) 50%, rgb(204, 204, 204) 70%, rgb(255, 255, 255) 80%, rgb(173 173 173) 95%)`;
const goldFoil = `radial-gradient(at right bottom, rgb(254, 219, 55) 0%, rgb(209 182 128) 8%, rgb(159, 121, 40) 30%, rgb(183, 146, 64) 40%, transparent 80%), radial-gradient(at left top, rgb(255, 255, 255) 0%, rgb(206, 169, 91) 8%, rgb(218, 182, 82) 25%, rgb(169 137 65) 62.5%, rgb(93, 74, 31) 100%)`;

function FullColorPreview(props) {
  const { width, height, imageData } = props;

  const [testImage, setTestImage] = useState(null);

  function createColourPreview(image) {
    URL.revokeObjectURL(testImage);
    setTestImage(image);
  }

  return (
    <>
      <OffscreenCanvas
        imageData={imageData}
        width={width}
        sendPreview={createColourPreview}
        height={height}
        color={props.color}
      />
      {!testImage && (
        <Flex direction="column" align="center" justify="center">
          <Spinner color="black"></Spinner>
          <Text color="black" mt="4">
            Loading your full colour preview
          </Text>
        </Flex>
      )}
      {testImage && (
        <StyledFrame display>
          <Box
            style={{
              zIndex: 1,
              background:
                props.color?.foreground === "white"
                  ? `url(${require("../assets/white.png")})`
                  : props.color?.foreground === "gold"
                  ? goldFoil
                  : props.color?.foreground === "silver"
                  ? silverFoil
                  : props.color?.foreground === "black"
                  ? `url(${require("../assets/black.png")})`
                  : `url(${require("../assets/white.png")})`,
            }}
          >
            {testImage && (
              <Image
                style={{
                  filter: props.color?.background
                    ? getColorFilters(props.color.background)
                    : null,
                  maxHeight: "calc(-200px + 100vh)",
                }}
                key={testImage}
                src={testImage}
              />
            )}
          </Box>
        </StyledFrame>
      )}
    </>
  );
}

export default FullColorPreview;
