import { useContext, useEffect, useState } from "react";
import Context from "../context";
import { getData } from "../Helpers/http";
import { Box, Link, Heading, Text } from "@chakra-ui/react";
import Order from "./Order";
import { TailSpin } from "react-loader-spinner";

function Orders({ user, getUrl }) {
  const [isLoading, setIsLoading] = useState();
  const state = useContext(Context);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getData(`${state.path}/orders`)
      .then((data) => {
        setIsLoading(false);
        setOrders(data.reverse());
      })
      .catch((err) => {
        console.log("Failed fetching orders", err);
      });
  }, []);

  if (isLoading) {
    return (
      <Box h="300px" display="flex" alignItems="center" justifyContent="center">
        <TailSpin
          height="45"
          width="45"
          color="#212121"
          ariaLabel="tail-spin-loading"
          radius="2"
          visible={true}
        />
      </Box>
    );
  }

  return (
    <>
      <Heading size="md" my={2}>
        Your orders
      </Heading>
      <Text color="gray.300" mb={4}>
        If you have any questions about your existing or past orders, please
        contact us at{" "}
        <Link href="mailto:info@kudosmaps.com" textDecoration="underline">
          info@kudosmaps.com
        </Link>
        .
      </Text>
      {orders &&
        orders.map((item, i) => {
          if (item.paid) {
            return <Order getUrl={getUrl} user={user} key={i} {...item} />;
          }
        })}
    </>
  );
}

export default Orders;
