import dateFormat from "dateformat";
import { getData } from "../Helpers/http";
import { Box, CardBody, Text, Card } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function Order({
  name,
  id,
  expected_delivery,
  getUrl,
  configuration,
  shipping_details,
  user,
  status,
  id: orderId,
}) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (user && user.id) {
      const query = `?user_id=${user.user_id}&order_id=${id}&image=thumbnail`;
      getData(`${getUrl}/image${query}`).then((data) => {
        setImage(data.url);
      });
    }
  }, []);

  function friendlyStatus() {
    switch (status) {
      case "submitted-to-printer":
        return "Submitted to printer";
      case "paid":
        return "Paid";
      default:
        return "";
    }
  }

  return (
    <Card bg="black" border="1px" mb={2} borderColor="gray.900">
      <CardBody
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        gap="4"
        borderBottom="1px solid"
        borderColor="gray.900"
        p={4}
      >
        <Box maxW="100px" flex="1">
          <Box borderRadius="md" overflow="hidden">
            {image && <img src={image} alt="product" />}
          </Box>
        </Box>
        <Box flex="2">
          <Box mb={2}>
            <Text m={0} fontSize="sm" color="gray.500">
              Order code
            </Text>
            <Text>{orderId}</Text>
          </Box>
          <Text m={0} fontSize="sm" color="gray.500">
            Product
          </Text>
          <Text mb={0}>{configuration.product.name}</Text>
          {configuration.frame && (
            <Text mb={0}>{configuration.frame.name}</Text>
          )}
        </Box>
        {shipping_details && (
          <Box flex="2">
            <Box mb={4}>
              <Text m={0} fontSize="sm" color="gray.500">
                Shipping address
              </Text>
              <Text m={0}>{shipping_details ? shipping_details.name : ""}</Text>
              <Text m={0}>{shipping_details.address.line1}</Text>
              {shipping_details.address.line2 && (
                <Text m={0}>{shipping_details.address.line2}</Text>
              )}
              {shipping_details.address.city && (
                <Text m={0}>{shipping_details.address.city}</Text>
              )}
              <Text m={0}>{shipping_details.address.postal_code}</Text>
            </Box>
            <Box>
              <Text m={0} fontSize="sm" color="gray.500">
                Estimated delivery date
              </Text>
              <Text m={0}>{dateFormat(expected_delivery, "mmmm dS")}</Text>
            </Box>
          </Box>
        )}
      </CardBody>
    </Card>
  );
}

export default Order;
