import React from "react";

import {
  Box,
  Card,
  CardBody,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactComponent as StarIcon } from "../assets/StarIcon.svg";

const TestimonialCard = () => {
  return (
    <Card bg="black" border="2px solid" borderColor="#484848" borderRadius="xl">
      <CardBody>
        <HStack mb={4}>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </HStack>
        <Text fontSize="xl" mb={2}>
          Omg this artwork is amazing my friends love it and my life is changed
          forever because of it
        </Text>
        <Box>
          <Text color="gray.500">Barry from Bromley</Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default TestimonialCard;
