import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  TokenExchange,
  Activities,
  ActivityDetail,
  ActivityPresets,
  Redirect,
  CheckLoginStatus,
  Profile,
  Checkout,
} from "./Routes";
import { Mapbox, CookieBanner } from "./Components";
import AdminHome from "./Routes/Admin/AdminHome";
import Provider from "./provider";
import UserProvider from "./userProvider";

import { ChakraProvider, ColorModeProvider, DarkMode } from "@chakra-ui/react";
import theme from "./theme";
import FAQ from "./Routes/FAQ";
import CookiePolicy from "./Routes/CookiePolicy";
import PrivacyPolicy from "./Routes/PrivacyPolicy";
import Vouchers from "./Routes/Vouchers";
import AnalyticsProvider from "./AnalyticsProvider";
import ActivityLoader from "./Routes/ActivityLoader";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <DarkMode>
          <BrowserRouter>
            <AnalyticsProvider>
              <Provider>
                <UserProvider>
                  <CheckLoginStatus />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/exchange_token" element={<TokenExchange />} />
                    <Route
                      path="/activity_presets"
                      element={<ActivityPresets />}
                    />
                    <Route path="/redirect" element={<Redirect />} />
                    <Route path="/activities" element={<Activities />} />
                    <Route path="/activity" element={<ActivityDetail />} />
                    <Route path="/load-activity" element={<ActivityLoader />} />
                    <Route
                      path="/activity/presets"
                      element={<ActivityPresets />}
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/cookie_policy" element={<CookiePolicy />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/vouchers" element={<Vouchers />} />
                    <Route path="/admin/*" element={<AdminHome />} />
                  </Routes>
                  <Mapbox />
                  <CookieBanner />
                </UserProvider>
              </Provider>
            </AnalyticsProvider>
          </BrowserRouter>
        </DarkMode>
      </ColorModeProvider>
    </ChakraProvider>
  );
}

export default App;
