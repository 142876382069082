import { Box, Container, Button, Avatar, ButtonGroup } from "@chakra-ui/react";
import React, { useContext } from "react";

import { useNavigate, Link as LinkRouter } from "react-router-dom";
import UserContext from "../userContext.js";

import { ReactComponent as Logo } from "../assets/kudosmaps-logo.svg";

const NavBar = () => {
  const navigate = useNavigate();
  const userState = useContext(UserContext);
  return (
    <Container
      display="flex"
      flexDirection={{ base: "column", md: "row" }}
      alignItems="center"
      px={8}
      maxW="container.xl"
      pt={8}
    >
      <Box flex="1" py={2}>
        <LinkRouter to="/">
          <Logo />
        </LinkRouter>
      </Box>
      <ButtonGroup spacing={3}>
        {/* <Button as={LinkRouter} to="/vouchers" variant="ghost">
          Gift Voucher
        </Button> */}
        <Button as={LinkRouter} to="/faq" variant="ghost">
          FAQ
        </Button>
        {userState.user ? (
          <Button
            onClick={() => navigate("/profile")}
            variant="ghost"
            aria-label="Profile"
            leftIcon={
              userState.user && (
                <Avatar
                  size="xs"
                  name={userState?.user?.firstname}
                  src={userState?.user?.profile}
                />
              )
            }
          >
            {userState.user.firstname}
          </Button>
        ) : (
          <Button onClick={() => navigate("/activities")} variant="ghost">
            Create artwork
          </Button>
        )}
      </ButtonGroup>
    </Container>
  );
};

export default NavBar;
