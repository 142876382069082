import {
  Box,
  Flex
} from "@chakra-ui/react";

function BuilderPagination({steps, selected}){

  return(
    <Flex
      userSelect="none"
      py="4"
      zIndex="10"
      background="linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,255))"
      direction="row" 
      justify="center"
      
    >
      {steps && steps.map((item, i) => {
        return(
          <Box 
          key={i}
            width="4"
            borderRadius="2"
            
            height="1"
            marginRight="2"
            background={selected === i ? 'black' : 'gray.300'}
          />
          
        )
      })}
    </Flex>
  
  )
}

export default BuilderPagination