import { useEffect, useState } from "react";
import { Stack, Input, HStack } from "@chakra-ui/react";

function TextInputs({ inputs, onChange, color }) {
  const [values, setvalues] = useState();

  useEffect(() => {
    let newValues = {};
    for (var i = 0; i < inputs.length; i++) {
      const item = inputs[i];
      newValues[item.key] = item.value;
    }
    setvalues(newValues);
  }, []);

  function onUpdateField(index, key, value) {
    let newvalues = Object.assign({}, values);
    newvalues[key] = value;
    setvalues(newvalues);
    onChange(newvalues);
  }

  return (
    inputs &&
    values &&
    inputs.map((item, i) => {
      if ((item.value || item.value === "") && item.display) {
        return (
          <Input
            key={i}
            label={item.label}
            placeholder={item.label}
            value={values[item.key]}
            onChange={(e) => onUpdateField(i, item.key, e.target.value)}
            variant="outline"
            borderColor="gray.100"
            px={2}
            _placeholder={{ color: "gray.500" }}
            _hover={{ borderColor: "gray.500" }}
            color={color ? color : "black"}
          />
        );
      } else return <div key={i} />;
    })
  );
}

export default TextInputs;
