import React from "react";
import AppFrame from "../Components/AppFrame";
import { Container, Box, Heading, Link, Text } from "@chakra-ui/react";

const CookiePolicy = () => {
  return (
    <AppFrame>
      <Container minH="90vh" maxW="container.md" py={12}>
        <Heading size="xl" fontStyle="italic" textAlign="center" mb={8}>
          Cookie policy
        </Heading>
        <Heading
          size="md"
          fontFamily="body"
          fontWeight="500"
          textAlign="center"
          mb={8}
        >
          Because even the cookie monster has rules
        </Heading>
        <Text mb={4}>Effective Date: 18th June 2023</Text>
        <Text fontSize="lg">
          This Cookie Policy ("Policy") describes how Kudos Maps ("we," "us," or
          "our") uses cookies and similar technologies on our website
          www.kudosmaps.com ("Website"). This Policy should be read in
          conjunction with our Privacy Policy.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          What are cookies?
        </Heading>
        <Text>
          Cookies are small text files that are placed on your device (computer,
          smartphone, tablet) when you visit our Website. These files contain
          information that allows us to recognize and remember you during your
          visit and subsequent visits to our Website.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          Why do we use cookies?
        </Heading>
        <Text>
          We use cookies and similar technologies to enhance your experience on
          our Website, personalize content and advertisements, analyze website
          traffic, and improve our services. By using cookies, we can remember
          your preferences, save your shopping cart information, and provide you
          with a seamless and customized browsing experience.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          Types of cookies we use
        </Heading>
        <Text>
          Necessary cookies: These cookies are essential for the operation of
          our Website and enable you to navigate our Website and use its
          features. Without these cookies, certain services you have requested
          may not be available.
        </Text>
        <Text>
          Analytical/performance cookies: These cookies allow us to collect
          information about how visitors use our Website, such as the number of
          visitors, the pages visited, and the sources of traffic. We use this
          information to improve the performance and functionality of our
          Website.
        </Text>
        <Text>
          Functionality cookies: These cookies enhance the functionality of our
          Website and remember your preferences (e.g., language selection,
          region) to provide you with a more personalized experience. They can
          also be used to provide you with requested services, such as live chat
          support.
        </Text>
        <Text>
          Advertising/targeting cookies: We work with third-party advertising
          networks and service providers that may use cookies to deliver
          advertisements relevant to your interests. These cookies collect
          information about your browsing habits and enable us to display
          targeted advertisements on our Website or other websites you visit.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          Managing cookies
        </Heading>
        <Text>
          You can manage and control cookies through your browser settings. You
          can choose to accept or decline cookies. Please note that if you
          disable or reject cookies, certain features of our Website may not
          function properly, and your browsing experience may be affected.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          Changes to this Policy
        </Heading>
        <Text>
          We reserve the right to update or modify this Cookie Policy at any
          time. Any changes we make will be effective immediately upon posting
          the revised Policy on our Website. We encourage you to review this
          Policy periodically to stay informed about our use of cookies.
        </Text>
        <Heading fontFamily="body" size="md" mt={4} mb={2}>
          Contact us
        </Heading>
        <Text>
          If you have any questions or concerns about this Cookie Policy, please
          contact us at info@kudosmaps.com.
        </Text>
        <Text mb={4}>
          By continuing to use our Website, you consent to the use of cookies
          and similar technologies as described in this Policy.
        </Text>
        <Text>Last updated: 18th June 2023</Text>
      </Container>
    </AppFrame>
  );
};

export default CookiePolicy;
