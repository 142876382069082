// import Kol from '../assets/fonts/Kollektif-Bold.ttf'
// import Nor from '../assets/fonts/norwester.ttf'
// import Mon from '../assets/fonts/Montserrat-VariableFont_wght.ttf'

function loadFontFaces(){
    return new Promise((resolve, reject) => {
    //     const Kollektif = new FontFace('Kollektifs', Kol);
    //     const Montserrat = new FontFace('Montserrat', Mon);


        new FontFace('Norwester', `url(${require('../assets/fonts/norwester.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('Kollektif', `url(${require('../assets/fonts/Kollektif.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('CooperHewittBold', `url(${require('../assets/fonts/CooperHewitt-Bold.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('CooperHewittLight', `url(${require('../assets/fonts/CooperHewitt-Light.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('MomcakeBold', `url(${require('../assets/fonts/MomcakeBold.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('MomcakeThin', `url(${require('../assets/fonts/MomcakeThin.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('UniSansThin', `url(${require('../assets/fonts/UniSansThin.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('UniSansHeavy', `url(${require('../assets/fonts/UniSansHeavy.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('ModerneSans', `url(${require('../assets/fonts/MODERNESANS.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
        new FontFace('Montserrat', `url(${require('../assets/fonts/Montserrat.ttf')})`).load().then((font) => {
            document.fonts.add(font);
        })
            
            
          
        // document.fonts.add(Norwester);
        // Norwester.load()
    //     Kollektif.load()

    //     Promise.all([Norwester, Montserrat, Kollektif]).then((fontFaces) => {
    //         for(var i = 0; i < fontFaces.length; i++){
    //             // console.log(fontFaces[i])
    //             document.fonts.add(fontFaces[i]);
    //         }


    //      resolve(fontFaces)
    //     })
    })

    // const font = new FontFace("myfont", "url(myfont.woff)", {
    //     style: "italic",
    //     weight: "400",
    //   });

}


// export async function loadFont(fontFamily: string, url: string): Promise<void> {
//     const font = new FontFace(fontFamily, `local(${fontFamily}), url(${url})`);
//     // wait for font to be loaded
//     await font.load();
//     // add font to document
//     document.fonts.add(font);
//     // enable font with CSS class
//     document.body.classList.add("fonts-loaded");
// }




export default loadFontFaces



