import { Wrap, WrapItem, Button } from "@chakra-ui/react";
import SwiperRadioButton from "./SwiperRadioButton";

function RadioImageControl({
  options,
  value,
  supportiveText,
  sendFullObject,
  onChange,
  button,
}) {
  
  return (
    <>
      {options &&
        options.map((item, i) => {
          if (!item.hide) {
            return (
              <SwiperRadioButton
                onChange={() => onChange(sendFullObject ? item : item.key)}
                key={i}
                {...item}
                item={item}
                isSelected={value === item.key}
              />
            );
          } else return <div key={i} />;
        })}

      {button && button.display && (
        <Wrap spacing="16px" justify="center" style={{ marginTop: "16px" }}>
          <WrapItem>
            <Button variant="outline" onClick={() => button.onClick()}>
              {button.label}
            </Button>
          </WrapItem>
        </Wrap>
      )}
    </>
  );
}

export default RadioImageControl;
