const GoldBlackIcon = require("../assets/colourCombinationIcons/goldfg-blackbg.png");
const SilverBlackIcon = require("../assets/colourCombinationIcons/silverfg-blackbg.png");

const GoldDarkNavy = require("../assets/colourCombinationIcons/goldfg-darknavybg.png");
const SilverDarkNavy = require("../assets/colourCombinationIcons/silverfg-darknavybg.png");

const GoldEggshell = require("../assets/colourCombinationIcons/goldfg-eggshellbg.png");

const colorOptions = [
  {
    key: "gold-eggshell",
    label: "Gold & Eggshell",
    subLabel: "Matt eggshell finish with gold foil",
    foreground: "gold",
    background: "rgb(249,244,235)",
    icon: GoldEggshell,
  },
  {
    key: "gold-black",
    label: "Gold & Black",
    subLabel: "Matt black finish with gold foil",
    foreground: "gold",
    background: "rgb(0,0,0)",
    icon: GoldBlackIcon,
  },
  {
    key: "silver-black",
    label: "Silver & Black",
    subLabel: "Matt black finish with silver foil",
    foreground: "silver",
    background: "rgb(0,0,0)",
    icon: SilverBlackIcon,
  },
  {
    key: "gold-darknavy",
    label: "Gold & Dark Navy",
    subLabel: "Matt blue finish with gold foil",
    foreground: "gold",
    background: "rgb(0,30,74)",
    icon: GoldDarkNavy,
  },
  {
    key: "silver-darknavy",
    label: "Silver & Dark Navy",
    subLabel: "Matt blue finish with silver foil",
    foreground: "silver",
    background: "rgb(0,30,74)",
    icon: SilverDarkNavy,
  },
];

export default colorOptions;

// {
//   group: "foreground",
//   label: "Silver metalic",
//   subLabel: "Premium metalic finish",
//   key: "silver",
//   buttonStyle: {
//     bgGradient: "linear(to-r, #E4E4E4, #B4B4B4)",
//     color: "black",
//   },
// },
// {
//   group: "foreground",
//   label: "Black",
//   subLabel: "Bold graphic finish",
//   key: "black",
//   buttonStyle: {
//     bgGradient: "linear(to-r, #212121, #000000)",
//     color: "white",
//   },
// },
// {
//   group: "foreground",
//   label: "White",
//   subLabel: "Bold graphic finish",
//   key: "white",
//   buttonStyle: {
//     bgGradient: "linear(to-r, #ffffff,  #efefef)",
//     color: "black",
//   },
// },
// {
//   group: "background",
//   label: "Black",
//   key: "rgb(0,0,0)",
//   buttonStyle: { bg: "rgb(0,0,0)", color: "white" },
//   foregroundCompatibility: ["gold", "silver", "white"],
// },
// {
//   group: "background",
//   label: "Deep Navy",
//   key: "rgb(0,30,74)",
//   buttonStyle: { bg: "rgb(0,30,74)", color: "white" },
//   foregroundCompatibility: ["gold", "silver", "white"],
// },
// {
//   group: "background",
//   label: "Forest Green",
//   key: "rgb(5,52,18)",
//   buttonStyle: { bg: "rgb(5,52,18)", color: "white" },
//   foregroundCompatibility: ["gold", "silver", "white"],
// },
// {
//   group: "background",
//   label: "Eggshell",
//   key: "rgb(249,244,235)",
//   buttonStyle: { bg: "rgb(249,244,235)", color: "black" },
//   foregroundCompatibility: ["gold", "black"],
// },
// {
//   group: "background",
//   label: "White",
//   key: "rgb(255,255,255)",
//   buttonStyle: { bg: "rgb(255,255,255)", color: "black" },
//   foregroundCompatibility: ["black"],
// },
