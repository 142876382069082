
import {  Box, Flex, Button, Avatar, ButtonGroup, Heading, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { useNavigate,  Link as LinkRouter } from "react-router-dom";
import UserContext from "../userContext.js";

import { ReactComponent as Logo } from "../assets/kudosmaps-logo.svg";


const NavBar = () => {
  const navigate = useNavigate();
  const userState = useContext(UserContext);
  return (
    <Flex bg="black" alignItems="center" px={8} width="full" pt={8}>
      <Box flex="1" py={2} onClick={() => navigate('/admin')}>

        <Logo />
       
      </Box>
      <ButtonGroup>
        <Button as={LinkRouter} to="/admin/prebuiltroutes" variant="ghost">
          Manage Routes
        </Button>
        <Button
          onClick={() => navigate("/admin/users")}
          variant="ghost"
          aria-label="Users"
          leftIcon={
            userState.user && (
              <Avatar size="xs" name={"Users"} />
            )
          }
        >
          {"Users"}
        </Button>
        </ButtonGroup>
      
    </Flex>
  );
};

export default NavBar;
