import React, { useContext, useEffect, useState } from "react";
import dateFormat from "dateformat";
import { getData } from "../Helpers/http";
import Context from "../context";
import { Box, Card, CardBody, HStack, Text } from "@chakra-ui/react";
import { TailSpin } from "react-loader-spinner";
import get from "lodash/get";

const OrderConfirmation = ({ orderId }) => {
  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState();
  const state = useContext(Context);

  useEffect(() => {
    if (!order) {
      getData(`${state.path}/order/?order_id=${orderId}`)
        .then((data) => {
          setIsLoading(false);
          if (data && data.length) {
            setOrder(data[0]);
          }
        })
        .catch((err) => {
          console.log("Failed fetching order", err);
        });
    }
  }, []);

  if (isLoading) {
    return (
      <Box h="300px" display="flex" alignItems="center" justifyContent="center">
        <TailSpin
          height="45"
          width="45"
          color="#212121"
          ariaLabel="tail-spin-loading"
          radius="2"
          visible={true}
        />
      </Box>
    );
  }

  if (!order || isLoading) {
    return (
      <Box h="300px" display="flex" alignItems="center" justifyContent="center">
        <TailSpin
          height="45"
          width="45"
          color="#212121"
          ariaLabel="tail-spin-loading"
          radius="2"
          visible={true}
        />
      </Box>
    );
  }

  function renderCost(unitAmountDecimal) {
    return unitAmountDecimal ? `£${unitAmountDecimal / 100}` : "";
  }

  return (
    <Card
      bg="black"
      border="1px solid"
      borderColor="gray.900"
      textAlign="left"
      maxW="96"
      mx="auto"
    >
      <CardBody>
        <HStack py={2} borderBottom="1px solid" borderColor="gray.900">
          <Box w="150px">Order Code</Box>
          <Box>{order.id}</Box>
        </HStack>
        <HStack py={2} borderColor="gray.900">
          <Box w="150px">Product</Box>
          <Box>{get(order, "configuration.product.name", "N/A")}</Box>
        </HStack>
        {/* <HStack py={2} borderBottom="1px solid" borderColor="gray.900">
          <Box w="150px">Total cost</Box>
          <Box>
            {renderCost(
              get(order, "configuration.product.default_price.unit_amount")
            )}
          </Box>
        </HStack> */}
        {/* <HStack py={2} borderBottom="1px solid" borderColor="gray.900">
          <Box w="150px">Deliver to</Box>
          <Box>
            <Text m={0}>{get(order, "shipping_details.name", "")}</Text>
            <Text m={0}>
              {get(order, "shipping_details.address.line1", "")}
            </Text>
            {get(order, "shipping_details.address.line2", "") && (
              <Text m={0}>
                {get(order, "shipping_details.address.line2", "")}
              </Text>
            )}
            {get(order, "shipping_details.address.city", "") && (
              <Text m={0}>
                {get(order, "shipping_details.address.city", "")}
              </Text>
            )}
            <Text m={0}>
              {get(order, "shipping_details.address.postal_code", "")}
            </Text>
          </Box>
        </HStack>
        <HStack py={2} borderColor="gray.900">
          <Box w="150px">Estimated delivery</Box>
          <Box>{dateFormat(get(order, "expected_delivery"), "dS mmmm")}</Box>
        </HStack> */}
      </CardBody>
    </Card>
  );
};

export default OrderConfirmation;
