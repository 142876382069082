import styled from 'styled-components'
import { Container}  from 'react-bootstrap'
const NavContainer = styled.div`
    
    width:100%;
    height:120px;
    display:flex;
    align-items:center;
`

const ProfileContainer = styled.div`
    width:44px;
    height:44px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const ProfileImage = styled.img`
    width:44px;
    height:44px;
`
function Nav() {

    return (
        <NavContainer >
            <Container>
            <ProfileContainer>
                <ProfileImage src={require('../assets/profile.png')} />
            </ProfileContainer>
            </Container>
        </NavContainer>
  );
}

export default Nav;
