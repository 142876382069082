import { useContext, useEffect, useState } from "react";
import Context from "../../adminContext";
import { getData } from "../../Helpers/http";
import dateFormat from "dateformat";

import { get, orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
} from "@chakra-ui/react";

function Orders({ query }) {
  let state = useContext(Context);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrders();
  }, []);

  function getOrders() {
    setPage(page + 1);
    getData(`${state.path}/orders${query}`)
      .then((res) => {
        const ordered = orderBy(res, "created_at", "desc");

        state.setOrders(ordered);
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }

  return (
    <Box>
      <Container maxW="container.lg">
        <Box>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Order</Th>
                  <Th>Recipient</Th>
                  <Th>Expected delivery date</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {state.orders &&
                  state.orders.map((order) => (
                    <Tr
                      _hover={{ bg: "gray.900", cursor: "pointer" }}
                      onClick={() => navigate(`/admin/order?id=${order.id}`)}
                      key={order.id}
                    >
                      <Td>
                        <Text mb={0} color="gray.500">
                          {get(order, "id")}
                        </Text>
                        {get(order, "configuration.product.name")}
                      </Td>
                      <Td>{get(order, "shipping_details.name")}</Td>
                      <Td>
                        {dateFormat(get(order, "expected_delivery"), "mmmm dS")}
                      </Td>
                      <Td className="capitalize">{get(order, "status")}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box textAlign="center" py={8}>
          <Button
            isLoading={loading}
            w={["full", "auto"]}
            onClick={() => getOrders()}
          >
            Load more
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

function AdminTabs() {
  const [tabs] = useState([
    {
      key: "",
      value: "All",
    },
    {
      key: "?status=paid",
      value: "Paid",
    },
    {
      key: "?status=submitted-to-printer",
      value: "With printer",
    },
    {
      key: "?status=complete",
      value: "Complete",
    },
    {
      key: "?status=cancelled",
      value: "Cancelled",
    },
  ]);

  return (
    <Container maxW="container.lg">
      <Tabs isLazy>
        <TabList>
          {tabs.map((item, i) => {
            return <Tab key={i}>{item.value}</Tab>;
          })}
        </TabList>

        <TabPanels>
          {tabs.map((item, i) => {
            return (
              <TabPanel key={i}>
                <Orders query={item.key} />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Container>
  );
}

export default AdminTabs;
