import { useNavigate } from "react-router-dom";
import dateFormat, { masks } from "dateformat";
import { getData } from "../Helpers/http";
import {
  Box,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Card,
} from "@chakra-ui/react";
import { useEffect, useState } from 'react'
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { postData} from '../Helpers/http'

function AdminUser({
  
  id,
  firstname, 
  lastname,
  is_admin,
  setUsers,
  path
}) {
    
    
    
    function handleClick(id) {
        console.log(`${path}/adminuser`, id)
       postData(`${path}/adminuser`, {id})
       .then((res) => {
        if (Array.isArray(res)){
            setUsers(res);
            
          }
          
       })
       .catch((err) => console.log(err))
    }

  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      alignItems="center"
      onClick={() => handleClick(id)}
      
      mb={2}
    >
      <CardHeader>
      </CardHeader>
      <CardBody display="flex" flexDirection="row" >
        
        <Box flex="2">
        <Text m={0} fontSize="sm" color="gray.500">
            Name
          </Text>
        <Text mb={0}>{firstname} {lastname}</Text>
         
        </Box>
        
        <Button
          display={['none', 'block']}
          variant="solid"
          colorScheme="orange"
          size="sm"
          
          rightIcon={<ArrowRightIcon width={16} color="white" />}
        >
          {is_admin ? 'Revoke admin' : 'Make admin' }
        </Button>
            
        
      </CardBody>
      
    </Card>
  );
}

export default AdminUser;
