import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Provider } from "./analyticsContext";
import ReactGA from "react-ga4";

ReactGA.initialize("G-Z5K7FFBF34");

const AnalyticsProvider = (props) => {
  const location = useLocation();

  useEffect(() => {
    // console.log("Path Changed", location.pathname);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Navigated",
    });
  }, [location]);

  function triggerEvent({ category, action, label, value }) {
    // console.log("Triggering Event", { category, action, label, value });
    ReactGA.event({
      category,
      action,
      label, // optional
      value, // optional, must be a number
    });
  }

  return (
    <Provider
      value={{
        triggerEvent,
      }}
    >
      {props.children}
    </Provider>
  );
};

export default AnalyticsProvider;
