import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Context from "../context";
import { getData } from "../Helpers/http";
import { Box, SimpleGrid } from "@chakra-ui/react";
import PresetRouteCard from "./PresetRouteCard";
import { TailSpin } from "react-loader-spinner";

function PresetActivitiesList() {
  const [isLoading, setIsLoading] = useState();
  const state = useContext(Context);

  useEffect(() => {
    if (!state.preBuiltRoutes) {
      setIsLoading(true);
      getData(`${state.path}/prebuiltroutes`).then((res) => {
        state.setPreBuiltRoutes(res);
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) {
    return (
      <Transition
        appear={true}
        show={isLoading}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Box
          h="300px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TailSpin
            height="45"
            width="45"
            color="#212121"
            ariaLabel="tail-spin-loading"
            radius="2"
            visible={true}
          />
        </Box>
      </Transition>
    );
  }

  return (
    <Transition
      show={!isLoading}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <SimpleGrid spacing={{ base: 2, md: 4 }} columns={{ base: 1, md: 3 }}>
        {state.preBuiltRoutes?.map((route, i) => {
          return <PresetRouteCard key={i} route={route} />;
        })}
      </SimpleGrid>
    </Transition>
  );
}

export default PresetActivitiesList;
