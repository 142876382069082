import {
  Box,
  Container,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { Link as LinkRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/kudosmaps-logo.svg";

export const CREATE_ARTWORK_STEPPER_HEIGHT = "62px";

const steps = [
  { title: "Select" },
  { title: "Customise" },
  { title: "Review" },
];

function CreateArtworkStepper({ stepNo }) {
  const { activeStep } = useSteps({
    index: stepNo,
    count: steps.length,
  });

  return (
    <Box
      bg="black"
      top={0}
      left={0}
      right={0}
      zIndex="2"
      position="fixed"
      py={4}
    >
      <Container maxW="container.xl" px={8} display="flex" alignItems="center">
        <Box display={{ base: "none", md: "inline-block" }} mr={4}>
          <LinkRouter to="/">
            <Logo width="130px" />
          </LinkRouter>
        </Box>
        <Stepper flex="1" index={activeStep} colorScheme="orange" size="sm">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  size="sm"
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <StepTitle mb={0} fontSize={["xs", "md"]}>
                  {step.title}
                </StepTitle>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Container>
    </Box>
  );
}

export default CreateArtworkStepper;
