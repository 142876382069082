import svgpath from "svgpath";
import {
  faGauge,
  faStopwatch,
  faMountain,
  faPlay,
  faForward,
  faHeartPulse,
  faUser,
  faRoute,
} from "@fortawesome/free-solid-svg-icons";
import dateFormat, { masks } from "dateformat";
import { max } from "d3";
const metresInAMile = 1609.34;
const metresInAFoot = 0.3048;

export const mapToRange = (in_min, in_max, out_min, out_max) => {
  return ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const getTypography = (props, ctx, typography) => {
  const {
    titleTypeface,
    subtitleTypeface,
    adminMasterScale,
    adminCanvas,
    header,
    typeScale,
  } = props;
  const masterScale = adminCanvas ? adminMasterScale : props.masterScale;

  const lockupTitle = props.name || props.title;
  const defaultTitleFont = `${
    300 * typeScale * masterScale
  }px ${titleTypeface}`;
  let forceFullWidthTitle = false;
  ctx.font = defaultTitleFont;

  let title = getTextDimensions(ctx, lockupTitle);

  if (title.width > ctx.canvas.width - props.borderSize * 2) {
    forceFullWidthTitle = true;
  }

  ///add condfition for if title is too long
  const fullWidthTitle =
    (header === "fullWidthTitle" && lockupTitle.length > 5) ||
    forceFullWidthTitle
      ? true
      : false;

  let titleScale = null;
  if (fullWidthTitle) {
    titleScale = getFullWidthTitleScale(
      ctx.canvas.width - props.borderSize * 2,
      props.title || props.name,
      ctx,
      titleTypeface
    );
  }
  return {
    title: `${
      fullWidthTitle ? 300 * titleScale : 300 * typeScale * masterScale
    }px ${titleTypeface}`,
    stat: `${160 * typeScale * masterScale}px ${titleTypeface}`,
    subtitle: `${100 * typeScale * masterScale}px ${subtitleTypeface}`,
    label: `${100 * typeScale * masterScale}px ${subtitleTypeface}`,
    icon: "140px fontawesome",
  };
};

export const getTextDimensions = (ctx, text) => {
  const metrics = ctx.measureText(text);
  const height = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  const boundsTop = 0 - metrics.actualBoundingBoxAscent;
  const boundsBottom = metrics.actualBoundingBoxDescent;
  const actualHeight = boundsBottom - boundsTop;
  return {
    height,
    actualHeight,
    boundsTop,
    boundsBottom,
    width: metrics.width,
  };
};

export const calculateLockupHeights = (
  ctx,
  typography,
  masterScale,
  typeScale
) => {
  const typographySpacing = Math.floor(75 * masterScale * typeScale);
  ctx.font = typography.title;
  const title = getTextDimensions(ctx, "HELLO");
  ctx.font = typography.stat;
  const stat = getTextDimensions(ctx, "HELLO");
  ctx.font = typography.label;
  const label = getTextDimensions(ctx, "HELLO");
  ctx.font = typography.subtitle;
  const subtitle = getTextDimensions(ctx, "HELLO");
  const headerLockup =
    title.actualHeight + subtitle.actualHeight + typographySpacing;
  const statLockup = label.actualHeight + stat.actualHeight + typographySpacing;

  return {
    headerLockup,
    statLockup,
    typographySpacing,
    title: title,
    label,
    subtitle,
    stat,
  };
};

const calculateLockupWidth = (ctx, text) => {
  let longest = 0;
  for (var i = 0; i < text.length; i++) {
    ctx.font = text[i].font;
    let width = ctx.measureText(text[i].value).width;
    if (width > longest) {
      longest = width;
    }
  }
  return longest;
};

function createTimeSting(value) {
  const hours = Math.floor(value / (60 * 60));
  let minutes = Math.floor(value / 60);
  let seconds = Math.round(value % 60);
  if (minutes.toString().length === 1 && hours > 0) {
    minutes = "0" + minutes;
  }
  if (seconds.toString().length === 1) seconds = "0" + seconds;
  return hours > 0 ? hours + ":" : "" + minutes + ":" + seconds;
}

const findFastestAndSlowest = (splits) => {
  let fastest = null;
  let slowest = null;
  for (var i = 0; i < splits.length; i++) {
    let split = splits[i];
    if (split.elapsed_time < fastest || fastest === null) {
      fastest = split.elapsed_time;
    }
    if (split.elapsed_time > slowest || slowest === null) {
      slowest = split.elapsed_time;
    }
  }
  fastest = createTimeSting(fastest);
  slowest = createTimeSting(slowest);
  return { fastest, slowest };
};

export const createStats = (data, isPrebuilt) => {
  const kmPaceRange = isPrebuilt
    ? false
    : findFastestAndSlowest(data.splits_metric.slice(0, -1));
  const milePaceRange = isPrebuilt
    ? false
    : findFastestAndSlowest(data.splits_standard.slice(0, -1));

  return [
    {
      display: data.location ? true : false,
      key: "location",
      label: "Location",
      prebuiltSelected: true,
      value: data.location,
      icon: faPlay.icon[4],
    },
    {
      display: data.distance ? true : false,
      key: "distance",
      label: "Distance",
      prebuiltSelected: true,
      selected: true,
      value: Math.floor(data.distance / 100) / 10 + "km",
      imperial: (data.distance / metresInAMile).toFixed(1) + "mi",
      icon: faRoute.icon[4],
    },
    // {
    //   display: data.elev_high ? true : false,
    //   key: "elevationHight",
    //   label: "Max altitude",
    //   // selected: true,
    //   value: data.elev_high + "m",
    //   imperial: (data.elev_high / metresInAFoot).toFixed(0) + "ft",
    //   icon: faRoute.icon[4],
    // },
    // {
    //   display: data.elev_low ? true : false,
    //   key: "elevationLow",
    //   label: "Min altitude",
    //   // selected: true,
    //   value: data.elev_low + "m",
    //   imperial: (data.elev_low / metresInAFoot).toFixed(0) + "ft",
    //   icon: faRoute.icon[4],
    // },
    {
      display: isPrebuilt ? false : true,
      key: "averagePace",
      label: "Avg pace",
      selected: true,
      value: createPace(data) + "/km",
      imperial: createPace(data, true) + "/mi",
      icon: faGauge.icon[4],
    },
    {
      display: data.elapsed_time ? true : false,
      key: "totalTime",
      label: "Duration",
      selected: true,
      value: data.elapsed_time ? data.elapsed_time.toString().toHHMMSS() : null,
      icon: faStopwatch.icon[4],
    },
    {
      display: data.total_elevation_gain ? true : false,
      key: "elevation",
      label: "Elevation",
      value: Math.floor(data.total_elevation_gain) + "m",
      imperial: Math.floor(data.total_elevation_gain / metresInAFoot) + "ft",
      icon: faMountain.icon[4],
    },
    {
      display: data.average_heartrate ? true : false,
      key: "avgHeartrate",
      label: "Avg heart rate",
      value: Math.floor(data.average_heartrate) + "bpm",
      icon: faHeartPulse.icon[4],
    },
    {
      display: data.max_heartrate ? true : false,
      key: "maxHheartrate",
      label: "Max heart rate",
      value: Math.floor(data.max_heartrate) + "bpm",
      icon: faHeartPulse.icon[4],
    },
    {
      display: data.kmPaceRange ? true : false,
      key: "fastestSplit",
      label: "Fastest km",
      imperialLabel: "FASTEST MILE",
      // selected: true,
      value: kmPaceRange.fastest,
      imperial: milePaceRange.fastest,
      icon: faForward.icon[4],
    },
    {
      display: data.kmPaceRange ? true : false,
      key: "SLOWEST SPLIT",
      label: "Slowest km",
      imperialLabel: "SLOWEST MILE",
      // selected: true,
      value: kmPaceRange.slowest,
      imperial: milePaceRange.slowest,
      icon: faPlay.icon[4],
    },
    {
      display: data.kmPaceRange ? true : false,
      key: "paceRange",
      label: "Pace range",
      value: kmPaceRange.fastest + " - " + kmPaceRange.slowest + "/km",
      imperial: milePaceRange.fastest + " - " + milePaceRange.slowest + "/mi",
      icon: faPlay.icon[4],
    },

    {
      display: data.date ? true : false,
      key: "date",
      prebuiltSelected: true,
      label: "Date",
      value: dateFormat(
        data.date ? data.date : data.start_date_local,
        "d mmm 'yy"
      ).toUpperCase(),
      icon: faPlay.icon[4],
    },
  ];
};

const createPace = ({ elapsed_time, distance }, imperial) => {
  const divisor = imperial ? metresInAMile : 1000;
  const totalseconds = elapsed_time / (distance / divisor);
  return createTimeSting(totalseconds, imperial);
  // const minutes = Math.floor(totalseconds / 60)
  // const seconds = Math.round(totalseconds % 60)

  // return`${minutes}:${seconds}/${imperial ? 'mi' : 'km'}`
};

export const getPace = (splits) => {
  let fastest = 0;
  let newSplits = [];
  for (var i = 0; i < splits.length; i++) {
    splits[i].pace = splits[i].distance / splits[i].moving_time;
    if (splits[i].pace > fastest) {
      fastest = splits[i].pace;
    }
  }
  for (var i = 0; i < splits.length; i++) {
    newSplits.push(splits[i].pace / fastest);
  }
  return newSplits;
};

export const getMapBounds = (geoJSON) => {
  let lowestLon = +10000000000;
  let highestLon = -10000000000;
  let lowestLat = +10000000000;
  let highestLat = -10000000000;
  for (var i = 0; i < geoJSON.coordinates.length; i++) {
    const lat = geoJSON.coordinates[i][1];
    const lon = geoJSON.coordinates[i][0];
    if (lat > highestLat) highestLat = lat;
    if (lat < lowestLat) lowestLat = lat;
    if (lon > highestLon) highestLon = lon;
    if (lon < lowestLon) lowestLon = lon;
  }
  let latRange = highestLat - lowestLat;
  let lonRange = highestLon - lowestLon;
  lowestLon -= lonRange * 0.1;
  highestLon += lonRange * 0.1;
  lowestLat -= latRange * 0.1;
  highestLat += latRange * 0.1;
  return [lowestLon, lowestLat, highestLon, highestLat];
};

export const drawCanvas = (ctx) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.fillStyle = "white";
  ctx.textBaseline = "top";
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
};

function getFullWidthTitleScale(targetWidth, titleText, ctx, titleTypeface) {
  let titleScale = 0;
  let width = 0;
  incrementTitleScale(width, targetWidth, 1, "up");
  incrementTitleScale(targetWidth, width, -0.1, "down");
  incrementTitleScale(width, targetWidth, 0.01, "up");
  incrementTitleScale(targetWidth, width, -0.01, "down");
  incrementTitleScale(width, targetWidth, 0.001, "up");

  function incrementTitleScale(minimum, maximum, increment, direction) {
    while (minimum < maximum) {
      ctx.font = `${300 * titleScale}px ${titleTypeface}`;
      if (direction === "up") {
        const metrics = ctx.measureText(titleText);
        minimum =
          Math.abs(metrics.actualBoundingBoxLeft) +
          Math.abs(metrics.actualBoundingBoxRight);
        width = minimum;
      } else {
        const metrics = ctx.measureText(titleText);
        maximum =
          Math.abs(metrics.actualBoundingBoxLeft) +
          Math.abs(metrics.actualBoundingBoxRight);
        width = maximum;
      }
      titleScale += increment;
    }
    return;
  }
  return titleScale;
}

export const getCenterFocusDimensions = (width, height, heights, props) => {
  const {
    borderSize,
    header,
    footer,
    elementSpacing,
    paceChartHeight,
    paceChartScale,
    mapWidth,
    mapHeight,
    preset,
    swapTitleAndStats,
  } = props;
  const showSubtitle =
    header === "titleSubtitleAndIcon" || header === "titleAndSubtitle"
      ? true
      : false;
  const showPaceChart =
    footer === "statsAndPaceChart" ||
    footer === "paceChart" ||
    preset === "graph"
      ? true
      : false;
  const showStats =
    footer === "statsAndPaceChart" || footer === "stats" ? true : false;

  let topLockupHeight = props.header
    ? borderSize +
      elementSpacing +
      (showSubtitle ? heights.headerLockup : heights.title.actualHeight)
    : borderSize;
  let bottomLockupHeight =
    borderSize +
    (showStats ? heights.statLockup : 0) +
    (showPaceChart && preset !== "graph"
      ? paceChartHeight * paceChartScale
      : 0) +
    (showPaceChart && preset !== "graph" ? elementSpacing : 0) +
    (showStats ? elementSpacing : 0);

  let h = height - topLockupHeight - bottomLockupHeight;
  let w = width - borderSize * 2;
  return {
    w,
    h,
    topLockupHeight: swapTitleAndStats ? bottomLockupHeight : topLockupHeight,
    bottomLockupHeight: swapTitleAndStats
      ? topLockupHeight
      : bottomLockupHeight,
  };
};

export const getMasterScales = (product) => {
  const relativeMultiplier =
    product.metadata.resolutionA > product.metadata.resolutionB
      ? product.metadata.resolutionA
      : product.metadata.resolutionB;
  return {
    adminMasterScale: relativeMultiplier / 6000,
    masterScale:
      (product.safeResolutionA / product.metadata.resolutionA) *
      (relativeMultiplier / 6000),
  };
};
