import React from "react";
import AppFrame from "../Components/AppFrame";
import {
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Container,
  Accordion,
  AccordionButton,
  Heading,
  Link,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";

const FAQ = () => {
  return (
    <AppFrame>
      <Container maxW="container.md" py={12}>
        <Heading size="xl" fontStyle="italic" textAlign="center" mb={8}>
          FAQ
        </Heading>
        <Heading
          size="md"
          fontFamily="body"
          fontWeight="500"
          textAlign="center"
          mb={8}
        >
          If you have a specific question please{" "}
          <Link href="mailto:info@kudosmaps.com" textDecoration="underline">
            contact us here.
          </Link>
        </Heading>
        <Accordion bg="black" allowMultiple>
          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                How do I place an order?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              Click on Create Artwork found on the home page. You will then be
              guided to connect your Strava account or select a pre-built race.
              From there you will be prompted to customize your map and take it
              to the checkout.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                What payment methods do you accept?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              We use Stripe as our payment provider, and therefore accept all
              major credit / debit cards.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                How can I track my order?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              Once your order is shipped, we will send you a confirmation email
              with a tracking number. This will think to the courier's website
              to track your order.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                What is your return policy?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              As all our products are custom made, we do not accept returns. If
              there is something wrong with the product, please contact us at{" "}
              <Link href="mailto:info@kudosmaps.com" textDecoration="underline">
                info@kudosmaps.com
              </Link>{" "}
              quoting your order number and we will do our best to help.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                How long does shipping take?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              See below for estimated shipping times based on country:
              <Box mb={8}>
                <Text size="md" mt={4} mb={2}>
                  United Kingdom
                </Text>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th></Th>
                        <Th>Estimated arrival</Th>
                        <Th isNumeric>Cost</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Standard</Td>
                        <Td>3 - 5 Business days</Td>
                        <Td isNumeric>Free</Td>
                      </Tr>
                      <Tr>
                        <Td>Express</Td>
                        <Td>1 Business day</Td>
                        <Td isNumeric>£9.95</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box mb={6}>
                <Text size="md" mt={4} mb={2}>
                  United States
                </Text>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th></Th>
                        <Th>Estimated arrival</Th>
                        <Th isNumeric>Cost</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Standard</Td>
                        <Td>3 - 5 Business days</Td>
                        <Td isNumeric>Free</Td>
                      </Tr>
                      <Tr>
                        <Td>Express</Td>
                        <Td>1 Business day</Td>
                        <Td isNumeric>$29.95</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                Can I change or cancel my order?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              If you need to change or cancel your order, please contact us as
              soon as possible. We will do our best to accommodate your request,
              but once an order has been processed or shipped, changes or
              cancellations may not be possible.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton py={4}>
              <Heading
                as="span"
                size="md"
                fontFamily="body"
                flex="1"
                textAlign="left"
              >
                How can I contact customer service?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize="lg" pb={4} color="gray.200">
              You can reach our customer service team via email at{" "}
              <Link href="mailto:info@kudosmaps.com" textDecoration="underline">
                info@kudosmaps.com
              </Link>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </AppFrame>
  );
};

export default FAQ;
