import styled from 'styled-components'

const ContainerPhoto = styled.div`
    height:44px;
    display:flex;
    align-items:center;
    height: 500px;
    justify-content:center;
    padding: 0 24px;
    border:1px solid black;
    flex:1;
    cursor:pointer;
    background-size: cover;
    background-position: center;
    ${({ backgroundImage }) => backgroundImage && `
        background-image: url(${backgroundImage});
  `}
`
function PhotoLockup({backgroundImage, text}) {

    return (
        <ContainerPhoto backgroundImage={backgroundImage}>
            <p>{text}</p>
        </ContainerPhoto>
  );
}

export default PhotoLockup;
