import { BrowserRouter, Routes, Route } from "react-router-dom";
import Orders from "./Orders";
import Generator from './Generator'
import Order from './Order'
import AdminProvider from "../../adminProvider";
import AdminNav from '../../Components/AdminNav'
import Users from './Users'
import AdminPreBuiltRoutes from './AdminPreBuiltRoutes'

function AdminHome() {
  return (
    
    
        <AdminProvider>
            <AdminNav />
            <Routes>

              <Route path="/" element={<Orders />} />
              <Route path="/order" element={<Order />} />
              <Route path="/users" element={<Users />} />
              <Route path="/prebuiltroutes" element={<AdminPreBuiltRoutes />} />
            </Routes>
            </AdminProvider>
        
      
    
  );
}

export default AdminHome;
