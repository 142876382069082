import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import AnalyticsContext from "../analyticsContext";
import { ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatFriendlyDate } from "../Helpers/utilities";

const PlaceholderImage = require("../assets/prebuiltRoute-placeholder.jpg");

const PresetRouteCard = ({ route = {} }) => {
  const { triggerEvent } = useContext(AnalyticsContext);
  const navigate = useNavigate();

  function handleClick() {
    triggerEvent({
      category: "select-activity",
      action: "click",
      label: `selected-prebuilt-activity-${route._id}`,
    });
    navigate(`/activity?id=${route._id}&prebuilt=true`);
  }

  return (
    <Card
      onClick={handleClick}
      boxShadow="none"
      border="1px solid"
      borderColor="gray.900"
      borderRadius="lg"
      bg="black"
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      _hover={{
        bg: "gray.900",
      }}
      direction={{ base: "column", sm: "row", md: "column" }}
    >
      <CardBody
        display={{ base: "flex", sm: "flex", md: "block" }}
        gap={{ base: 2, md: 4 }}
        p={{ base: 2, md: 4 }}
      >
        <Box flex="0" minW="80px" display={{ base: "block", sm: "block" }}>
          <Image
            src={route.image || PlaceholderImage}
            alt={route.title}
            borderRadius="sm"
            w={{ base: "auto", md: "full" }}
          />
        </Box>
        <Stack flex="1" mt={{ base: 1, md: 4 }} spacing={{ base: 1, md: 3 }}>
          {/* <Text>{route.date && formatFriendlyDate(route.date)}</Text> */}
          <Heading size="md">{route.title}</Heading>
          <Text color="gray.500">
            {route.location}
            {"  "}·{"  "}
            {route.distance && Math.floor(route.distance / 100) / 10 + "km"}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter display={{ base: "none", md: "block" }}>
        <ButtonGroup spacing="2">Create artwork</ButtonGroup>
      </CardFooter>
    </Card>
  );
};

export default PresetRouteCard;
