import { useContext, useEffect } from "react";
import Context from "../context";
import { useSearchParams } from "react-router-dom";
import { getData } from "../Helpers/http";
import { useNavigate } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";
import { TailSpin } from "react-loader-spinner";

function TokenExchange() {
  const navigate = useNavigate();
  let [params] = useSearchParams();
  let state = useContext(Context);
  const code = params.get("code");

  useEffect(() => {
    checkStatus();
  }, []);

  function checkStatus() {
    console.log(state.path)
    console.log("getting athlete");
    getData(`${state.path}/athlete`)
      .then((res) => {
        console.log("athelete", res);
        navigate(`/activities`, { replace: true });
        console.log("success");
      })
      .catch((err) => {
        console.log("should navigatee");
        window.location.replace(state.STRAVA_AUTHENTICATION_URL);
      });
  }

  return (
    <Box
      h="100vh"
      w="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box display="inline-block" mb="4">
        <TailSpin
          height="45"
          width="45"
          color="#212121"
          ariaLabel="tail-spin-loading"
          radius="2"
          visible={true}
        />
      </Box>
      <Heading size="md" fontFamily="body" textAlign="center">
        Loading...
      </Heading>
    </Box>
  );
}

export default TokenExchange;
