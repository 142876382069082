const BalancedIcon = require("../assets/presetIcons/icon-balanced.png");
const MinimalIcon = require("../assets/presetIcons/icon-minimal.png");
const DetailedIcon = require("../assets/presetIcons/icon-detailed.png");
const AbstractIcon = require("../assets/presetIcons/icon-abstract.png");

const data = [
  {
    strava:false,
    mapWidth: 3120,
    mapHeight: 2451.8399963378906,
    color: { foreground: "gold", background: "rgb(249,244,235)" },
    label: "Balanced (Popular)",
    selectElements: [ 'subtitle', 'icon'],
    icon: BalancedIcon,
    header: "title",
    footer: "stats",
    swapTitleAndStats: false,
    scale: 1,
    rotation: 180,
    typography: 0,
    canvasImage: null,
    strokeWidth: 20,
    paceChartHeight: 2000,
    paceChartCenteredVertically: false,
    paceChartScale: 0.25,
    elementSpacing: 300,
    borderSize: 400,
    largeIcon: false,
    updater: 0,
    isMetric: true,
    preset: "map",
    useIconForStats: false,
    imageSize: "square",
    titleTypeface: "ModerneSans",
    subtitleTypeface: "Montserrat",
    stats: ["location", "distance", "date"],
    typeScale: 1,
    frame: null,
  },
  {
    strava: true,
    mapWidth: 3120,
    mapHeight: 2451.8399963378906,
    color: { foreground: "gold", background: "rgb(249,244,235)" },
    label: "Balanced (Popular)",
    selectElements: [ 'subtitle', 'paceChart', 'icon'],
    icon: BalancedIcon,
    header: "title",
    footer: "stats",
    swapTitleAndStats: false,
    scale: 1,
    rotation: 180,
    typography: 0,
    canvasImage: null,
    strokeWidth: 20,
    paceChartHeight: 2000,
    paceChartCenteredVertically: false,
    paceChartScale: 0.25,
    elementSpacing: 300,
    borderSize: 400,
    largeIcon: false,
    updater: 0,
    isMetric: true,
    preset: "map",
    useIconForStats: false,
    imageSize: "square",
    titleTypeface: "ModerneSans",
    subtitleTypeface: "Montserrat",
    stats: ["distance", "averagePace", "totalTime"],
    typeScale: 1,
    frame: null,
  },
  {
    strava: true,
    mapWidth: 2700,
    mapHeight: 2223.4912605285645,
    color: { foreground: "silver", background: "rgb(249,244,235)" },
    label: "Minimal",
    selectElements: ['paceChart', 'stats'],
    icon: MinimalIcon,
    swapTitleAndStats: true,
    header: "fullWidthTitle",
    footer: false,
    scale: 1,
    rotation: 180,
    canvasImage: null,
    typography: 0,
    strokeWidth: 20,
    paceChartHeight: 2000,
    paceChartCenteredVertically: false,
    paceChartScale: 0.25,
    elementSpacing: 200,
    borderSize: 400,
    largeIcon: false,
    updater: 0,
    isMetric: true,
    preset: "map",
    useIconForStats: false,
    imageSize: "square",
    titleTypeface: "ModerneSans",
    subtitleTypeface: "Montserrat",
    typeScale: 1,
    fullWidthTitle: true,
    frame: null,
  },
  {
    strava: false,
    mapWidth: 2700,
    mapHeight: 2223.4912605285645,
    color: { foreground: "silver", background: "rgb(249,244,235)" },
    label: "Minimal",
    selectElements: ['stats'],
    icon: MinimalIcon,
    swapTitleAndStats: true,
    header: "fullWidthTitle",
    footer: false,
    scale: 1,
    rotation: 180,
    canvasImage: null,
    typography: 0,
    strokeWidth: 20,
    paceChartHeight: 2000,
    paceChartCenteredVertically: false,
    paceChartScale: 0.25,
    elementSpacing: 200,
    borderSize: 400,
    largeIcon: false,
    updater: 0,
    isMetric: true,
    preset: "map",
    useIconForStats: false,
    imageSize: "square",
    stats: ["location", "distance", "date"],
    titleTypeface: "ModerneSans",
    subtitleTypeface: "Montserrat",
    typeScale: 1,
    fullWidthTitle: true,
    frame: null,
  },
  {
    strava:false,
    mapWidth: 1800,
    mapHeight: 1550.2399978637695,
    color: { foreground: "gold", background: "rgb(0,0,0)" },
    label: "Abstract",
    selectElements: ['header', 'subtitle', 'stats', 'icon'],
    icon: AbstractIcon,
    header: false,
    footer: false,
    swapTitleAndStats: false,
    scale: 1,
    typography: 0,
    rotation: 180,
    canvasImage: null,
    strokeWidth: 20,
    paceChartHeight: 2000,
    paceChartCenteredVertically: false,
    paceChartScale: 0.25,
    elementSpacing: 400,
    borderSize: 500,
    largeIcon: false,
    updater: 1,
    isMetric: true,
    preset: "map",
    useIconForStats: false,
    imageSize: "square",
    titleTypeface: "ModerneSans",
    stats: ["location", "distance", "date"],
    subtitleTypeface: "Montserrat",
    typeScale: 1.25,
    frame: null,
  },
  {
    strava: true,
    mapWidth: 1800,
    mapHeight: 1550.2399978637695,
    color: { foreground: "gold", background: "rgb(0,0,0)" },
    label: "Abstract",
    selectElements: ['header', 'subtitle', 'stats', 'paceChart', 'icon'],
    icon: AbstractIcon,
    header: false,
    footer: false,
    swapTitleAndStats: false,
    scale: 1,
    typography: 0,
    rotation: 180,
    canvasImage: null,
    strokeWidth: 20,
    paceChartHeight: 2000,
    paceChartCenteredVertically: false,
    paceChartScale: 0.25,
    elementSpacing: 400,
    borderSize: 500,
    largeIcon: false,
    updater: 1,
    isMetric: true,
    preset: "map",
    useIconForStats: false,
    imageSize: "square",
    titleTypeface: "ModerneSans",
    subtitleTypeface: "Montserrat",
    typeScale: 1.25,
    frame: null,
  },
  
];

for (let i = 0; i < data.length; i++) {
  data[i].key = i;
  data[i].presetKey = i;
}

export const getPresetConfigs = (isPrebuilt) => {
  if (!isPrebuilt) {
    return data.filter((preset, i) => {
      if (preset.strava) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    return data.filter((preset, i) => {
      if (!preset.strava) {
        return true;
      } else {
        return false;
      }
    });
  }
};

export default data;
