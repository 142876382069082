import { Button, Box, Image } from "@chakra-ui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const SwiperRadioButton = ({ isSelected, onChange, label, subLabel, item }) => {
  return (
    <Box mb={2}>
      <Button
        onClick={() => onChange(item)}
        style={{ minHeight: "44px!important" }}
        size="xl"
        w="full"
        variant={isSelected ? "radioSelected" : "outlineDark"}
        p={2}
        alignItems="center"
        rightIcon={
          isSelected ? (
            <CheckCircleIcon width={30} color="white" />
          ) : (
            <Box
              h="24px"
              w="24px"
              borderRadius="full"
              border="1px solid"
              borderColor="gray.200"
              mr={1}
            />
          )
        }
      >
        {item.icon && <Image src={item.icon} h="38px" w="38px" mr={2} />}
        <Box flex="1" textAlign="left">
          <p size="sm">{item.label}</p>
          {subLabel && (
            <Box fontSize="xs" color="gray.600">
              {subLabel}
            </Box>
          )}
        </Box>
      </Button>
    </Box>
  );
};

export default SwiperRadioButton;
