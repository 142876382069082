import React, { useState } from "react";
import { Provider } from "./userContext";

const UserProvider = props => {  
    const [ user, setUser ] = useState(null)
    const [ email, setEmail ] = useState('')
  return (
     <Provider
        value={{
            user,
            setUser,
            email, 
            setEmail,
        }}
      >
       {props.children}
    </Provider>
  );
};

export default UserProvider;