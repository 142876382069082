import React, { useContext, useEffect } from "react";
import Context from "../context";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";

import { createCheckoutSession } from "../Helpers/stripe";
import { TailSpin } from "react-loader-spinner";
import { skuSelector } from "../Helpers/orderHelpers";
import get from "lodash/get";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { CheckCircleIcon, FaceFrownIcon } from "@heroicons/react/24/solid";
import OrderConfirmation from "../Components/OrderConfirmation";
import { formatShippingOptionsForStripe } from "../Helpers/shipping";
import { BGTexture } from "../Components/AppFrame";

const toCheckout = "?toStripe=true";
const successUrl = "?success=true";
const cancelUrl = "?cancelled=true";

const Checkout = () => {
  const state = useContext(Context);
  let { search } = useLocation();
  let [params] = useSearchParams();
  const activityId = params.get("id");
  const orderId = params.get("order");

  function createLineItems() {
    const selectedProduct = get(state, "builderConfig.product");
    const selectedFrame = get(state, "builderConfig.frame");

    let items = [];
    if (selectedProduct) {
      items.push({
        price: selectedProduct.default_price.id,
        quantity: 1,
      });
    }
    if (selectedFrame && selectedFrame.key) {
      items.push({
        price: selectedFrame.default_price.id,
        quantity: 1,
      });
    }
    return items;
  }

  useEffect(() => {
    if (search.includes(toCheckout) && state.builderConfig) {
      const selectedProduct = get(state, "builderConfig.product");
      const sku = skuSelector(state.builderConfig, selectedProduct);

      createCheckoutSession({
        path: state.path,
        line_items: createLineItems(),
        orderId,
        country: state.shippingRates.country,
        activityId: state.builderConfig.activity.id,
        allow_promotion_codes: true,
        sku,
        shipping_options: formatShippingOptionsForStripe(
          state.shippingRates.options
        ),
      });
    }
  }, []);

  if (search.includes(successUrl)) {
    return (
      <>
        <BGTexture />
        <Box
          h="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Container maxW="container.sm" py={8}>
            <Box textAlign="center">
              <Box display="inline-block" mb={8}>
                <CheckCircleIcon width={45} color="green.500" />
              </Box>
              <Heading mb={2}>Checkout Complete</Heading>
              <Text fontSize="lg" mb={8} color="gray.300">
                We are now processing your order. If you wish to change or
                cancel, please contact us as soon as possible at{" "}
                <Link
                  href="mailto: info@kudosmaps.com"
                  textDecoration="underline"
                >
                  info@kudosmaps.com
                </Link>{" "}
                quoting your order code.
                <br />
                <br />
                You will receive an email confirmation shortly with your order
                details.
              </Text>
              <OrderConfirmation orderId={orderId} />
              <ButtonGroup mt={8} spacing={4}>
                <Button as={RouterLink} to="/" variant="outline">
                  Back to homepage
                </Button>
              </ButtonGroup>
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  if (search.includes(cancelUrl)) {
    return (
      <Box h="100vh" display="flex" alignItems="center" justifyContent="center">
        <Container maxW="container.sm" py={8}>
          <Box textAlign="center">
            <Box display="inline-block" mb={8}>
              <FaceFrownIcon width={45} color="gray.500" />
            </Box>
            <Heading mb={2}>Checkout cancelled</Heading>
            <Text>Head back to your order to make changes</Text>
            <ButtonGroup mt={8} spacing={4}>
              <Button
                as={RouterLink}
                to="/"
                variant="outline"
                _hover={{ color: "black" }}
              >
                Back to home
              </Button>
              <Button
                as={RouterLink}
                to={`/activity?id=${activityId}`}
                variant="solid"
                colorScheme="orange"
                _hover={{ color: "white" }}
              >
                Back to order
              </Button>
            </ButtonGroup>
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box h="100vh" display="flex" alignItems="center" justifyContent="center">
      <Container maxW="container.sm" py={8}>
        <Box textAlign="center">
          <Box display="inline-block">
            <TailSpin
              height="45"
              width="45"
              color="#212121"
              ariaLabel="tail-spin-loading"
              radius="2"
              visible={true}
            />
          </Box>
          <br />
          <br />
          Taking you to checkout...
        </Box>
      </Container>
    </Box>
  );
};

export default Checkout;
