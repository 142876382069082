import React from "react";
import NavBar from "./NavBar";
import { Box } from "@chakra-ui/react";
import Footer from "./Footer";

export const BGTexture = () => {
  return (
    <Box
      position="fixed"
      w="100vw"
      h="100vh"
      zIndex="-1"
      display="flex"
      justifyContent="space-around"
      pointerEvents="none"
    >
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
      <Box w="1px" h="100%" bg="#141414" />
    </Box>
  );
};

const AppFrame = ({ children }) => {
  return (
    <Box>
      <BGTexture />
      <NavBar />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
};

export default AppFrame;
