import { Wrap, WrapItem, Button, Box, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
function ButtonsImageControl({
  options,
  value,
  onChange,
  onBuildComplete,
  sendFullObject,
}) {
  return (
    <>
      {options &&
        options.map((item, i) => {
          if (!item.hide) {
            
            return (
              <Box py={2} key={i} borderBottom="1px solid #f2f2f2">
                <Button
                  
                  key={i}
                  onClick={() => onChange(item.key)}
                  size="lg"
                  w="full"
                  variant={"outlineDark"}
                  color={item.type === 'danger' ? 'red' : "black"}
                  colorScheme={item.type === 'danger' ? 'orange' : "gray"}
                  px={4}
                  py={8}
                  pr={2}
                >
                  <Box flex="1" textAlign="left">
                    <p>
                    {item.label}
                    </p>
                  {item.subLabel && (
                    <p
                      style={{
                        marginTop:'4px',
                        fontWeight: "300",
                        fontSize: "12px",
                      }}
                    >
                      {item.subLabel}
                    </p>
                  
                  )}
                </Box>
                  
                <ArrowRightIcon width={16} color="black" />
        
                </Button>
              </Box>
            );
          } else return <div key={i} />;
        })}
      {/* <Box mb={2}>
            <Button   
            onClick={() => onBuildComplete}
            size="lg"
            w="full"
            variant={ "solid"}
            colorScheme={ "orange"}
            px={4}
            pr={2}
            >
            <Box flex="1" textAlign="left">
                Continue to size & colour
            </Box>
            </Button>
        </Box> */}
    </>
  );
}

export default ButtonsImageControl;
