import { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { IconButton } from "@chakra-ui/react";
import Worker from 'workerize-loader!../Helpers/offscreencanvas.js' // eslint-disable-line import/no-webpack-loader-syntax

const OffscreenRenderCanvas = styled.canvas``

function OffscreenCanvas({width, height,color, getCanvasImageData, imageData, sendPreview}) {
    const canvasRef = useRef(null);
    const worker = useRef(new Worker())
    const [offscreen, setOffscreen ] = useState(null)
    const [ counter, setCounter ] = useState(0)

    useEffect(() => {
        let htmlCanvas = canvasRef.current     
        const offscreenCanvas = htmlCanvas.transferControlToOffscreen();
        setOffscreen(offscreenCanvas)
        worker.current.postMessage({event: 'translateImageData', imageData, color: color});
       
    },[])

    // useEffect(() => {
    //     if (imageData){
    //         worker.current.postMessage({event: 'translateImageData', imageData, color: color});
    //     }
    // },[imageData])

    useEffect(() => {
        if(offscreen){
            worker.current.addEventListener('message', onMessage)
            return () => {
                worker.current.removeEventListener('message', onMessage)
                
            }
        }
    },[offscreen])


    function addStuff(message){
        if(offscreen && message?.data?.imageData){
            const ctx = offscreen.getContext('2d')
            ctx.canvas.width  = width;
            ctx.canvas.height = height;
            ctx.putImageData(message.data.imageData, 0, 0);
            
            offscreen.convertToBlob().then(blob => {
                const url = URL.createObjectURL(blob)
                sendPreview(url)
            })
                
            
        }
    }

    function onMessage(message){
        addStuff(message)
        
        
        
    }

    function onButtonClick(){
    //     console.log('cliging')
    //    let  imageData = getCanvasImageData()
    //     console.log(imageData)
    //     worker.current.postMessage({event: 'translateImageData', imageData, color: color});
    }

  

//   function getRGBFromColorName(color){
//     switch(color){
//       case 'black':
//         return {
//           r: 0,
//           g:0,
//           b:0
//         }
//       case 'white':
//         return {
//           r: 255,
//           g: 255,
//           b: 255 
//         }
//       default: 
//         return null 
//     }
//   }

//   function getRGBFromColorString(color){
//     if (color){

//     }
//     color = color.replace('rgb(', '')
//     color = color.replace(')', '')
//     color = color.split(',')
//     return {
//       r: color[0],
//       g: color[1],
//       b: color[2]
//     }
//   }

//   function exportImageForPrinter(color, isTestImage){
//       const isFoil = color.foreground === 'silver' || color.foreground === 'gold' 
//       const foregroundColorObject = getRGBFromColorName(color.foreground)
//       const backgroundColorObject = getRGBFromColorString(color.background)
//       ctx.willReadFrequently = true;
//       const imageData = ctx.getImageData(0, 0, width, height);
//       let newImageData = ctx.createImageData(imageData);
//       for(var x = 0; x < imageData.width; x++){
//         for(var y = 0; y < imageData.height; y++){
//           var r = imageData.data[((y*(imageData.width*4)) + (x*4))];
//           var g = imageData.data[((y*(imageData.width*4)) + (x*4)) + 1];
//           var b = imageData.data[((y*(imageData.width*4)) + (x*4)) + 2];
//           var a = imageData.data[((y*(imageData.width*4)) + (x*4)) + 3];
//           if (r < 255 || g < 255 || b < 255){
//             if (isFoil){
//             //set all detail to be transparent 
//               imageData.data[((y*(imageData.width*4)) + (x*4)) + 3] = 0  
//             } else {
//             // set detail colour with foregroundColorObject
//               imageData.data[((y*(imageData.width*4)) + (x*4))] = foregroundColorObject.r 
//               imageData.data[((y*(imageData.width*4)) + (x*4)) + 1] = foregroundColorObject.g
//               imageData.data[((y*(imageData.width*4)) + (x*4)) + 2] = foregroundColorObject.b
//             }
//           } else {
//             // set background colour 
//             imageData.data[((y*(imageData.width*4)) + (x*4))] = backgroundColorObject.r 
//             imageData.data[((y*(imageData.width*4)) + (x*4)) + 1] = backgroundColorObject.g
//             imageData.data[((y*(imageData.width*4)) + (x*4)) + 2] = backgroundColorObject.b
//           }
//         }
//       }
//       //   createTestImage(imageData) 
//   }
  return (
    <>
 
      <OffscreenRenderCanvas
      style={{width:0, height:0}}
        width={width}
        height={height}
        ref={canvasRef}
      />
    </>

  );
}

export default OffscreenCanvas;
