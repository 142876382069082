import {
  Box,
  FormLabel,
  Flex,
  Stack,
  FormControl,
  Switch,
} from "@chakra-ui/react";

import SwiperRadioButton from "./SwiperRadioButton";

function BuilderToggles({ toggles, onChange }) {
  function amalgamateValues(key) {
    let newToggles = {};
    toggles.map((item, i) => {
      return (newToggles[item.key] = item.value);
    });
    newToggles[key] = !newToggles[key];
    onChange(newToggles);
  }
  return (
    <Flex
      userSelect="none"
      py="4"
      zIndex="10"
      background="linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,255))"
      direction="column"
    >
      {toggles &&
        toggles.map((item, i) => {
          if (item.display) {
            return (
              <SwiperRadioButton
                key={i}
                isSelected={item.value}
                onChange={() => amalgamateValues(item.key)}
                item={item}
                subLabel={item.subLabel}
              />
            );
          } else return <div key={i} />;
        })}
    </Flex>
  );
}

// <FormControl display='flex' alignItems='center ' key={i}>
// <FormLabel color="black" htmlFor='email-alerts' mb='0'>
//   {item.label}
// </FormLabel>
// <Switch id='email-alerts' colorScheme="orange" isChecked={item.value} onChange={() => amalgamateValues(item.key)}/>
// </FormControl>

export default BuilderToggles;
