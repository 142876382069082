import { getData } from "../Helpers/http";
import { createStats, getMapBounds, getMasterScales } from "../Helpers/canvasHelper";
import poly from "@mapbox/polyline";
import dateFormat from "dateformat";
import { getProducts } from "./stripe";

const maxResolutionMultiplier = 0.6
const maxResolutionA = {
paper: 3380 * maxResolutionMultiplier,
long: 2443 * maxResolutionMultiplier,
square: 4020 * maxResolutionMultiplier,
};

function loadActivity(id, path, builderConfig, isPrebuilt){ 
    return new Promise((resolve, reject )=> {
       getProductsAndSizes(id, path).then((newProducts) => {
            getActivity(id, path, builderConfig, newProducts, isPrebuilt).then((data) => {
                
                resolve(data)
            })
       })
    })
}

function getProductsAndSizes(id, path){
    return new Promise((resolve, reject) => {
        getProducts(path).then((res) => {
            
            
            setMaxSize(res.products).then((newProducts) => {
                // state.setProductsAndSizes(newProducts); 
                resolve(newProducts)
            });
        });
    })
}


function setMaxSize(products) {
    return new Promise((resolve, reject) => {
        products = products.map((product, i) => {
        
        const { metadata } = product;
        if (
            metadata?.aspect_ratio &&
            metadata?.resolutionA > maxResolutionA[metadata?.aspect_ratio]
        ) {
            let maxSide = metadata.resolutionA - metadata.resolutionB < 0 ? 'a' : 'b'
            
            if (maxSide === 'a'){
                const aspectRatio = metadata.resolutionB / metadata.resolutionA;
                product.safeResolutionA = maxResolutionA[metadata?.aspect_ratio];
                product.safeResolutionB = Math.floor(
                    product.safeResolutionA * aspectRatio
                );
            } else if (maxSide = 'b'){
                const aspectRatio = metadata.resolutionA / metadata.resolutionB;
                product.safeResolutionB = maxResolutionA[metadata?.aspect_ratio];
                product.safeResolutionA = Math.floor(
                    product.safeResolutionB * aspectRatio
                );
            }
            return product;
        } else {
            product.safeResolutionA = metadata.resolutionA;
            product.safeResolutionB = metadata.resolutionB;
            return product;
        }
        });
        resolve(products);
    });
}

function getActivity(id, path, builderConfig, products, isPrebuilt) {
    
    return new Promise((resolve, reject )=> {
        const callPath = isPrebuilt ? `${path}/prebuiltroutes?id=${id}` : `${path}/activity/${id}` 
        getData(callPath)
        .then((res) => {
            processActivity(res, id, path, builderConfig, products, isPrebuilt).then((data) => {
                resolve(data)
            })
        })
        .catch((err) => console.log("error", err));
    })
}

function processActivity(res, id, path, builderConfig, products, isPrebuilt) {
    return new Promise((resolve, reject) => {
        //stat options
        const statOptions = createStats(res, isPrebuilt);
        //default product
        let defaultProduct = products.filter(
            (p) => p.metadata.aspect_ratio === builderConfig.imageSize
        );
        defaultProduct = defaultProduct.reduce((previous, current) => {
            return current.metadata.resolutionA < previous.metadata.resolutionA
            ? current
            : previous;
        });
        //starter stats
        
        const starterStats = statOptions.filter((s) => {
            
            if (!isPrebuilt && s.selected) return true
            else if (isPrebuilt && s.prebuiltSelected) return true
            else return false
        });
        const starterStatKeys = [];
        for (let i = 0; i < starterStats.length; i++) {
            starterStatKeys.push(starterStats[i].key);
        }
        // Set up map boundaries
        const geoJSON = isPrebuilt ? res.geoJson : poly.toGeoJSON(res.map.summary_polyline);
        const mapBounds = getMapBounds(geoJSON);
        const masterScales = getMasterScales(defaultProduct)
        const builder = {
            product: defaultProduct,
            activity: res,
            isPrebuilt: isPrebuilt ? id : false,
            mapBounds,
            ...masterScales,
            title: isPrebuilt ? res.title :res.name,
            stats:starterStatKeys,
            // builderConfig.stats && builderConfig.stats.length > 0
            //     ? builderConfig.stats
            //     : starterStatKeys,
            subtitle: isPrebuilt ? "Your text here" : builderConfig.subtitle
            ? builderConfig.subtitle
            : dateFormat(res.start_date_local, "dddd, mmmm dS yyyy").toUpperCase(),
        }
        resolve({
            builderConfig: builder,
            geoJSON, 
            statOptions,
            activity:res,
            mapBounds,
            mapWidth:builderConfig.mapWidth, 
            mapHeight: builderConfig.mapHeight,
            products: products
            
        })
    })
}


export default loadActivity